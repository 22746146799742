import React from 'react'
import { Link } from 'react-router-dom'
import { STUDENT } from '../../Router/paths'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
export default function CourseDetail() {


    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [lesson, setLesson] = useState([]);
    const [ischeck, setCheck] = useState("");
    const [certilink, setCertilink] = useState("");


    const { id } = useParams();

    // Now, 'id' contains the value from the URL


    useEffect(() => {
        window.scroll(0, 0)
        GetCorsesByid(id)
        GetcheckCertificate(id)

    }, [id])


    // GET Categories 
    const GetCorsesByid = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/student/courses/lesson/${id}`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("Data spec", Data);
                    if (Data?.courses && Object.keys(Data.courses).length > 0) {
                        console.log("Data 1", Data.courses.lesson);
                        setData(Data?.courses)
                        setLesson(Data?.coursesLesson)

                        // Continue with your logic for handling non-empty courses array
                    } else {
                        setLoader(false);
                        toast.error("Course detail not found");
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message

                setLoader(false);
                toast.error(Error);
            })
    }


    const GetcheckCertificate = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/isapply/certificate/${id}`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("is check", Data);
                    if (Data.status) {
                        setCheck(Data.message)
                        setCertilink(Data?.courseCertificate)

                        // Continue with your logic for handling non-empty courses array
                    } else {
                        setLoader(false);
                        toast.error(Data.message);
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message

                setLoader(false);
                toast.error(Error);
            })
    }

    const GetCertificate = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/apply/certificate/${id}`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("Data spec", Data);
                    if (Data.status) {
                        console.log("Data 1", Data);
                        toast.success(Data.message)

                        // Continue with your logic for handling non-empty courses array
                    } else {
                        setLoader(false);
                        toast.error(Data.message);
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message

                setLoader(false);
                toast.error(Error);
            })
    }







    return (
        <>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="blogarea__2 sp_top_100 sp_bottom_100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-lg-10">
                            <div
                                className="blogarae__img__2 course__details__img__2"
                                data-aos="fade-up"
                            >
                                <img loading="lazy" src="/assets/img/blog/blog_8.png" alt="blog" />
                                {/* <div class="registerarea__content course__details__video">
                          <div class="registerarea__video">
                              <div class="video__pop__btn">
                                  <Link class="video-btn" to="https://www.youtube.com/watch?v=vHdclsdkp28"> <img loading="lazy"  src="img/icon/video.png" alt="">  </Link>
                              </div>


                          </div>
                      </div> */}
                            </div>
                            <div className="blog__details__content__wraper">

                                <div className="course__details__heading" data-aos="fade-up">
                                    <h3>{data.title}</h3>
                                </div>
                                <div className="course__details__price" data-aos="fade-up">
                                    <ul>
                                        <li>
                                            <div className="course__price">
                                                ${data.price} <del>/ ${data.price_discount}</del>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="course__details__paragraph" data-aos="fade-up">
                                    <p>
                                        {data.description}
                                    </p>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h4 className="sidebar__title" data-aos="fade-up">
                                            Course Details
                                        </h4>

                                    </div>


                                </div>


                                <div className="course__details__wraper" data-aos="fade-up">
                                    <ul>
                                        <li>
                                            Course Level : <span> {data.course_level}</span>
                                        </li>
                                        <li>
                                            Course Status : <span> {data.course_status}</span>
                                        </li>

                                        <li>
                                            Quiz : <span> {data.is_quiz}</span>
                                        </li>

                                        <li>
                                            Certificate : <span> {data.certificate}</span>
                                        </li>

                                    </ul>
                                    <ul>

                                        <li>
                                            Language : <span>{data.language}</span>
                                        </li>
                                        <li>
                                            Price Discount : <span>{data.price_discount}</span>
                                        </li>
                                        <li>
                                            Regular Price : <span>${data.price}</span>
                                        </li>
                                        <li>
                                            Course Status : <span>{data.skill_level}</span>
                                        </li>


                                    </ul>
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center d-flex mb-4'>

                                        {ischeck !== "" && certilink.status === "Applied" ?
                                            <button type="button" className="default__button" >
                                                {ischeck}
                                            </button> :

                                            (ischeck !== "" && certilink.status === "Generated" ?
                                                // <button  type="button" className="default__button" >
                                                //    Download Certificate
                                                // </button>
                                                <Link className="default__button" to={certilink.document} target='_blank'> Download Certificate</Link>


                                                :


                                                <button onClick={() => GetCertificate(id)} type="button" className="default__button" >
                                                    Apply for Certificate
                                                </button>



                                            )}






                                    </div>
                                </div>

                                {/* {data.allLessonComplete === true ?
                                    <div className='row '>
                                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center d-flex'>
                                            <button onClick={() => GetCertificate(id)} type="button" className="default__button" >
                                                Apply for Certificate
                                            </button>

                                        </div>
                                    </div> : ""} */}

                                <div
                                    className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    data-aos="fade-up"
                                >
                                    <div className="lesson__assignment__wrap">
                                        <h3>Lessons</h3>
                                        <hr className="hr" />
                                        <div className="dashboard__table table-responsive">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Videos</th>
                                                        <th />
                                                    </tr>
                                                </thead>
                                                {lesson.map((v, i) => (
                                                    <tbody>
                                                        <tr>
                                                            <th>

                                                                <p>
                                                                    {v.title}
                                                                </p>
                                                            </th>

                                                            <td>
                                                                <p>{v.description}</p>
                                                            </td>


                                                            <td>
                                                                {v.is_open === "1" ?


                                                                    <Link className="dashboard__small__btn" to={STUDENT.lesson(v.id)}>  Detail </Link> :

                                                                    <button type='button' className="btn" disabled >  Detail </button>

                                                                }


                                                            </td>

                                                        </tr>

                                                    </tbody>

                                                ))}

                                            </table>
                                        </div>
                                        <br />

                                    </div>
                                </div>




                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
