import { useEffect, useState } from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { STUDENT } from '../../Router/paths'
import React from 'react'
import { axiosInstance } from '../../config/https';
import { Link, useParams } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Studentquiz() {

  const [loader, setLoader] = useState(false);
  // const [data, setData] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const { id } = useParams();
  console.log("id", id)
  useEffect(() => {
    window.scroll(0, 0)
    GetAllQuiz(id)
  }, [id])

  const GetAllQuiz = (id) => {
    setLoader(true);
    axiosInstance.get(`/api/student/lessons/${id}/quizzes`)
      .then((response) => {
        const Data = response.data
        setLoader(false);
        if (Data?.status) {
          console.log("get all quizes", Data)
          // setData(Data.lesson)
          setQuiz(Data.lessonQuizzes)
        } else {
          setLoader(false);
          toast.error(Data?.message);

        }
      }).catch((err) => {
        setLoader(false);
        const Error = err?.response?.data?.message
        setLoader(false);
        toast.error(Error);

      })
  }

  return (
    <div>
      {loader === true ? <Loader fullPage loading /> : null}

      <div className="dashboardarea sp_bottom_100">
        <Breadcrumb />
        <div className="dashboard">
          <div className="container-fluid full__width__padding">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-12">
                <StudentSidbar />
              </div>
              <div className="col-xl-9 col-lg-9 col-md-12">
                <div className="dashboard__content__wraper">
                  <div className="dashboard__section__title">
                    <h4>My Quiz</h4>
                  </div>

                  <hr className="mt-40" />
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="dashboard__table table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th>S.No</th>

                              <th> Title</th>
                              <th> Description</th>
                              <th>Status</th>
                              <th>Qestions</th>
                              <th />
                            </tr>
                          </thead>

                          {quiz.map((v, i) => (
                            <tbody>
                              <tr>
                                <th>

                                  <p>{i + 1}</p>

                                </th>
                                <th>

                                  <p>{v.title}</p>

                                </th>
                                <th>
                                  <p>{v.description}</p>
                                </th>


                                <td>
                                  <span className="dashboard__td dashboard__td--running">
                                    {v.active === 1 ? 'Active' : 'DeActive'}
                                  </span>
                                </td>

                                {v.attempted === true ?

                                  <td> <span className="dashboard__td dashboard__td--running" > Attempted </span></td> :
                                  <td> <Link className="dashboard__small__btn" to={STUDENT.coursequiz(v.id)}>View Questions </Link></td>


                                }

                                {/* <td> <Link className="dashboard__small__btn" to={ADMIN.questionoptions(v.id)}> Options </Link></td> */}



                              </tr>



                            </tbody>


                          ))}

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
