import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Router/routes";
import { useLoader } from "./context/LoaderContext";
import Loader from "./utils/Loader";
// import LightDarkSwitch from "./utils/lightDarkSwitch";
import Aos from "aos";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const { loader } = useLoader();
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      {loader && <Loader />}
      {/* <LightDarkSwitch /> */}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
