import React from 'react'
import Quizoption from '../../components/admin/quizquestionopt'

export default function quizoption() {
  return (
    <div>
        <Quizoption/>
    </div>
  )
}
