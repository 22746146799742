import React from 'react'
import BreadCrumb from "../../components/breadCrumb";
import Price from "../../components/sections/pricing";
export default function pricing() {
    return (
        <div>
            <BreadCrumb heading={"Pricing"} />
            <Price />
        </div>
    )
}
