import React from "react";

export default function CounterStats() {
  return (
    <>
      {/* counter__section__start */}
      <div className="counterarea sp_bottom_50 sp_top_50">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img
                    loading="lazy"
                    src="/assets/img/counter/counter__1.png"
                    alt="counter"
                  />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">27</span>+
                  </div>
                  <p>Total Acheivment</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img
                    loading="lazy"
                    src="/assets/img/counter/counter__2.png"
                    alt="counter"
                  />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">145</span>+
                  </div>
                  <p>TOTAL STUDENTS</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img
                    loading="lazy"
                    src="/assets/img/counter/counter__3.png"
                    alt="counter"
                  />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">10</span>k
                  </div>
                  <p>tOTAL INSTRUCTOR</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
              <div className="counterarea__text__wraper">
                <div className="counter__img">
                  <img
                    loading="lazy"
                    src="/assets/img/counter/counter__4.png"
                    alt="counter"
                  />
                </div>
                <div className="counter__content__wraper">
                  <div className="counter__number">
                    <span className="counter">214</span>+
                  </div>
                  <p>OVER THE WORLD</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter__section__end*/}
    </>
  );
}
