import React from 'react'
import Createcourse from '../../components/admin/createcourse'
import BreadCrumb from '../../components/breadCrumb'
export default function Createcourses() {
  return (
    <div>
        <BreadCrumb heading={"Create Course"}/>
        <Createcourse/>
    </div>
  )
}
