import React from 'react'
import Quz from '../../components/lesson/quiz'
import BreadCrumb from '../../components/breadCrumb'
export default function Quiz() {
    return (
        <div>
            <BreadCrumb heading={" Quiz"} />
            <Quz />

        </div>
    )
}
