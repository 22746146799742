import React from 'react'
import Aboutcomp from '../../components/about'
import BreadCrumb from '../../components/breadCrumb'
export default function About() {
  return (
    <>
    <BreadCrumb  heading={"About"}/>
    <Aboutcomp/>
        
    </>
  )
}
