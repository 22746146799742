import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../layout/admin/breadcrumb'
import Sidbar from '../../layout/admin/sidbar'
import { Loader } from 'react-overlay-loader';
import { Link, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config/https';
import toast from 'react-hot-toast';

export default function Assignment() {
    const [loader, setLoader] = useState(false);
    const [viewcontent, setViewcontent] = useState("");
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);


    // add state 
    const [title, setTitle] = useState("");
    const [contents, setContents] = useState("");
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [file, setFile] = useState("");
    const [lessonid, setLessonid] = useState("");
    // delete 
    const [deleteass, setDeleteass] = useState("");
    const [assignid, setAssignid] = useState("");





    useEffect(() => {
        window.scroll(0, 0)
        GetAllAssignment(id)
        GetLessons()
    }, [id])

    const handleInputChange = (event, func) => {
        func(event.target.value);
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // FileReader to read the file content
        const reader = new FileReader();
        reader.onload = () => {
            // const binaryData = reader.result; // binary data of the file
            // Now you can send the binaryData to your API

        };
        reader.readAsArrayBuffer(selectedFile);
    };
    const GetLessons = () => {
        setLoader(true);
        axiosInstance.get('/api/all/lesson')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all lessons", Data)
                    setData2(Data.lessons)

                } else {
                    setLoader(false);
                    toast.error(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }
    const GetAllAssignment = (id) => {

        setLoader(true);
        axiosInstance.get(`/api/all/lesson/assignment/${id}`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all quizes", Data)
                    setData(Data?.assingments)


                } else {
                    // setLoader(false);
                    toast.error(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                toast.error(Error);

            })
    }


    // ADD API
    const AddAssignment = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('lesson_id', lessonid)
        formData.append('title', title)
        formData.append('content', contents)
        formData.append('assign_date', startdate)
        formData.append('due_date', enddate)
        formData.append('document', file)

        axiosInstance.post('/api/lesson/assignment/add', formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data
                console.log("assi", Data)

                if (Data.status) {
                    console.log("assi in", Data.message)
                    CloseAddmodal()
                    toast.success(Data?.message);
                    GetAllAssignment(id)
                    setContents("")
                    setTitle("")
                    setEnddate("")
                    setStartdate("")
                    setLessonid("")
                    setFile("")

                }

                else {
                    console.log("else assi", Data)
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                console.log("err", err)
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }


    // Deletee 
    const DeleteModal = (data) => {
        console.log(data)
        setDeleteass(data.id)

    }

    // DELETE 
    const DeleteAssignment = () => {
        setLoader(true);
        axiosInstance.delete(`/api/delete/assignment/${deleteass}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetAllAssignment(id)
                    setDeleteass("")

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtndlt", closeBtn)

        closeBtn.click();

    }


    // update api  

    const Editmodal = (item) => {
        console.log("item edit", item)
        setTitle(item.title)
        setContents(item.content)
        setStartdate(item.assign_date)
        setEnddate(item.due_date)
        setLessonid(item.lesson_id)
        setAssignid(item.id)

    }
    const CloseEditmodal = () => {
        const deletemodal = document.getElementById("staticBackdropedit");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    // UPDATE  
    const UpdateAssignment = () => {
        setLoader(true);

        const formData = new FormData();
        formData.append('lesson_id', lessonid)
        formData.append('title', title)
        formData.append('content', contents)
        formData.append('assign_date', startdate)
        formData.append('due_date', enddate)

        axiosInstance.post(`/api/update/assignment/${assignid}`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data update", Data)
                if (Data?.status) {
                    CloseEditmodal()
                    GetAllAssignment(id)
                    toast.success(Data.message);
                    setContents("")
                    setTitle("")
                    setEnddate("")
                    setStartdate("")
                    setLessonid("")
                    setFile("")

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    return (
        <div>
            <>
                {loader === true ? <Loader fullPage loading /> : null}

                <div className="dashboardarea sp_bottom_100">
                    <Breadcrumb />

                    <div className="dashboard">
                        <div className="container-fluid full__width__padding">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-12">
                                    <Sidbar />

                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-12">
                                    <div className="dashboard__content__wraper">
                                        <div className="dashboard__section__title">
                                            <h4>ASSIGNMENT</h4>
                                        </div>
                                        <div className="dashboard__Announcement__wraper">
                                            <div className="row ">
                                                <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                    <div className="dashboard__Announcement">
                                                        <h5>Assignment</h5>

                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                    {/* <Link className="default__button" to="#" onClick={handleShow}>
                                                    Add Lesson
                                                </Link> */}
                                                    <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                        Add Assignment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="dashboard__table table-responsive">
                                                    <table style={{overflow:"scroll"}}>
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Content</th>
                                                                <th>Start Date</th>
                                                                <th>Due Date</th>
                                                                <th>View PDF</th>



                                                                <th>Action</th>
                                                                {/* <th>Quiz</th> */}


                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        {data.map((v, i) => (
                                                            <tbody>


                                                                <tr className='dashboard__table__row even dashboard__table__row odd'>
                                                                    <th>
                                                                        <p>{v.title}</p>
                                                                        {/* <p>{item.name}</p> */}
                                                                    </th>
                                                                    <Link className="dashboard__small__btn" href=""> <td data-bs-toggle="modal" data-bs-target="#staticBackdroptext" onClick={() => setViewcontent(v.content)}>view content</td></Link>


                                                                    <td>{v.assign_date}</td>
                                                                    <td> {v.due_date}</td>
                                                                    <td> <a className="dashboard__small__btn" href={v.document}> View</a></td>


                                                                    <td>
                                                                        <div className="dashboard__button__group">
                                                                            <td>
                                                                                <div className="dashboard__button__group d-flex">

                                                                                    <button className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdropedit" onClick={() => Editmodal(v)} > <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg>{" "} Edit </button>

                                                                                    <button className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => DeleteModal(v)}> <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Delete </button>

                                                                                    {/* <Link className="modal_button" 
                   to={ADMIN.quiz(item.id)} > <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Quiz </Link> */}

                                                                                </div>
                                                                            </td>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                            </tbody>

                                                        ))}


                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* text modal  */}
                <div class="modal fade" id="staticBackdroptext" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Content</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            {viewcontent}
                                        </div>

                                    </div>





                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>


                            </div>
                        </div>
                    </div>
                </div>


                {/* ADD MODAL  */}


                <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Add Assignment</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="title" class="form-label">Title</label>
                                            <input
                                                id='title'
                                                className="form-control"
                                                value={title}
                                                onChange={(e) => handleInputChange(e, setTitle)}
                                                type="text"
                                                placeholder="Enter Title"
                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Content</label>
                                            <textarea
                                                id='Description'
                                                class="form-control"
                                                value={contents}
                                                onChange={(e) => handleInputChange(e, setContents)}
                                                type="text"
                                                placeholder="Enter Content"
                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Start Date</label>
                                            <input
                                                id='start'
                                                class="form-control"
                                                type="date"
                                                value={startdate}
                                                onChange={(e) => handleInputChange(e, setStartdate)}

                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Due Date</label>
                                            <input
                                                id='due'
                                                type="date"
                                                class="form-control"
                                                value={enddate}
                                                onChange={(e) => handleInputChange(e, setEnddate)}

                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Select Lesson</label>
                                                <select value={lessonid} onChange={(e) => handleInputChange(e, setLessonid)} className="form-select" aria-label="Default select example" >
                                                    <option value="" disabled selected>
                                                        Select ...
                                                    </option>
                                                    {data2.map((v, i) => (
                                                        <option value={v.id} key={v.id}>
                                                            {v.title}
                                                        </option>


                                                    ))}


                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Upload File</label>
                                            <input
                                                id='Descpdfription'
                                                type="file"
                                                class="form-control"
                                                onChange={handleFileChange}
                                            // value={file}
                                            // onChange={(e) => handleFileChange(e, setFile)}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                                <button className="default__button" type="button" onClick={() => AddAssignment()}> Add</button>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit Modal  */}

                <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Edit Assignment</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="title" class="form-label">Title</label>
                                            <input
                                                id='title'
                                                className="form-control"
                                                value={title}
                                                onChange={(e) => handleInputChange(e, setTitle)}
                                                type="text"
                                                placeholder="Enter Title"
                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Content</label>
                                            <textarea
                                                id='Description'
                                                class="form-control"
                                                value={contents}
                                                onChange={(e) => handleInputChange(e, setContents)}
                                                type="text"
                                                placeholder="Enter Content"
                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Start Date</label>
                                            <input
                                                id='start'
                                                class="form-control"
                                                type="date"
                                                value={startdate}
                                                onChange={(e) => handleInputChange(e, setStartdate)}

                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <label htmlFor="Description" class="form-label">Due Date</label>
                                            <input
                                                id='due'
                                                type="date"
                                                class="form-control"
                                                value={enddate}
                                                onChange={(e) => handleInputChange(e, setEnddate)}

                                            />
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div class="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Select Lesson</label>
                                                <select value={lessonid} onChange={(e) => handleInputChange(e, setLessonid)} className="form-select" aria-label="Default select example" >
                                                    <option value="" disabled selected>
                                                        Select ...
                                                    </option>
                                                    {data2.map((v, i) => (
                                                        <option value={v.id} key={v.id}>
                                                            {v.title}
                                                        </option>


                                                    ))}


                                                </select>
                                            </div>
                                        </div>

                                    </div>



                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="default__button" onClick={() => UpdateAssignment()} >Update</button>
                            </div>
                        </div>
                    </div>
                </div>


                {/* DELETE  */}

                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Delete Assignment</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h2> Are You Sure ?</h2>
                                        <h5>Do you really want to delete this Assignment?</h5>

                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                                <button type="button" className="default__button" onClick={() => DeleteAssignment()}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </div>
    )
}
