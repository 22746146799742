import { useState, useEffect } from 'react'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
export default function Breadcrumb() {


    const [loader, setLoader] = useState(false);
    // const [Emaill, setEmaill] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");

    useEffect(() => {
        GetProfile()

    }, [])

    // GET Categories 
    const GetProfile = () => {
        setLoader(true);
        axiosInstance.get('/api/view/user')
            .then((response) => {
                const Data = response.data

                setLoader(false);
                if (Data?.status) {

                    // setData(Data.user)
                    setFname(Data.user.fname)
                    setLname(Data.user.lname)
                    // setEmaill(Data.user.email)


                } else {
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }
  return (
    <div>
            {loader === true ? <Loader fullPage loading /> : null}

         <div className="container-fluid full__width__padding">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="dashboardarea__wraper">
                                <div className="dashboardarea__img">
                                    <div className="dashboardarea__inner student__dashboard__inner">
                                        <div className="dashboardarea__left">
                                            <div className="dashboardarea__left__img">
                                                <img
                                                    loading="lazy"
                                                    src="/assets/img/teacher/teacher__2.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="dashboardarea__left__content">
                                              
                                                <h4>{fname} {lname}</h4>
                                               
                                            </div>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
  )
}
