import React from 'react'
import LessonMat from '../../components/lesson/lessionmaterial'
import BreadCrumb from '../../components/breadCrumb'
export default function Lessionmaterial() {
    return (
        <div>
            <BreadCrumb heading={"Course Materials"} />
            <LessonMat />

        </div>
    )
}
