import { useState, useEffect } from 'react'
import Sidbar from '../../layout/admin/sidbar'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/admin/breadcrumb';


export default function GenerateCertificate() {
    const [loader, setLoader] = useState(false);
    
    // get data 
    const [data, setData] = useState([]);
   
   



    useEffect(() => {
        window.scroll(0, 0)
        GeneratCertificate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // GET Categories 
    const GeneratCertificate = () => {
        setLoader(true);
        axiosInstance.get('/api/generated/certificates')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                   console.log('data',Data)
                setData(Data?.generatedCertificates)

                } else {
                  
                    setLoader(false);
                    toast.error(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
               

                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }






    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />

                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />

                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Generated Certificate</h4>
                                    </div>
                                    {/* <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>Requested Certificate</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                

                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                   View Generated Certificate
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Course Title</th>
                                                            <th>Student Name</th>
                                                            <th>Email</th>
                                                            <th>Status</th>
                                                            {/* <th>Action</th> */}



                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, index) => (
                                                            <tr key={index} className={index % 2 === 0 ? 'dashboard__table__row even' : 'dashboard__table__row odd'}>
                                                                <th>
                                                                   {item.course.title}
                                                                    {/* <p>{item.name}</p> */}
                                                                </th>
                                                                <td>{item.student.fname} {item.student.lname}</td> 
                                                                <td>{item.student.email}</td> 
                                                                <td>{item.status}</td> 

{/* 
                                                                <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td>
                                                                            <div className="dashboard__button__group">
                                                                                <button type='button'  className="modal_button mx-1">
                                                                                    {" "}
                                                                                    Generate Certificate
                                                                                </button>
                                                                               
                                                                            </div>
                                                                        </td>
                                                                    </div>
                                                                </td> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}
