import { useState } from 'react'
import { axiosInstance } from '../../config/https';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { STUDENT } from '../../Router/paths';

export default function Codeverify() {
    const navigate = useNavigate()
    const [code, setCode] = useState("");
    const [loader, setLoader] = useState(false);

    
    const SendToken = () => {

        setLoader(true)
  const formData = new FormData()
            formData.append("token", code)
        axiosInstance.post('/api/user/verify',formData)
            .then((response) => {
                setLoader(false)
                const Data = response?.data
                if (Data.status) {
                    console.log("response", Data)
                    toast.success(Data.message)
                    navigate(STUDENT.confirmpassword, { state: { token:Data.token} })
                } else {
                    toast.error(Data.message)
                   
                }
            }).catch(() => {
                setLoader(false)


            })




    }

    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="loginarea sp_top_100 sp_bottom_100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-md-8 offset-md-2" data-aos="fade-up">

                        </div>
                        <div
                            className="tab-content tab__content__wrapper"

                            data-aos="fade-up"
                        >
                            <div
                                className=""

                            >
                                <div className="col-xl-8 col-md-8 offset-md-2">
                                    <div className="loginarea__wraper">
                                        <div className="login__heading">
                                            <h5 className="login__title">PASSWORD RECOVERY</h5>
                                            <p className="login__description"> Enter your code! </p>
                                        </div>
                                        <form action="#">
                                            <div className="login__form">
                                                <label className="form__label"> Code</label>
                                                <input
                                                    value={code}
                                                    onChange={(e) => setCode(e.target.value)}
                                                    className="common__login__input"
                                                    type="text"
                                                    placeholder="Enter Your Code"
                                                />
                                            </div>


                                            <div className="login__button" >
                                                <button type='button' className="default__button" onClick={() => SendToken()}>
                                                Submit
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=" login__shape__img educationarea__shape_image">
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__1"
                            src="/assets/img/education/hero_shape2.png"
                            alt="Shape"
                        />
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__2"
                            src="/assets/img/education/hero_shape3.png"
                            alt="Shape"
                        />
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__3"
                            src="/assets/img/education/hero_shape4.png"
                            alt="Shape"
                        />
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__4"
                            src="/assets/img/education/hero_shape5.png"
                            alt="Shape"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
