import React from "react";
import { Link } from "react-router-dom";
export default function Brands() {
  return (
    <>
      {/* brand__section__start */}
      <div className="brandarea__2">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="brandarea__wraper brandarea__wraper__2">
              <div className="brandarea__img">
                <Link to="#">
                  <img
                    loading="lazy"
                    src="/assets/img/brand/brand_1.png"
                    alt="brand"
                  />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link to="#">
                  <img
                    loading="lazy"
                    src="/assets/img/brand/brand_2.png"
                    alt="brand"
                  />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link to="#">
                  <img
                    loading="lazy"
                    src="/assets/img/brand/brand_3.png"
                    alt="brand"
                  />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link to="#">
                  <img
                    loading="lazy"
                    src="/assets/img/brand/brand_4.png"
                    alt="brand"
                  />
                </Link>
              </div>
              <div className="brandarea__img">
                <Link to="#">
                  <img
                    loading="lazy"
                    src="/assets/img/brand/brand_5.png"
                    alt="brand"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* brand__section__end */}
    </>
  );
}
