import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "../pages/main/home";
// import { AUTH, CLIENT, FREELANCER, MAIN } from "./paths";
import MainLayout from "../layout/main";
import Error404 from "../pages/404";
import Courses from "../pages/main/courses";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Blogs from "../pages/main/blogs";
import Blogdetails from "../pages/main/blogdetails";
import CourseDetail from "../pages/main/coursesdetail";
import About from "../pages/main/about";
import Contect from "../pages/main/contect";
import Lesson from "../pages/lession/lesson";
import Lessionmaterial from "../pages/lession/lessionmaterial";
import Quiz from "../pages/lession/quiz";
import Assignment from "../pages/lession/assignment";
import SpecificCate from "../pages/main/specificCate";
import Pricing from "../pages/main/pricing";
// ADMIN 
import AdminLogin from "../pages/auth/adminlogin";
import Dashboard from "../pages/admin/dashboard";
import Profile from "../pages/admin/profile";
import Admincourses from "../components/admin/admincourses";
import Reviews from "../pages/admin/reviews";
import AdminQuiz from "../pages/admin/quiz";
import Settings from "../pages/admin/settings";
import Createcourses from "../pages/admin/createcourses";
import Category from "../pages/admin/category";
import Lessons from "../pages/admin/lessons";
import Lessonvides from "../components/admin/lessonvides";
import Questions from "../pages/admin/questions";
import Quizoption from "../pages/admin/quizoption";
import AdminAssignment from "../pages/admin/assignment";
import Submittedassignment from "../components/admin/submittedassignment";
import RequestedCertificate from "../components/admin/requestedcertificate";
import GenerateCertificate from "../components/admin/generatecertificate";
// STUDENT 
import StudentDashboard from "../pages/student/dashboard";
import Assignments from "../pages/student/assignments";
import Enrolledcourse from "../pages/student/enrolledcourse";
import StudentProfile from "../pages/student/profile";
import StudentSetting from "../pages/student/setting";
import StudentQuiz from "../pages/student/quiz";
import Createcourse from "../pages/student/createcourse";
import Studentquestion from "../components/student/questions";
import Passwordrecover from "../auth/studentauth/passwordrecover";
import Codeverify from "../auth/studentauth/codeverify";
import Confirmpassword from "../auth/studentauth/confirmpassword";
import QuizDetail from "../pages/student/quizdetail";
import AttemptQuiz from "../pages/student/attemptquiz";
import Specificassignment from "../components/student/specificassignment";
export default function Routes() {
  const element = useRoutes([
    // auth
    // {
    //   path: "/auth",
    //   children: [
    //     {
    //       element: <Navigate to={"/auth/login"} />,
    //       index: true,
    //     },
    //     {
    //       path: "login",
    //       element: <AutoLogin comp={<LoginPage />} />,
    //     },
    //     {
    //       path: "signup",
    //       element: <AutoLogin comp={<SignupPage />} />,
    //     },
    //     { path: "account-type", element: <CategoryPage /> },
    //     { path: "client-profile", element: <ClientProfileSetup /> },
    //     { path: "freelancer-profile", element: <FreelancerProfileSetup /> },
    //   ],
    // },
    // freelancer
    // {
    //   path: "/freelancer",
    //   element: <ProtectedRoute comp={<FreelancerLayout />} access={"seller"} />,
    //   children: [
    //     {
    //       element: <Navigate to={"/freelancer/dashboard"} />,
    //       index: true,
    //     },
    //     {
    //       path: "dashboard",
    //       element: <Dashboard />,
    //     },
    //     { path: "notifications", element: <Notifications /> },
    //     { path: "submitProposal", element: <SubmitProposal /> },
    //     { path: "profile", element: <FreelancerProfile /> },
    //     { path: "hire", element: <Hire /> },
    //     { path: "connects", element: <Connects /> },
    //     { path: "transaction-history", element: <TransactionHistory /> },
    //     { path: "my-jobs", element: <MyJobs /> },
    //     { path: "my-proposals", element: <MyProposals /> },
    //     { path: "view-proposals", element: <ViewProposals /> },
    //     { path: "view-proposal/:id", element: <ViewProposal /> },
    //     // dispute
    //     { path: "disputes/:id", element: <DisputeResolution /> },
    //     { path: "file-a-complain", element: <FileAComplain /> },
    //     // dispute

    //     // courses
    //     { path: "courses", element: <CoursesPage /> },
    //     { path: "course/:id", element: <CoursesDetailPage /> },
    //     { path: "lectures/:id", element: <CoursesVideoPage /> },
    //     // courses
    //   ],
    // },

    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          element: <Home />,
          index: true,
        },
        // { path: "login", element: <Login /> },
        { path: "signup", element: <Signup /> },
        { path: "courses", element: <Courses /> },
        { path: "blogs", element: <Blogs /> },
        { path: "blogdetails", element: <Blogdetails /> },
        // { path: "coursedetails", element: <CourseDetail /> },
        { path: "about", element: <About /> },
        { path: "contactus", element: <Contect /> },
        { path: "lesson", element: <Lesson /> },
        { path: "specific-course/:id", element: <SpecificCate /> },
        { path: "pricing", element: <Pricing /> },



        // admin
        {
          path: "/admin",

          children: [
            {
              element: <Dashboard />,
              index: true,
            },
            { path: "adminlogin", element: <AdminLogin /> },
            { path: "profile", element: <Profile /> },
            { path: "courses", element: <Admincourses /> },
            { path: "reviews", element: <Reviews /> },
            { path: "quiz/:id", element: <AdminQuiz /> },
            { path: "questions/:id", element: <Questions /> },

            { path: "settings", element: <Settings /> },
            { path: "createcourse", element: <Createcourses /> },
            { path: "coursecategory", element: <Category /> },
            { path: "lessons", element: <Lessons /> },
            { path: "assignment/:id", element: <AdminAssignment /> },
            { path: "requestecertificate", element: <RequestedCertificate /> },
            { path: "generatecertificate", element: <GenerateCertificate /> },


            
            { path: "submitassignments/:id", element: <Submittedassignment /> },


            { path: "questionoptions/:id", element: <Quizoption /> },

            { path: "lesson-videos/:id/:title", element: <Lessonvides /> },







          ],

        },

        // student
        {
          path: "/student",

          children: [
            {
              element: <StudentDashboard />,
              index: true,
            },

            { path: "login", element: <Login /> },
            { path: "profile", element: <StudentProfile /> },
            { path: "assignment/:id", element: <Assignments /> },
            { path: "specificassignment/:id/:status", element: <Specificassignment /> },
            { path: "enrolledcourse", element: <Enrolledcourse /> },
            { path: "quiz/:id", element: <StudentQuiz /> },
            { path: "settings", element: <StudentSetting /> },
            { path: "createcourse", element: <Createcourse /> },
            { path: "coursedetail/:id", element: <CourseDetail /> },
            { path: "lesson/:id", element: <Lesson /> },
            { path: "course-materials", element: <Lessionmaterial /> },
            { path: "course-quiz/:id", element: <Quiz /> },
            { path: "course-assignment", element: <Assignment /> },
            { path: "questions/:id", element: <Studentquestion /> },
            { path: "passwordrecover", element: <Passwordrecover /> },
            { path: "codeverify", element: <Codeverify /> },
            { path: "confirmpassword", element: <Confirmpassword /> },
            { path: "quizdetail", element: <QuizDetail /> },
            { path: "attempt_quiz/:id", element: <AttemptQuiz /> },















          ],

        },

        { path: "*", element: <Error404 /> },
      ],
    },
    // { path: "*", element: <Navigate to={"/"} /> },
  ]);

  return element;
}

// const ProtectedRoute = ({ comp, access }) => {
//   const token = localStorage.getItem("token");
//   const user = JSON.parse(localStorage.getItem("user"));
//   if (token === null) return <Navigate to={AUTH.login} />;
//   if (user?.user?.role === access) {
//     return comp;
//   } else {
//     return <Navigate to={AUTH.login} />;
//   }
// };

// const AutoLogin = ({ comp }) => {
//   const token = localStorage.getItem("token");
//   const user = JSON.parse(localStorage.getItem("user"));
//   if (token === null) return comp;
//   if (user?.user?.role === "seller") {
//     return <Navigate to={FREELANCER.dashboard} />;
//   } else if (user?.user?.role === "client") {
//     return <Navigate to={CLIENT.home} />;
//   } else {
//     localStorage.clear();
//     return <Navigate to={MAIN.landing} />;
//   }
// };
