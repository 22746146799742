import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { STUDENT } from '../../Router/paths'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
export default function Index() {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [video, setVideo] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const { id } = useParams();


    useEffect(() => {
        window.scroll(0, 0)
        GetCorsesByid(id)


    }, [id])

    // GET Categories 
    const GetCorsesByid = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/student/lesson/view/${id}`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {

                    if (Data?.lesson && Object.keys(Data.lesson).length > 0) {
                        console.log("Data 1", Data);
                        setData(Data?.lesson)
                        setVideo(Data?.lessonVideo)

                        // Continue with your logic for handling non-empty courses array
                    } else {
                        setLoader(false);
                        toast.error("Lesson detail not found");
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message

                setLoader(false);
                toast.error(Error);
            })
    }


    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="tution sp_bottom_100 sp_top_50">
                <div className="container-fluid full__width__padding">
                    <div className="row">
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up" >

                            <div
                                className="accordion content__cirriculum__wrap"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            {data.title}
                                        </button>
                                    </h2>
                                    {video.map((v, i) => (
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <div className="scc__wrap">
                                                    <div className="scc__info">
                                                        <i className="icofont-video-alt" />
                                                        <h5 onClick={() => setSelectedVideo(v.file)}>

                                                            <Link to="">
                                                                <span>Video :</span> {i + 1}
                                                            </Link>
                                                        </h5>
                                                    </div>
                                                    <div className="scc__meta">

                                                        <Link to="#" onClick={() => setSelectedVideo(v.file)}>
                                                            <span className="question">
                                                                <i className="icofont-eye" /> Preview
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    ))}

                                </div>


                            </div>


                            <div
                                className="accordion content__cirriculum__wrap"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingtwo">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsetwo"
                                            aria-expanded="true"
                                            aria-controls="collapsetwo"
                                        >
                                            Lesson Quiz
                                        </button>
                                    </h2>

                                    <div
                                        id="collapsetwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingtwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to={STUDENT.quiz(id)}>
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>




                                </div>


                            </div>



                            <div
                                className="accordion content__cirriculum__wrap"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingthree">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsetwo"
                                            aria-expanded="true"
                                            aria-controls="collapsethree"
                                        >
                                            Lesson Assignment
                                        </button>
                                    </h2>

                                    <div
                                        id="collapsethree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingthree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to={STUDENT.assignment(id)}>
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            {/* <div className="scc__wrap">
                                            <div className="scc__info">
                                                <i className="icofont-file-text" />
                                                <h5>
                                                    {" "}
                                                    <Link to={STUDENT.coursematerial}>
                                                        <span>Course Materials</span>
                                                    </Link>
                                                </h5>
                                            </div>
                                        </div>
                                      
                                        <div className="scc__wrap">
                                            <div className="scc__info">
                                                <i className="icofont-file-text" />
                                                <h5>
                                                    <Link to={STUDENT.courseassignment}>
                                                        <span>Assignment</span>
                                                    </Link>
                                                </h5>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>




                                </div>


                            </div>



                        </div>



                        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"  >
                            {selectedVideo && (
                                <div className="lesson__content__main">
                                    <div className="lesson__content__wrap">

                                        <span>
                                            <Link to="">Close</Link>
                                        </span>
                                    </div>


                                    <div className="plyr__video-embed rbtplayer" style={{ aspectRatio: "16/9" }}>
                                        <iframe
                                            style={{ width: "100%", height: "100%" }}
                                            src={selectedVideo}
                                            allowFullScreen
                                            allow="autoplay"
                                            title='youtube'
                                        />
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
