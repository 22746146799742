import React from 'react'
import Dashboardcomp from '../../components/admin/dashboard'

export default function Dashboard() {
  return (
    <div>
        <Dashboardcomp/>

    </div>
  )
}
