import { useState, useEffect } from 'react'
import Sidbar from '../../layout/admin/sidbar'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/admin/breadcrumb';

export default function Category() {
    const [loader, setLoader] = useState(false);   
    // ADD CATE  
    const [addcategory, setAddcategory] = useState("");
    // get data 
    const [data, setData] = useState([]);
    // edit state 
    const [editcategory, setEditcategory] = useState("");
    const [id, setId] = useState("");
    const [deleteid, setDeleteid] = useState("");

    // EDIT FUNC 

    const Editmodal = (item) => {
        setEditcategory(item?.name)
        setId(item.id)
    }

    useEffect(() => {
        window.scroll(0,0)
        GetCategory()
    }, [])


    // GET Categories 
    const GetCategory = () => {
        setLoader(true);
        axiosInstance.get('/api/categories')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get data",)
                    setData(Data.categories)

                } else {
                    setLoader(false);
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }

    // ADD API
    const AddCateogry = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('name', addcategory)

        axiosInstance.post("/api/create/category", formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;

                if (Data?.status) {
                    GetCategory()
                    setAddcategory("")
                    CloseAddmodal()
                    toast.success("Added Successfully");

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    // UPDATE  
    const UpdateCateogry = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('name', editcategory)
        axiosInstance.post(`api/update/category/${id}`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data update", Data)
                if (Data?.status) {
                    CloseEditmodal()
                    toast.success(Data.message);
                    GetCategory()

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    // DELETE 
    const DeleteCateogry = () => {
        setLoader(true);

        axiosInstance.get(`/api/delete/category/${deleteid}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {

                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetCategory()

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const Deltemodal = (item) => {
        setDeleteid(item.id)
        // handleShow2(true)
    }

    const handleInputChange = (event, func) => {
        func(event.target.value);
    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    const CloseEditmodal = () => {
        const deletemodal = document.getElementById("staticBackdropedit");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="dashboardarea sp_bottom_100">
            <Breadcrumb/>

                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />

                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Course Category</h4>
                                    </div>
                                    <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>Course Category</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                {/* <Link className="default__button" to="#" onClick={handleShow}>
                                                    Add Category
                                                </Link> */}

                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                    Add Category
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Category Name</th>
                                                            <th></th>
                                                            <th>Status</th>

                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, index) => (

                                                            <tr key={index} className={index % 2 === 0 ? 'dashboard__table__row even' : 'dashboard__table__row odd'}>
                                                                <th>
                                                                    <span>{item.name}</span>
                                                                    {/* <p>{item.name}</p> */}
                                                                </th>
                                                                <td>{/* Your dynamic data for this cell */}</td>
                                                                <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td> 
                                                                            <div className="dashboard__button__group">

                                                                            <button type='button' className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdropedit" onClick={() => Editmodal(item)}> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg>{" "} Edit </button>



                                                                            <button
                                                                                type='button'
                                                                                className="modal_button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => Deltemodal(item)}> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Delete </button> 
                                                                                </div> 
                                                                                </td>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD MODAL  */}
            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Category</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label for="category" class="form-label">Category Name</label>
                                        <input
                                            class="form-control"
                                            id="category"
                                            value={addcategory}
                                            onChange={(e) => handleInputChange(e, setAddcategory)}
                                            type="text"
                                            placeholder="Enter Category"
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="default__button" onClick={() => AddCateogry()} >Add</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Modal  */}

            <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Category</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label for="ecategory" class="form-label">Category Name</label>
                                        <input
                                            class="form-control"
                                            id="eecategory"
                                            value={editcategory}
                                            onChange={(e) => setEditcategory(e.target.value)}
                                            type="text"
                                            placeholder="Edit Category"
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="default__button" onClick={() => UpdateCateogry()} >Update</button>
                        </div>
                    </div>
                </div>
            </div>

          

            {/* DELETE  */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Category</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this category?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteCateogry()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}
