import { useState, useEffect } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';

export default function SpecificCourses() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const { id } = useParams();

  // Now, 'id' contains the value from the URL
  

  useEffect(() => {
    window.scroll(0, 0)
    GetCorsesByid(id)

  }, [id])


  // GET Categories 
  const GetCorsesByid = (id) => {
    setLoader(true);
    axiosInstance.get(`/api/category/courses/${id}`)
      .then((response) => {
        const Data = response.data
        setLoader(false);
        if (Data?.status) {
          if (Data.courses && Data.courses.length > 0) {
            setData(Data.courses);
          } else {

            toast.error("No course found");
          }

        } else {
          setLoader(false);
          toast.error(Data?.message);
        }
      }).catch((err) => {
        setLoader(false);
        const Error = err?.response?.data?.message
       
        setLoader(false);
        toast.error(Error);
      })
  }

  const handleCourseClick = (data) => {

    navigate('/pricing', { state: data });
   
  };

  return (
    <>
            {loader === true ? <Loader fullPage loading /> : null}

      <div className="gridarea">
        <div className="container">


          <div className="row grid">
          {data.map((v, i) => (

            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter5 filter2"
              data-aos="fade-up"
            >
             



                <div className="gridarea__wraper">
                  <div className="gridarea__img">
                    <div  onClick={() => handleCourseClick(v)}>
                      <img
                        loading="lazy"
                        src={v.image}
                        alt="grid"
                      />
                    </div>
                    <div className="gridarea__small__button">
                      <div className="grid__badge yellow__color">{v.title}</div>
                    </div>
                    {/* <div className="gridarea__small__icon">
                      <Link to="#">
                        <i className="icofont-heart-alt" />
                      </Link>
                    </div> */}
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> {v.course_level} 
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> {v.language} 
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <div  onClick={() => handleCourseClick(v)}>
                          {v.description}
                        </div>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                    ${v.price_discount}<del>/ ${v.price}</del>
                      <span>
                        {" "}
                        <span className="">{v.course_status}</span>
                      </span>
                    </div>
                  
                  </div>
                </div>
            
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
