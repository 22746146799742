import { useEffect, useState } from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { STUDENT } from '../../Router/paths'
import React from 'react'
import { axiosInstance } from '../../config/https';
import { Link } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Studentquiz() {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        window.scroll(0, 0)
        GetQuizDetail()

    }, [])


    // GET GetQuizDetail 
    const GetQuizDetail = () => {
        setLoader(true);
        axiosInstance.get('/api/student/attempted/quiz')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get data", Data)
                    setData(Data.attemptedQuiz)

                } else {
                    setLoader(false);
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <StudentSidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Quiz Details</h4>
                                    </div>

                                    <hr className="mt-40" />
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>

                                                            <th> Title</th>
                                                            <th> Description</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                            <th />
                                                        </tr>
                                                    </thead>

                                                    {data.map((v, i) => (

                                                        <tbody key={i}>
                                                            <tr>
                                                                <th>

                                                                    <p>{1 + i}</p>

                                                                </th>
                                                                <th>

                                                                    <p>{v.title}</p>

                                                                </th>
                                                                <th>
                                                                    <p>{v.description}</p>
                                                                </th>


                                                                <td>
                                                                    <span className="dashboard__td dashboard__td--running">
                                                                    {v.active === 1 ? 'Active' : 'DeActive'}
                                                                    </span>
                                                                </td>


                                                                <td> <Link className="dashboard__small__btn" to={STUDENT.attemptquiz(v.id)}> View </Link></td>

                                                            </tr>

                                                        </tbody>

                                                    ))}


                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
