import React from 'react'
import BreadCrumb from "../../components/breadCrumb";
import BlogsComp from "../../components/blogs/index";
export default function Blogs() {
  return (
    <div>
        <BreadCrumb heading={'Blog Page'}/>
        <BlogsComp/>
    </div>
  )
}
