import { useState, useEffect } from 'react'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';

export default function Breadcrumb() {
    const [loader, setLoader] = useState(false);
    // const [Emaill, setEmaill] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");

    useEffect(() => {
        GetProfile()

    }, [])

    // GET Categories 
    const GetProfile = () => {
        setLoader(true);
        axiosInstance.get('/api/admin/view/profile')
            .then((response) => {
                const Data = response.data
                console.log("admin pro", Data)
                setLoader(false);
                if (Data?.status) {

                    // setData(Data.user)
                    setFname(Data.user.fname)
                    setLname(Data.user.lname)
                    // setEmaill(Data.user.email)


                } else {
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }

    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="container-fluid full__width__padding">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="dashboardarea__wraper">
                            <div className="dashboardarea__img">
                                <div className="dashboardarea__inner admin__dashboard__inner">
                                    <div className="dashboardarea__left">
                                        <div className="dashboardarea__left__img">
                                            <img
                                                loading="lazy"
                                                src="/assets/img/dashbord/dashbord__2.jpg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="dashboardarea__left__content">
                                            <h5>Hello</h5>
                                            <h4>{fname} {lname}</h4>
                                        </div>
                                    </div>
                                    <div className="dashboardarea__star">
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-star"
                                        >
                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                        <span>4.0 (120 Reviews)</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
