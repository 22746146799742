import axios from "axios";
import toast from "react-hot-toast";
import { baseURL } from "../utils/variables";

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      // axiosInstance.defaults.headers.common['Authorization']  = token;
      config.headers.Authorization =  token ? `Bearer ${token}` : '';

    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response !== undefined && error.response.data !== undefined) {
      const Data = error.response.data;
      if (Data.errors) {
        const ErrList = Object.keys(Data.errors);
        ErrList.map((e) => toast.error(Data.errors[e][0]));
        
      } else {
        toast.error(Data.message);
      }
    } else {
      // console.log("ErrList",error.message);
      toast.error(error.message);
    }
    return Promise.reject(error);
  }
);
