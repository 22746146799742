import React from "react";
import BreadCrumb from "../../components/breadCrumb";
import CoursesComp from "../../components/courses";

export default function Courses() {
  return (
    <>
      <BreadCrumb heading={'Courses'}/>
      <CoursesComp />
    </>
  );
}
