import { useEffect, useState } from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { STUDENT } from '../../Router/paths'
import React from 'react'
import { axiosInstance } from '../../config/https';
import { Link, useNavigate, useParams } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Specificassignment() {

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()
    // const [data, setData] = useState([]);
    const [assignment, setAssignment] = useState([]);
    const [checkstatus, setCheckstatus] = useState('');
    const [file, setFile] = useState("");
    const [assignid, setAssignid] = useState("");
    const [lessonid, setLessonid] = useState("");
    console.log('ids', assignid, lessonid)

    const { id, status } = useParams();



    useEffect(() => {
        // window.scroll(0, 0)
        GetSingleAssignment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const GetSingleAssignment = () => {
        setLoader(true);
        axiosInstance.get(`/api/student/lesson/assignment/view/${id}/${status}`)
            .then((response) => {
                const Data = response.data;
                setLoader(false);
                if (Data?.status) {
                    console.log("speci assign", Data);
                    setAssignid([Data?.lessonAssignment?.id])
                    setLessonid([Data?.lessonAssignment?.lesson_id])
                    setCheckstatus(Data.attempted)
                    // Ensure lessonAssignment is an array
                    setAssignment([Data.lessonAssignment])

                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message;
                toast.error(Error);
            });
    };


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // FileReader to read the file content
        const reader = new FileReader();
        reader.onload = () => {
            // const binaryData = reader.result; // binary data of the file
            // Now you can send the binaryData to your API

        };
        reader.readAsArrayBuffer(selectedFile);
    };



    // ADD API
    const SubmitAssignment = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('lesson_id', lessonid)
        formData.append('lesson_assignment_id', assignid)
        formData.append('document', file)

        axiosInstance.post('/api/submit/lesson/assignment', formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data
                console.log("assi", Data)
                if (Data.status) {
                    console.log("assi in", Data.message)
                    toast.success(Data?.message);
                    setFile("")
                    GetSingleAssignment()
                    navigate(STUDENT.assignment(lessonid))
                }

                else {
                    console.log("else assi", Data)
                    setLoader(false)
                    toast.error(Data.message)
                }

            }).catch((err) => {
                console.log("err", err)
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}
            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <StudentSidbar />
                            </div>
                            <div
                                className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
                                data-aos="fade-up"
                            >

                                {assignment.map((v, i) => {

                                    return <div className="lesson__meterials__wrap">
                                        <div className="aboutarea__content__wraper__5">
                                            <div className="section__title">

                                                <div className="section__title__heading ">
                                                    <h2>{v.title}.</h2>
                                                </div>
                                            </div>
                                            <div className="about__text__5">
                                                <p>
                                                    {v.content}

                                                </p>
                                            </div>



                                            <div className="aboutarea__list__2">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check" />START DATE: {v.assign_date}
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check" />END DATE: {v.due_date}
                                                    </li>
                                                    {checkstatus === false && v.document !== null ? (
                                                        <li>
                                                            <Link target='_blank' className="default__button" to={v.document}>Download</Link>
                                                        </li>
                                                    ) : (
                                                        checkstatus === false && v.document === null && (
                                                            <li>
                                                                <Link className="default__button" href='#'>Download file not available</Link>
                                                            </li>
                                                        )
                                                    )}


                                                </ul>
                                            </div>





                                        </div>
                                    </div>
                                })}

                                <br />
                                <br />


                                {checkstatus === false ?
                                    <div className="assignment__submit__wrap">
                                        <h3>Assignment Submission</h3>
                                        <hr className="hr" />

                                        <div className="mb-3">
                                            <label htmlFor="formFileLg" className="form-label">
                                                Drop file here
                                            </label>
                                            <input
                                                className="form-control form-control-lg"
                                                id="formFileLg"
                                                type="file"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <button className="default__button" onClick={() => SubmitAssignment()}>
                                            Submit Assignment
                                        </button>
                                    </div> : ''}


                            </div>



                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
