import React from 'react'
import AdminLogins from '../../auth/adminsignup'
import BreadCrumb from '../../components/breadCrumb'
export default function AdminLogin() {
    return (
      <>
        <BreadCrumb heading={'Admin Login'}/>
  
      <AdminLogins/>
          
      </>
    )
  }
  