import React from "react";
import { Link } from "react-router-dom";
export default function Testimonials() {
  return (
    <>
      {/* Testimonials__section__3__start */}
      <div className="aboutarea__3 testimonial__area__2 sp_bottom_90 sp_top_120">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 custom__review__grid" data-aos="fade-up">
              <div className="section__title aboutarea__3__section__title">
                <div className="section__title__button">
                  <div className="default__small__button">Course List</div>
                </div>
                <div className="section__title__heading">
                  <h2>
                    What They Say
                    <br />
                    About us
                  </h2>
                </div>
              </div>
              <div className="aboutarea__3__content">
                <p>
                  Construction is a general term meaning the art and science to
                  form systems organizations and comes from Latin Construction
                  is
                </p>
              </div>
              <div className="aboutarea__3__button">
                <Link className="default__button" to="#">
                  Explore More
                  <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 custom__review__grid" data-aos="fade-up">
              <div className="aboutarea__content__wraper__3">
                <div className="aboutarea__para__3">
                  <p>
                    “The other hand we denounce righteou indg ation men who are
                    so beguiled and demoraliz by the the mo ment.Dislike men who
                    so development co”{" "}
                  </p>
                  <div className="aboutarea__icon__3">
                    <i className="icofont-quote-left" />
                  </div>
                </div>
                <div className="aboutarea__img__3">
                  <img
                    loading="lazy"
                    src="/assets/img/about/about_5.png"
                    alt="about"
                  />
                  <div className="aboutarea__img__name">
                    <h6>Robind Jon</h6>
                    <p>Desginer TechBoot</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 custom__review__grid" data-aos="fade-up">
              <div className="aboutarea__content__wraper__3">
                <div className="aboutarea__para__3">
                  <p>
                    “The other hand we denounce righteou indg ation men who are
                    so beguiled and demoraliz by the the mo ment.Dislike men who
                    so development co”{" "}
                  </p>
                  <div className="aboutarea__icon__3">
                    <i className="icofont-quote-left" />
                  </div>
                </div>
                <div className="aboutarea__img__3">
                  <img
                    loading="lazy"
                    src="/assets/img/about/about_5.png"
                    alt="about"
                  />
                  <div className="aboutarea__img__name">
                    <h6>Robind Jon</h6>
                    <p>Desginer TechBoot</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutarea__img__3">
          <img
            loading="lazy"
            className="aboutarea__3__img__1"
            src="/assets/img/about/about_6.png"
            alt="about"
          />
          <img
            loading="lazy"
            className="aboutarea__3__img__2"
            src="/assets/img/about/about_7.png"
            alt="about"
          />
          <img
            loading="lazy"
            className="aboutarea__3__img__3"
            src="/assets/img/about/about_9.png"
            alt="about"
          />
        </div>
      </div>
      {/* Testimonials__section__3__end */}
    </>
  );
}
