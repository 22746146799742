import React from 'react'
import Blogdetailcomp from '../../components/blogdetails'
import BreadCrumb from '../../components/breadCrumb'
export default function blogdetails() {
  return (
    <>
    <BreadCrumb  heading={"Blog Details"}/>
    <Blogdetailcomp/>
        
    </>
  )
}
