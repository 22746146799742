import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Sidbar from '../../layout/admin/sidbar'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { useDropzone } from 'react-dropzone';
import { ADMIN } from '../../Router/paths';
import Breadcrumb from '../../layout/admin/breadcrumb';


export default function Lessons({ onFetchQuizData, lessonId }) {
    const [loader, setLoader] = useState(false);
    const [datacourses, setDatacourses] = useState([]);

  
    // Add 
    const [title, setTitle] = useState("");
    const [descp, setDescp] = useState("");
    const [files, setFiles] = useState([]);
    const [courseid, setCourseid] = useState('');


    // edit 

    const [etitle, seteTitle] = useState("");
    const [edescp, seteDescp] = useState("");
    const [id, setId] = useState("");
    const [ecourseid, seteCourseid] = useState('');
console.log("ecourseid",ecourseid)

    // delete 
    const [deleteid, setDeleteid] = useState("");

    // get data 
    const [data, setData] = useState([]);


    useEffect(() => {
        window.scroll(0,0)
        GetLessons()
        GetCourses()

    }, [])
      // GET COURSES
      const GetCourses = () => {
        setLoader(true);
        axiosInstance.get('/api/courses')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get data",Data)
                    setDatacourses(Data.courses)

                } else {
                    setLoader(false);
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }
    const handleInputChange = (event, func) => {
        func(event.target.value);
    }


    const { getRootProps, getInputProps } = useDropzone({
        accept: ['video/*'],
        maxFiles: 10,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: file.type.startsWith('video/') ? URL.createObjectURL(file) : undefined,
                    })
                )
            );
        },
    });


    // ADD API
    const AddLessons = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('course_id', courseid)
        formData.append('title', title)
        formData.append('description', descp)
        for (var i = 0; i < files.length; i++) {
            formData.append("file[]", files[i]);
        }

        axiosInstance.post("/api/create/lesson", formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;

                if (Data?.status) {
                    CloseAddmodal()
                    toast.success(Data?.message);
                    GetLessons()
                    setDescp("")
                    setTitle("")
                    setFiles([])

                }

                else {

                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message


                setLoader(false);
                toast.error(Error);
            })
    }

    // GET API 

    const GetLessons = () => {
        setLoader(true);
        axiosInstance.get('/api/all/lesson')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all lessons", Data)
                    setData(Data.lessons)

                } else {
                    setLoader(false);
                    toast.error(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    const DeleteModal = (data) => {
        setDeleteid(data.id)

    }

    // DELETE 
    const DeleteLesson = () => {
        setLoader(true);
        axiosInstance.get(`/api/delete/lesson/${deleteid}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetLessons()
                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    // EDIT FUNC 

    const Editmodal = (item) => {
        console.log("item edit", item)
        seteCourseid(item.course_id)
        seteDescp(item.description)
        seteTitle(item.title)
        setId(item.id)


    }

    // UPDATE  
    const UpdateLesson = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('title', etitle)
        formData.append('description', edescp)
        formData.append('course_id', ecourseid)



        axiosInstance.post(`/api/update/lesson/${id}`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data update", Data)
                if (Data?.status) {
                    CloseEditmodal()
                    toast.success(Data.message);
                    GetLessons()

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtndlt", closeBtn)

        closeBtn.click();

    }

    const CloseEditmodal = () => {
        const deletemodal = document.getElementById("staticBackdropedit");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }


    return (

        <>
            {loader === true ? <Loader fullPage loading /> : null}
         
            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />

                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />

                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Lessons</h4>
                                    </div>
                                    <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>Lessons</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                {/* <Link className="default__button" to="#" onClick={handleShow}>
                                                    Add Lesson
                                                </Link> */}
                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                    Add Lesson
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th>View</th>
                                                            <th>Quiz</th>
                                                            <th>Assignment</th>
                                                            <th>Submitted</th>




                                                            <th>Action</th>
                                                            {/* <th>Quiz</th> */}


                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((item, index) => (

                                                            <tr key={index} className={index % 2 === 0 ? 'dashboard__table__row even' : 'dashboard__table__row odd'}>
                                                                <th>
                                                                    <p>{item.title}</p>
                                                                    {/* <p>{item.name}</p> */}
                                                                </th>
                                                                <td>{item.description}</td>

                                                              
                                                                <td>   <Link className="dashboard__small__btn" to={ADMIN.lessonvideos(item.id, item.title)}>  videos </Link></td>
                                                                <td>   <Link className="dashboard__small__btn"    to={ADMIN.quiz(item.id)}>  Quiz </Link></td>
                                                                <td>   <Link className="dashboard__small__btn"    to={ADMIN.assignment(item.id)}>  Assignment </Link></td>
                                                                <td>   <Link className="dashboard__small__btn"    to={ADMIN.submitassignment(item.id)}> Submitted Assignment </Link></td>



                                                                <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td>
                                                                            <div className="dashboard__button__group d-flex">

                                                                                <button className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdropedit" onClick={() => Editmodal(item)}> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg>{" "} Edit </button>

                                                                                <button className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => DeleteModal(item)}> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Delete </button>

                                                                                {/* <Link className="modal_button" 
                                                                                to={ADMIN.quiz(item.id)} > <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Quiz </Link> */}

                                                                            </div>
                                                                        </td>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        ))}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD MODAL  */}


            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Lesson</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="title" class="form-label">Title</label>
                                        <input
                                            id='title'
                                            className="form-control"
                                            value={title}
                                            onChange={(e) => handleInputChange(e, setTitle)}
                                            type="text"
                                            placeholder="Enter Title"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="Description" class="form-label">Description</label>
                                        <input
                                            id='Description'
                                            class="form-control"
                                            value={descp}
                                            onChange={(e) => handleInputChange(e, setDescp)}
                                            type="text"
                                            placeholder="Enter Description"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Select Course</label>
                                            <select value={courseid} onChange={(e) => handleInputChange(e, setCourseid)} className="form-select" aria-label="Default select example">
                                                <option value="" disabled selected>
                                                    Select ...
                                                </option>
                                                {datacourses.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <div
                                        {...getRootProps({ className: "dropzone" })}
                                        style={{
                                            border: "2px dotted  rgba(90,204,230)",
                                            width: "100%",
                                            height: "100%",
                                            padding: "30px",
                                            backgroundColor: "rgba(68,70,84)",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <p className="text-center text-white"> Upload your lesson video</p>
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div className='mt-4'>
                                        {files.map((file) => (
                                            <div key={file.name} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                                {file.type.startsWith('video/') ? (
                                                    <>
                                                        <video
                                                            src={file.preview}
                                                            alt={file.name}
                                                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                            controls
                                                        />
                                                        <p>{file.name}</p>
                                                    </>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => AddLessons()}>
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Modal  */}

            <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Lesson</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="etitle" class="form-label">Title</label>
                                        <input
                                            id='etitle'
                                            className="form-control"
                                            value={etitle}
                                            onChange={(e) => handleInputChange(e, seteTitle)}
                                            type="text"
                                            placeholder="Edit Title"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="Description" class="form-label">Description</label>
                                        <input
                                            id='Description'
                                            class="form-control"
                                            value={edescp}
                                            onChange={(e) => handleInputChange(e, seteDescp)}
                                            type="text"
                                            placeholder="Edit Description"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Select Course</label>
                                            <select value={ecourseid} onChange={(e) => handleInputChange(e, seteCourseid)} className="form-select" aria-label="Default select example">
                                                <option value="" disabled selected>
                                                    Select ...
                                                </option>
                                                {datacourses.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="default__button" onClick={() => UpdateLesson()} >Update</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* DELETE  */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Lesson</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this lesson?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteLesson()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
