import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { STUDENT } from "../../Router/paths";


export default function Pricing() {
  const [loader, setLoader] = useState(false);
  const location = useLocation()
  const navigate = useNavigate()
  const Data = location?.state
  const [paymentid, setPaymentid] = useState("");
  const [email, setEmail] = useState("");
  const Token = localStorage.getItem("token")
  console.log("paymentid", paymentid)
  console.log("location data", location.state)

  useEffect(() => {
    if (Token == null) {
      toast.error('Please Login First To Buy Course');
      navigate(STUDENT.login);
    }

    window.scroll(0, 0)

  }, [Token,navigate])



  // payment 
  const onToken = (token) => {
    console.log("ontoken", token)
    setPaymentid(token.id)
    setEmail(token.email)

  }

  const paymentStripe = () => {
    let a = document.getElementById('paymentmethod')
    a.children[0].click()
  }

  // Login api
  const PaywithStripe = () => {
    if (Token === null) {
      toast.error("Please Login First")
      navigate(STUDENT.login);
    }
    else {

      setLoader(true);
      const formData = new FormData();
      formData.append('payment_platform', "Stripe")
      formData.append('token', paymentid)
      formData.append('course_id', Data?.id)
      formData.append('email', email)
      formData.append('amount', Data?.price)

      axiosInstance.post("/api/course/subscription", formData)
        .then((response) => {
          const Data = response?.data;
          setLoader(false);
          if (Data?.status) {
            toast.success(Data.message)
            navigate(STUDENT.dashboard);
          }


          else {
            toast.error(Data.message);

          }


        }).catch((err) => {

          const Error = err?.response?.data?.message
          setLoader(false);
          toast.error(Error);

        })
    }
  }




  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}


      {/* Pricing__section__start */}
      <div className="pricingarea sp_bottom_100 sp_top_90">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="section__title text-center">
              <div className="section__title__button">
                <div className="default__small__button">Pricing Plan</div>
              </div>
              <div className="section__title__heading">
                <h2>
                  Choose The Best Package
                  <br />
                  For your Learning
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" data-aos="fade-up">
              <div className="pricingarea__content__wraper">
                <div className="pricingarea__heading">
                  <div className="pricingarea__plan__type">
                    <h6>{Data?.course_level}</h6>
                    <img
                      loading="lazy"
                      src="/assets/img/icon/price__1.png"
                      alt=""
                    />
                  </div>
                  <div className="pricingarea__number">
                    <h1>
                      <span className="price__currency">$</span>{Data?.price_discount}
                      <span className="price__durition">/ month</span>
                    </h1>
                  </div>
                  <p>Perfect for startup</p>
                </div>
                <div className="pricingarea__list">
                  <ul>
                    <li>
                      <i className="icofont-check" />Title: {Data?.title}
                    </li>
                    <li>
                      <i className="icofont-check" /> Certificate: {Data?.certificate}
                    </li>
                    <li>
                      <i className="icofont-check" />Language: {Data?.language}

                    </li>
                    <li>
                      <i className="icofont-check" />Start Date: {Data?.start_date}

                    </li>
                    <li>
                      <i className="icofont-check" />Skill Level: {Data?.skill_level}
                    </li>
                  </ul>
                </div>

                {paymentid === "" ?
                
              
              
              
                <div className="pricingarea__button d-flex justify-content-center">
                  <div onClick={() => paymentStripe()}>
                    <button className="default__button"> Pay with Stripe</button>
                    <div id='paymentmethod' className='d-none'>
                      <StripeCheckout
                        token={onToken}
                        stripeKey="pk_test_51MdqNVAOm2Y7pmXtOPM7GnEqm0icL0bkvRAKxCdVUjnRyIKkDh5HGnVexJGiDG48c9B4kLQKxIVwCCC4DyTjdP0o00FWouzEvv"
                        name='payment'
                      />
                    </div>



                  </div>
                </div>:

                  <div className="pricingarea__button d-flex justify-content-center">
                    <Link onClick={() => PaywithStripe()} className="default__button" to="">Buy Now</Link>
                  </div> }


              </div>
            </div>


          </div>
        </div>
      </div>
      {/* Pricing__section__end*/}
    </>
  );
}
