import React from 'react'
import Assing from '../../components/lesson/assignment'
import BreadCrumb from '../../components/breadCrumb'
export default function Assignment() {
    return (
        <div>
            <BreadCrumb heading={"Assignment"} />
            <Assing />

        </div>
    )
}
