import { useState, useEffect, useRef } from 'react'
import Sidbar from '../../layout/admin/sidbar'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/admin/breadcrumb';
import "./style.css"
export default function Admincourses() {
    const [loader, setLoader] = useState(false);

    // Add 
    const [title, setTitle] = useState('');
    const [descp, setDescp] = useState('');
    const [courselevel, setCourselevel] = useState('');
    const [lang, setLang] = useState('');
    const [price, setPrice] = useState('');
    const [disprice, setDisprice] = useState('');
    const [coursestatus, setCoursestatus] = useState('');
    const [startdate, setStartdate] = useState('');
    const [skilllevel, setSkilllevel] = useState('');
    const [isquiz, setIsquiz] = useState('');
    const [certificate, setCertificate] = useState('');
    const [categoryid, setCategoryid] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef();
    const [id, setId] = useState("");




    // EDIT 

    const [etitle, seteTitle] = useState('');
    const [edescp, seteDescp] = useState('');
    const [ecourselevel, seteCourselevel] = useState('');
    const [elang, seteLang] = useState('');
    const [eprice, setePrice] = useState('');
    const [edisprice, seteDisprice] = useState('');
    const [ecoursestatus, seteCoursestatus] = useState('');
    const [estartdate, seteStartdate] = useState('');
    const [eskilllevel, seteSkilllevel] = useState('');
    const [eisquiz, seteIsquiz] = useState('');
    const [ecertificate, seteCertificate] = useState('');
    const [ecategoryid, seteCategoryid] = useState('');
    const [deleteid, setDeleteid] = useState("");
    const [eselectedFile, seteSelectedFile] = useState(null);
    const [updatedImageFile, setUpdatedImageFile] = useState(null);
    console.log("updatedImageFile",updatedImageFile)
    const fileInputRefe = useRef();

    // get data 
    const [data, setData] = useState([]);
    const [datacourses, setDatacourses] = useState([]);
    console.log('datacourses', datacourses)

    useEffect(() => {
        window.scroll(0, 0)
        GetCategory()
        GetCourses()

    }, [])


    // GET Categories 
    const GetCategory = () => {
        setLoader(true);
        axiosInstance.get('/api/categories')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get data",)
                    setData(Data.categories)

                } else {
                    setLoader(false);
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }

    // GET Categories 
    const GetCourses = () => {
        setLoader(true);
        axiosInstance.get('/api/courses')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get data",)
                    setDatacourses(Data.courses)

                } else {
                    setLoader(false);
                    toast.warn(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    // ADD API
    const AddCourse = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('course_category_id', categoryid)
        formData.append('title', title)
        formData.append('description', descp)
        formData.append('course_level', courselevel)
        formData.append('language', lang)
        formData.append('price', price)
        formData.append('price_discount', disprice)
        formData.append('course_status', coursestatus)
        formData.append('start_date', startdate)
        formData.append('skill_level', skilllevel)
        formData.append('is_quiz', isquiz)
        formData.append('certificate', certificate)
        formData.append('image', selectedFile)


        axiosInstance.post("/api/create/course", formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("Data course", Data)

                if (Data?.status) {
                    GetCourses()
                    CloseAddmodal()
                    toast.success(Data.message);
                    setSelectedFile(null)

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const Deltemodal = (item) => {
        setDeleteid(item.id)



    }

    // DELETE 

    const DeleteCateogry = () => {
        setLoader(true);

        axiosInstance.get(`/api/delete/course/${deleteid}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetCourses()

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    // EDIT FUNC 

    const Editmodal = (item) => {
        console.log("item edit", item)
        seteSelectedFile(item.image)
        seteCategoryid(item.course_category_id)
        seteTitle(item.title)
        seteDescp(item.description)
        seteCourselevel(item.course_level)
        seteLang(item.language)
        setePrice(item.price)
        seteDisprice(item.price_discount)
        seteSkilllevel(item.skill_level)
        seteStartdate(item.start_date)
        seteIsquiz(item.is_quiz)
        seteCertificate(item.certificate)
        seteCoursestatus(item.course_status)
        setId(item.id)



    }

    // UPDATE  


    const UpdateCourse = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('course_category_id', ecategoryid)
        formData.append('title', etitle)
        formData.append('description', edescp)
        formData.append('course_level', ecourselevel)
        formData.append('language', elang)
        formData.append('price', eprice)
        formData.append('price_discount', edisprice)
        formData.append('course_status', ecoursestatus)
        formData.append('start_date', estartdate)
        formData.append('skill_level', eskilllevel)
        formData.append('is_quiz', eisquiz)
        formData.append('certificate', ecertificate)
        formData.append('image', updatedImageFile)


        axiosInstance.post(`api/update/course/${id}}`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data update", Data)
                if (Data?.status) {
                    CloseEditmodal()
                    toast.success(Data.message);
                    GetCourses()

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const handleInputChange = (event, func) => {
        func(event.target.value);
    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    const CloseEditmodal = () => {
        const deletemodal = document.getElementById("staticBackdropedit");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }



// image upload add
   

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleChooseImageClick = () => {
        fileInputRef.current.click();
    };


    // update image 
    const handleFileUpdate = (event) => {
        console.log("update",event)
        const file = event.target.files[0];
        setUpdatedImageFile(file);
    };

    const handleChooseImageUpdate= () => {
        fileInputRefe.current.click();
    };



    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />

                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Courses</h4>
                                    </div>
                                    <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>Courses</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                {/* <Link className="default__button" to="#" onClick={handleShow}>
                                                    Add Course
                                                </Link> */}
                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                    Add Course
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th>Image</th>

                                                            <th>Language</th>
                                                            <th>Certificate</th>
                                                            <th>Course level</th>
                                                            <th>Price</th>
                                                            <th>Price Discount</th>
                                                            <th>Start Date</th>

                                                            <th>Status</th>


                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {datacourses.map((item, index) => (
                                                            <tr key={index} className={index % 2 === 0 ? 'dashboard__table__row even' : 'dashboard__table__row odd'}>
                                                                <th>
                                                                    <p>{item.title}</p>

                                                                </th>
                                                                <th>
                                                                    <p>{item.description}</p>

                                                                </th>
                                                                <th>{item.image ?

                                                                    <img src={item.image} alt='' width={30} />
                                                                    : "-"}

                                                                </th>
                                                                <th>
                                                                    <p>{item.language}</p>

                                                                </th>
                                                                <th>
                                                                    <p>{item.certificate}</p>

                                                                </th>
                                                                <th>
                                                                    <p>{item.course_level}</p>

                                                                </th>
                                                                <th>
                                                                    <p>{item.price}</p>

                                                                </th>
                                                                <th>
                                                                    <p>{item.price_discount}</p>

                                                                </th>


                                                                <th>
                                                                    <p>{item.start_date}</p>

                                                                </th>



                                                                <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td>
                                                                            <div className="dashboard__button__group">



                                                                                <button type='button' className="modal_button " data-bs-toggle="modal" data-bs-target="#staticBackdropedit" onClick={() =>{ Editmodal(item); setUpdatedImageFile(null);}}> <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg>{" "} Edit </button>


                                                                                <button className="modal_button mt-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => Deltemodal(item)}> <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Delete </button>
                                                                            </div>
                                                                        </td>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD  */}

            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Course</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="profile-pic-wrapper">
                                        <div className="pic-holder">
                                            {selectedFile ? (
                                                <img
                                                    id="profilePic"
                                                    className="pic"
                                                    src={URL.createObjectURL(selectedFile)}
                                                    alt="Selected"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => handleChooseImageClick()}
                                                />
                                            ) : (
                                                <label htmlFor="newProfilePhoto" className="upload-file-block" onClick={() => handleChooseImageClick()}>
                                                    <div className="text-center">
                                                        <div className="mb-2">
                                                            <i className="fa fa-camera fa-2x" />
                                                        </div>
                                                        <div className="text-uppercase">
                                                            Update <br /> Course Photo
                                                        </div>
                                                    </div>
                                                </label>
                                            )}
                                            <input
                                                className="uploadProfileInput"
                                                type="file"
                                                name="profile_pic"
                                                id="newProfilePhoto"
                                                accept="image/*"
                                                style={{ opacity: 0 }}
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="category" class="form-label">Title</label>
                                        <input
                                            class="form-control"
                                            id="category"
                                            value={title}
                                            onChange={(e) => handleInputChange(e, setTitle)}
                                            type="text"
                                            placeholder="Enter Title"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="category" class="form-label">Description</label>
                                        <input
                                            class="form-control"
                                            value={descp}
                                            onChange={(e) => handleInputChange(e, setDescp)}
                                            type="text"
                                            placeholder="Enter Description"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="course" class="form-label">course level</label>
                                        <input
                                            class="form-control"
                                            value={courselevel}
                                            onChange={(e) => handleInputChange(e, setCourselevel)}
                                            type="text"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="Language" class="form-label">Language</label>
                                        <input
                                            class="form-control"
                                            value={lang}
                                            onChange={(e) => handleInputChange(e, setLang)}
                                            type="text"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="price" class="form-label">price</label>
                                        <input
                                            class="form-control"
                                            value={price}
                                            onChange={(e) => handleInputChange(e, setPrice)}
                                            type="text"
                                            placeholder="Enter Price"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="category" class="form-label">Price Discount</label>
                                        <input
                                            class="form-control"
                                            value={disprice}
                                            onChange={(e) => handleInputChange(e, setDisprice)}
                                            type="text"
                                            placeholder="Price Discount"


                                        />

                                    </div>

                                </div>

                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Course Status</label>
                                            <select value={coursestatus} onChange={(e) => handleInputChange(e, setCoursestatus)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="Available">Available</option>
                                                <option value="Unavailable">Unavailable</option>
                                            </select>
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="date" class="form-label">Start Date</label>
                                        <input
                                            class="form-control"
                                            value={startdate}
                                            onChange={(e) => handleInputChange(e, setStartdate)}
                                            type="date"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="Skill" class="form-label">Skill Level</label>
                                        <input
                                            id='Skill'
                                            class="form-control"
                                            value={skilllevel}
                                            onChange={(e) => handleInputChange(e, setSkilllevel)}
                                            type="text"
                                            placeholder="Enter Skill Level"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Is Quiz</label>
                                            <select value={isquiz} onChange={(e) => handleInputChange(e, setIsquiz)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Certificate</label>
                                            <select value={certificate} onChange={(e) => handleInputChange(e, setCertificate)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Select Category</label>
                                            <select value={categoryid} onChange={(e) => handleInputChange(e, setCategoryid)} className="form-select" aria-label="Default select example">
                                                <option value="" disabled selected>
                                                    Select ...
                                                </option>
                                                {data.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="default__button" onClick={() => AddCourse()} >Add</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* EDIT  */}

            <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Update Course</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="profile-pic-wrapper">
                                        <div className="pic-holder">
                                            <img
                                                id="profilePic"
                                                className="pic"
                                                src={updatedImageFile ? URL.createObjectURL(updatedImageFile)  : eselectedFile}  // Use the value from the state
                                                alt="Selected"
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <label htmlFor="newProfilePhoto" className="upload-file-block" onClick={handleChooseImageUpdate}>
                                                <div className="text-center">
                                                    <div className="mb-2">
                                                        <i className="fa fa-camera fa-2x" />
                                                    </div>
                                                    <div className="text-uppercase">
                                                        Update <br /> Course Photo
                                                    </div>
                                                </div>
                                            </label>
                                            <input
                                                className="uploadProfileInput"
                                                type="file"
                                                name="profile_pic"
                                                id="newProfilePhoto"
                                                accept="image/*"
                                                ref={fileInputRefe}
                                                style={{ opacity: 0 }}
                                               onChange={handleFileUpdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="category" class="form-label">Title</label>
                                        <input
                                            class="form-control"
                                            id="category"
                                            value={etitle}
                                            onChange={(e) => handleInputChange(e, seteTitle)}
                                            type="text"

                                        />
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label for="Description" class="form-label">Description</label>
                                        <input
                                            id="Description"
                                            class="form-control"
                                            value={edescp}
                                            onChange={(e) => handleInputChange(e, seteDescp)}
                                            type="text"

                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label htmlFor="course" class="form-label">course level</label>
                                        <input
                                            id='course'
                                            class="form-control"
                                            value={ecourselevel}
                                            onChange={(e) => handleInputChange(e, seteCourselevel)}
                                            type="text"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label htmlFor="Language" class="form-label">Language</label>
                                        <input
                                            id='Language'
                                            class="form-control"
                                            value={elang}
                                            onChange={(e) => handleInputChange(e, seteLang)}
                                            type="text"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label htmlFor="price" class="form-label">price</label>
                                        <input
                                            id='price'
                                            class="form-control"
                                            value={eprice}
                                            onChange={(e) => handleInputChange(e, setePrice)}
                                            type="text"
                                        />

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label htmlFor="Discount" class="form-label">Price Discount</label>
                                        <input
                                            id='Discount'
                                            class="form-control"
                                            value={edisprice}
                                            onChange={(e) => handleInputChange(e, seteDisprice)}
                                            type="text"

                                        />

                                    </div>

                                </div>

                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Course Status</label>
                                            <select value={ecoursestatus} onChange={(e) => handleInputChange(e, seteCoursestatus)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="Available">Available</option>
                                                <option value="Unavailable">Unavailable</option>
                                            </select>
                                        </div>

                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label htmlFor="date" class="form-label">Start Date</label>
                                        <input
                                            id='date'
                                            class="form-control"
                                            value={estartdate}
                                            onChange={(e) => handleInputChange(e, seteStartdate)}
                                            type="date"
                                        />

                                    </div>

                                </div>

                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <label htmlFor="Skill" class="form-label">Skill Level</label>
                                        <input
                                            id='Skill'
                                            class="form-control"
                                            value={eskilllevel}
                                            onChange={(e) => handleInputChange(e, seteSkilllevel)}
                                            type="text"

                                        />

                                    </div>

                                </div>

                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Is Quiz</label>
                                            <select value={eisquiz} onChange={(e) => handleInputChange(e, seteIsquiz)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Certificate</label>
                                            <select value={ecertificate} onChange={(e) => handleInputChange(e, seteCertificate)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>

                                    </div>

                                </div>

                                <div className='col-md-6'>
                                    <div class="mb-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1">Select Category</label>
                                            <select value={ecategoryid} onChange={(e) => handleInputChange(e, seteCategoryid)} className="form-select" aria-label="Default select example">
                                                <option value="" disabled selected>
                                                    Select ...
                                                </option>
                                                {data.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="default__button" onClick={() => UpdateCourse()} >Update</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* DELETE  */}


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Course</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this course?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteCateogry()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
