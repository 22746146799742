import React from "react";
import BreadCrumb from "../../components/breadCrumb";
import CoursesDeta from "../../components/coursedetail";

export default function CourseDetail() {
  return (
    <>
      <BreadCrumb heading={'Course-Details'}/>
      <CoursesDeta />
    </>
  );
}
