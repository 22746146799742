import React from 'react'
import Sidbar from '../../layout/admin/sidbar'
import Breadcrumb from '../../layout/admin/breadcrumb'

export default function Dashboard() {

 
    return (
        <div>
            <div className="dashboardarea sp_bottom_100">
              <Breadcrumb/>
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                              <Sidbar/>
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Dashboard</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className="dashboard__single__counter">
                                                <div className="counterarea__text__wraper">
                                                    <div className="counter__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/counter/counter__1.png"
                                                            alt="counter"
                                                        />
                                                    </div>
                                                    <div className="counter__content__wraper">
                                                        <div className="counter__number">
                                                            <span className="counter">900</span>+
                                                        </div>
                                                        <p>Enrolled Courses</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className="dashboard__single__counter">
                                                <div className="counterarea__text__wraper">
                                                    <div className="counter__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/counter/counter__2.png"
                                                            alt="counter"
                                                        />
                                                    </div>
                                                    <div className="counter__content__wraper">
                                                        <div className="counter__number">
                                                            <span className="counter">500</span>+
                                                        </div>
                                                        <p>Active Courses</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className="dashboard__single__counter">
                                                <div className="counterarea__text__wraper">
                                                    <div className="counter__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/counter/counter__3.png"
                                                            alt="counter"
                                                        />
                                                    </div>
                                                    <div className="counter__content__wraper">
                                                        <div className="counter__number">
                                                            <span className="counter">300</span>k
                                                        </div>
                                                        <p>Complete Courses</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className="dashboard__single__counter">
                                                <div className="counterarea__text__wraper">
                                                    <div className="counter__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/counter/counter__4.png"
                                                            alt="counter"
                                                        />
                                                    </div>
                                                    <div className="counter__content__wraper">
                                                        <div className="counter__number">
                                                            <span className="counter">1500</span>+
                                                        </div>
                                                        <p>Total Courses</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className="dashboard__single__counter">
                                                <div className="counterarea__text__wraper">
                                                    <div className="counter__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/counter/counter__3.png"
                                                            alt="counter"
                                                        />
                                                    </div>
                                                    <div className="counter__content__wraper">
                                                        <div className="counter__number">
                                                            <span className="counter">30</span>k
                                                        </div>
                                                        <p>Total Students</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                            <div className="dashboard__single__counter">
                                                <div className="counterarea__text__wraper">
                                                    <div className="counter__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/counter/counter__4.png"
                                                            alt="counter"
                                                        />
                                                    </div>
                                                    <div className="counter__content__wraper">
                                                        <div className="counter__number">
                                                            <span className="counter">90,000</span>K+
                                                        </div>
                                                        <p>Total Earning</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
