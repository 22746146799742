import { useEffect, useState } from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { axiosInstance } from '../../config/https';
import { Link, useParams } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Questions() {
    const [loader, setLoader] = useState(false);
    // const [data, setData] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        window.scroll(0, 0)
        GetAllQuestions(id)
    }, [id])

    const GetAllQuestions = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/student/quizzes/${id}/questions`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all ques", Data);

                    if (Data.quizQuestion && Data.quizQuestion.length > 0) {
                        // setData(Data.quiz);
                        setQuiz(Data.quizQuestion);
                    } else {
                        setLoader(false);
                        toast.error("Quiz questions array is empty.");
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="dashboardarea sp_bottom_100">
               <Breadcrumb/>
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <StudentSidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>My Quiz</h4>
                                    </div>

                                    <hr className="mt-40" />
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>

                                                            <th>Questions</th>
                                                            <th>Type</th>
                                                            <th>Score </th>
                                                            <th>Options </th>


                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    {quiz.map((v, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <th>

                                                                    <p>{i + 1}</p>

                                                                </th>

                                                                <th>

                                                                    <p>{v.question}</p>

                                                                </th>
                                                                <td>
                                                                    <p>{v.type}</p>
                                                                </td>


                                                                <td>{v.score} </td>

                                                                <td> <Link className="dashboard__small__btn" to=""> Options </Link></td>



                                                            </tr>



                                                        </tbody>


                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
