import React from 'react'
import BreadCrumb from "../../components/breadCrumb";
import Specific from "../../components/sections/specificCourses";
export default function SpecificCate() {
  return (
    <div>
        <BreadCrumb heading={'Courses'}/>
      <Specific />
    </div>
  )
}
