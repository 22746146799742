import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { axiosInstance } from '../../config/https';
import { useNavigate, useParams } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import "./style.css"
import { STUDENT } from '../../Router/paths';
export default function Quiz() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [quizsubmit, setQuizsubmit] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const { id } = useParams(); 
    const modalRef = useRef(null);
    const Navigate = useNavigate()

    useEffect(() => {
        window.scroll(0, 0)
        GetAllQuiz(id)
    }, [id])


    const [combinedSelectedAnswers, setCombinedSelectedAnswers] = useState();
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [selectedmulAnswers, setSelectedmulAnswers] = useState([]);

  

    // Combine data from selectedAnswers and selectedmulAnswers
    useEffect(() => {
        const combinedData = { ...selectedAnswers, ...selectedmulAnswers };
        setCombinedSelectedAnswers(combinedData);
    }, [selectedAnswers, selectedmulAnswers]);

    const GetAllQuiz = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/student/quizzes/${id}/questions`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all ques opt", Data)
                    setData(Data.quiz)
                    setQuiz(Data.quizQuestion)
                } else {
                    setLoader(false);
                    toast.error(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    // Function to update selected answers
    const handleOptionSelect = (questionIndex, optionIndex) => {
        const questionId = quiz[questionIndex].id; // Replace 'id' with the actual property name in your question object
        const optionId = quiz[questionIndex].options[optionIndex].id; // Replace 'id' with the actual property name in your option object

        setSelectedAnswers((prevSelectedAnswers) => ({
            ...prevSelectedAnswers,
            [questionIndex]: { question_type: quiz[questionIndex].type, question_id: questionId, option_id: optionId },
        }));
    };

    // Function to update selected multiple ans
    const handleMultipleSelect = (questionIndex, isChecked, optionId) => {
        const questionId = quiz[questionIndex].id;

        setSelectedmulAnswers((prevSelectedAnswers) => {
            const updatedOptions = isChecked
                ? [...(prevSelectedAnswers[questionIndex]?.option_id || []), optionId]
                : (prevSelectedAnswers[questionIndex]?.option_id || []).filter((id) => id !== optionId);

            return {
                ...prevSelectedAnswers,
                [questionIndex]: {
                    question_type: quiz[questionIndex].type,
                    question_id: questionId,
                    option_id: updatedOptions,
                },
            };
        });
    };

    // ADD API
    const SubmitAns = () => {
        setLoader(true);
        const formData = new FormData();
        // Convert the object into an array
        // Convert the object into an array of objects
        const answersArray = Object.values(combinedSelectedAnswers).map(obj => {
            const { question_index, ...rest } = obj;  // Exclude 'question_index'
            return rest;
        });

        // Convert the array to JSON string
        const jsonString = JSON.stringify(answersArray);

        // Append the JSON string to the FormData
        formData.append("quiz_answer", jsonString);

        axiosInstance.post(`/api/quizzes/${id}/submit/answers`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;

                if (Data?.status) {
                    console.log('data', Data)
                    setQuizsubmit(Data)
                    // toast.success(Data.message)
                    openAddModal()
                }

                else {
                    setLoader(false)
                    toast.error(Data.message)

                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const openAddModal = () => {
        // Open the modal
        const modalElement = modalRef.current;
        if (modalElement) {
          modalElement.classList.add('show');
          modalElement.style.display = 'block';
        }
      };

      const closeModal = () => {
        // Close the modal
        const modalElement = modalRef.current;
        if (modalElement) {
          modalElement.classList.remove('show');
          modalElement.style.display = 'none';
          Navigate(STUDENT.quizdetail)
        }
      };
    

    return (
        <>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="tution sp_bottom_100 sp_top_100">
                <div className="container-fluid full__width__padding">
                    <div className="row d-flex justify-content-center">

                    {/* <button type="button" className="default__button" onClick={openAddModal}>
                                                    Add Category
                                                </button> */}
                       
                        <div
                            className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
                            data-aos="fade-up"
                        >
                            <div class="dashboard__Announcement__wraper">
                                <div class="row ">
                                    <div class="col-xl-8 col-lg-6 col-md-6 col-12">
                                        <div class="dashboard__Announcement">
                                            <h5>Title : <strong>{data.title}</strong> </h5>
                                        </div>
                                    </div>

                                </div>
                                <div class="row ">

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="dashboard__Announcement">
                                            <h5>Description: <strong>{data.description}</strong> </h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <ul>
                                <h3>Title : <strong>{data.title}</strong>  </h3> <br />
                                <h5>Description: <strong>{data.description}</strong></h5>

                            </ul> */}
                            <div className="lesson__quiz__wrap">
                                <div>

                                    {quiz.map((question, index) => (
                                            <>
                                        <div className="quiz__single__attempt" key={index}>
                                            <ul>
                                                <li>Question : {index + 1}/{quiz.length} | </li>
                                                <li> Type : <strong>{question.type} </strong> </li>
                                                <li> | score: <strong>{question.score}</strong></li>
                                            </ul>
                                            <hr className="hr mt-4" />
                                            <h3>{index + 1}. {question.question}?</h3>
                                            <div className="row">
                                                <div className="row col-md-12">
                                                    {question.options.map((option, optionIndex) => (
                                                        <div key={optionIndex} className="col-md-6 form-check">
                                                            {question.type === "multiple_choice" ? (
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id={`flexCheckDefault_${index}_${optionIndex}`}
                                                                    onChange={(e) => handleMultipleSelect(index, e.target.checked, option.id)}


                                                                />
                                                            ) : (
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value=""
                                                                    id={`flexCheckDefault_${index}_${optionIndex}`}
                                                                    name={`radio_${index}`}
                                                                    onChange={() => handleOptionSelect(index, optionIndex)}
                                                                />
                                                            )}
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`flexCheckDefault_${index}_${optionIndex}`}
                                                            >
                                                                {option.option}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                   
                                           <br/><br/><br/>
                                       </> 
                                    ))}
                                    

                                </div>


                                <button className="default__button" type='button' onClick={() => SubmitAns()}>
                                    {" "}
                                    Quiz Submit
                                    <i className="icofont-long-arrow-right" />
                                </button>
                                <br />


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD MODAL  */}
          
            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"  ref={modalRef}>
                <div class="modal-dialog modal-confirm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="material-icons">&#xE876;</i>
                            </div>
                            <h4 class="modal-title w-100">{quizsubmit.message}!</h4>
                        </div>
                        <div class="modal-body">
                            <h5 class="text-center">CALCULATION</h5>
                            <div className='row'>
                                <div className='col-md-12 d-flex justify-content-center '>
                                    <ul>
                                        <p>Quiz Marks : <strong>{quizsubmit.quizMarks}</strong> </p>
                                        <p>Total Question :  <strong>{quizsubmit.totalQuestion}</strong></p>
                                        <p>Correct Answer :  <strong>{quizsubmit.correctAnswer}</strong></p>
                                        <p>Marks Obtain :  <strong>{quizsubmit.marksObtain}</strong></p>

                                    </ul>

                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button className="default__button"  onClick={closeModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>


        
            

        </>
    )
}
