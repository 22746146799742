import React from "react";

export default function BannerHome() {
  return (
    <>
      {/* herobannerarea__section__start */}
      <div className="herobannerarea herobannerarea__box herobannerarea__marketplace">
        <div className="container">
          <div className="row justify-content-center ">
            <div
              className="col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12"
              data-aos="fade-up"
            >
              <div className="herobannerarea__content__wraper text-center">
                <div className="herobannerarea__title">
                  <div className="herobannerarea__small__title">
                    <span>Education Solution</span>
                  </div>
                  <div className="herobannerarea__title__heading__2">
                    <h2>
                      Massive Courses Available for Anyone<span>.</span>
                    </h2>
                  </div>
                </div>
                {/* <div class="hreobannerarea__button">
                            <a class="herobannerarea__button__1" href="#">View Courses</a>
                            <a class="herobannerarea__button__2" href="#">Find out more
                                <i class="icofont-long-arrow-right"></i>
                            </a>
                        </div> */}
              </div>
              <form className="search__form" action="#">
                <div className="search__form__categories">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <rect
                        x={48}
                        y={48}
                        width={176}
                        height={176}
                        rx={20}
                        ry={20}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                      />
                      <rect
                        x={288}
                        y={48}
                        width={176}
                        height={176}
                        rx={20}
                        ry={20}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                      />
                      <rect
                        x={48}
                        y={288}
                        width={176}
                        height={176}
                        rx={20}
                        ry={20}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                      />
                      <rect
                        x={288}
                        y={288}
                        width={176}
                        height={176}
                        rx={20}
                        ry={20}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                      />
                    </svg>
                  </div>
                  <div className="categories__box">
                    <select className="wide">
                      <option data-display="Explore">Explore</option>
                      <option value={1}>WordPress</option>
                      <option value={2}>Shopify</option>
                      <option value={3}>Joomla</option>
                      <option value={4}>Opencart</option>
                      <option value={5}>Megento</option>
                      <option value={6}>Webflow</option>
                      <option value={7}>React</option>
                      <option value={8}>PrestaShop</option>
                    </select>
                  </div>
                </div>
                <input
                  placeholder="Find & Choose your perfect course"
                  type="text"
                />
                <div className="search__btn">
                  <button className="default__button" type="submit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      />
                    </svg>
                    Search
                  </button>
                </div>
              </form>
              <div className="keyfeatures text-center">
                <ul>
                  <li>
                    <i className="icofont-check-alt" />
                    <span>More than 2k Courses</span>
                  </li>
                  <li>
                    <i className="icofont-check-alt" />
                    <span>1.1k Free Courses</span>
                  </li>
                  <li>
                    <i className="icofont-check-alt" />
                    <span>150+ Instructors</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" >
                    <div class="aboutarea__img__inner text-center">
                        <div class="aboutarea__img" data-tilt>
                            <img loading="lazy"  class="aboutimg__1" src="/assets/img/about/about_8.png" alt="aboutimg">
                            <img loading="lazy"  class="aboutimg__2" src="/assets/img/about/about_1.png" alt="aboutimg">
                        </div>
                    </div>
                </div> */}
          </div>
        </div>
        <div className="herobannerarea__icon">
          <img
            loading="lazy"
            className="hero__icon__1"
            src="/assets/img/register/register__2.png"
            alt="register"
          />
          <img
            loading="lazy"
            className="hero__icon__2"
            src="/assets/img/herobanner/herobanner__6.png"
            alt="banner-img"
          />
          <img
            loading="lazy"
            className="hero__icon__3"
            src="/assets/img/herobanner/herobanner__7.png"
            alt="banner-img"
          />
          <img
            loading="lazy"
            className="hero__icon__4"
            src="/assets/img/herobanner/herobanner__7.png"
            alt="banner-img"
          />
        </div>
      </div>
      {/* herobannerarea__section__end*/}
    </>
  );
}
