import { useEffect, useState } from 'react'
import { axiosInstance } from '../../config/https';
import { useParams } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';

export default function AttemptQuiz() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const [marks, setMarks] = useState([]);

    const { id } = useParams(); 


    useEffect(() => {
        window.scroll(0, 0)
        GetAllQuiz(id)
    }, [id])


  
    const GetAllQuiz = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/quizzes/${id}/user/result`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all ques opt", Data.quiz)
                    setData(Data.quiz.questions)
                    setQuiz(Data.quiz)
                    setMarks(Data)
                } else {
                    setLoader(false);
                    toast.error(Data?.message);

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


  

    return (
        <>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="tution sp_bottom_100 sp_top_100">
                <div className="container-fluid full__width__padding">
                    <div className="row d-flex justify-content-center">

                
                       
                        <div
                            className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
                            data-aos="fade-up"
                        >
                            <div class="dashboard__Announcement__wraper">
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="">
                                            <h5>Quiz Marks : <strong>{marks.quizMarks}</strong> </h5>
                                            <h5>Total Question : <strong>{marks.totalQuestion}</strong> </h5>

                                        </div>
                                    </div>
                                    <div class=" col-md-6 ">
                                        <div class="">
                                            <h5>Marks Obtain : <strong>{marks.marksObtain}</strong> </h5>
                                            <h5>Correct Answer : <strong>{marks.correctAnswer}</strong> </h5>

                                        </div>
                                    </div>

                                </div>
                             
                            </div>
                            <ul>
                                <h5>Title : <strong>{quiz.title}</strong>  </h5> 
                                {/* <br /> */}
                                <h5>Description: <strong>{quiz.description}</strong></h5>

                            </ul>
                            <br/>
                            <div className="lesson__quiz__wrap">
                                <div>
                                    {data.map((question, index) => (
                                        <>
                                      
                                        <div className="quiz__single__attempt" key={index}>
                                            <ul>
                                                {/* <li>Question : {index + 1}/{quiz.length} | </li> */}
                                                <li> Type : <strong>{question.type}</strong></li>
                                                <li>| score: <strong>{question.score}</strong></li>
                                            </ul>
                                            <hr className="hr" />
                                            <h3>{index + 1}. {question.question}?</h3>
                                           
                                        </div>
                                        <br/>
                                        <br/>
                                   


                                        </>
                                    ))}
                                   

                                </div>


                             
                                <br />


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
        </>
    )
}
