import { useState, useEffect } from 'react'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { useNavigate} from 'react-router-dom';

export default function CouseList() {
const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetCourses()

}, [])


// GET courses 
const GetCourses = () => {
    setLoader(true);
    axiosInstance.get('/api/courses')
        .then((response) => {
            const Data = response.data
            setLoader(false);
            if (Data?.status) {
                console.log("user courses all", Data)
                setData(Data.courses)

            } else {
                setLoader(false);
                toast.warn(Data?.message);

            }
        }).catch((err) => {
            setLoader(false);
            const Error = err?.response?.data?.message
            setLoader(false);
            toast.error(Error);

        })
}

const handleCourseClick = (data) => {

  navigate('/pricing', { state: data });
 
};

  return (
    <>
            {loader === true ? <Loader fullPage loading /> : null}

      {/* grid__section__start */}
      <div className="gridarea">
        <div className="container">
          <div className="row">
            <div className="section__title text-center" data-aos="fade-up">
              <div className="section__title__button">
                <div className="default__small__button">Course List</div>
              </div>
              <div className="section__title__heading heading__underline">
                <h2>
                  Perfect Online <span>Course</span>
                  <br /> For Your Carrer
                </h2>
              </div>
            </div>
          </div>
          {/* <div className="row" data-aos="fade-up">
            <div className="col-xl-12">
              <div className="gridfilter_nav grid__filter gridFilter">
                <button className="active" data-filter="*">
                  All
                </button>
                <button data-filter=".filter1" className="">
                  Computer Science
                </button>
                <button data-filter=".filter2" className="">
                  Data science
                </button>
                <button data-filter=".filter3" className="">
                  {" "}
                  Engineering{" "}
                </button>
                <button data-filter=".filter4" className="">
                  Web Development
                </button>
                <button data-filter=".filter5" className="">
                  {" "}
                  Architecture
                </button>
              </div>
            </div>
          </div> */}
          <div className="row grid">
          {data.map((v, i) => (
               
               <div
           className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter5 filter2"
           data-aos="fade-up"
         >
        

             <div className="gridarea__wraper">
               <div className="gridarea__img">
                 <div  onClick={() => handleCourseClick(v)}>
                   <img
                     loading="lazy"
                     src={v.image}
                     alt="grid"
                   />
                 </div>
                 <div className="gridarea__small__button">
                   <div className="grid__badge yellow__color">{v.title}</div>
                 </div>
                
               </div>
               <div className="gridarea__content">
                 <div className="gridarea__list">
                   <ul>
                     <li>
                       <i className="icofont-book-alt" /> {v.course_level} 
                     </li>
                     <li>
                       <i className="icofont-clock-time" /> {v.language} 
                     </li>
                   </ul>
                 </div>
                 <div className="gridarea__heading">
                   <h3>
                     <div  onClick={() => handleCourseClick(v)}>
                       {v.description}
                     </div>
                   </h3>
                 </div>
                 <div className="gridarea__price">
                 ${v.price_discount}<del> / ${v.price}</del>
                   <span>
                     {" "}
                     <span className="">{v.course_status}</span>
                   </span>
                 </div>
               
               </div>
             </div>
         </div>
           ))}
      
          
           
          
          </div>
        </div>
      </div>
      {/* grid__section__end */}
    </>
  );
}
