export const MAIN = {
  home: "/",
  specificCourse: (id) => `/specific-course/${id}`,
};

export const ADMIN = {
  dashboard: "/admin",
  profile: "/admin/profile",
  courses: "/admin/courses",
  coursesCategory: "/admin/coursecategory",
  reviews: "/admin/reviews",
  quiz: (id) => `/admin/quiz/${id}`,
  questions: (id) => `/admin/questions/${id}`,
  createcourse: "/admin/createcourse",
  settings: "/admin/settings",
  login: "/admin/adminlogin",
  lessons: "/admin/lessons",
  assignment:(id)=> `/admin/assignment/${id}`,
  submitassignment:(id)=> `/admin/submitassignments/${id}`,

  questionoptions: (id) => `/admin/questionoptions/${id}`,
  lessonvideos: (id,title) => `/admin/lesson-videos/${id}/${title}`,
  requestcertificate: '/admin/requestecertificate',
  generatecertificate: '/admin/generatecertificate',







};

export const STUDENT = {
  dashboard: "/student",
  assignment:(id)=> `/student/assignment/${id}`,
  specificassignment:(id,status)=> `/student/specificassignment/${id}/${status}`,

  settings: "/student/settings",
  profile: "/student/profile",
  quiz:(id)=> `/student/quiz/${id}`,
  enrolledcourse: "/student/enrolledcourse",
  createcourse: "/student/createcourse",
  login: "/student/login",
  passwordrecover: "/student/passwordrecover",
  codeverify: "/student/codeverify",
  confirmpassword: "/student/confirmpassword",
  quizdetail: "/student/quizdetail",
  coursedetail: (id) => `/student/coursedetail/${id}`,
  lesson: (id) => `/student/lesson/${id}`,
  // lesson: "/student/lesson",
  coursematerial: "/student/course-materials",
  coursequiz:(id)=> `/student/course-quiz/${id}`,
  courseassignment: "/student/course-assignment",
  questions:(id)=> `/student/questions/${id}`,
  attemptquiz:(id)=> `/student/attempt_quiz/${id}`










};




export const AUTH = {
  login: "/login",
  signup: "/signup",
  category: "/auth/account-type",
  clientProfile: "/auth/client-profile",
  freelancerProfile: "/auth/freelancer-profile",
};

// export const FREELANCER = {
//   dashboard: "/freelancer/dashboard",
//   notifications: "/freelancer/notifications",
//   chat: "/freelancer/chat",
//   submitProposal: "/freelancer/submitProposal",
//   profile: "/freelancer/profile",
//   hire: "/freelancer/hire",
//   connects: "/freelancer/connects",
//   transactionHistory: "/freelancer/transaction-history",
//   myJobs: "/freelancer/my-jobs",
//   myProposals: "/freelancer/my-proposals",
//   viewProposals: "/freelancer/view-proposals",
//   viewProposal: (id) => `/freelancer/view-proposal/${id}`,
//   disputeResolution: "/freelancer/dispute-resolution",
//   fileAComplain: "/freelancer/file-a-complain",
//   courses: "/freelancer/courses",
//   viewCourse: (id) => `/freelancer/course/${id}`,
//   viewLecture: (id) => `/freelancer/lectures/${id}`,
//   aboutUs: "/freelancer/about-us",
//   contactUs: "/freelancer/contact-us",
//   terms: "/freelancer/terms-conditions",
//   privacy: "/freelancer/privacy-policy",
// };

// export const CLIENT = {
//   home: "/client/home",
//   notifications: "/client/notifications",
//   chat: "/client/chat",
//   jobPost: "/client/all-jobs",
//   reviewProposals: (id) => `/client/review-proposals/${id}`,
//   viewJob: "/client/view-job",
//   jobDetails: "/client/job-details",
//   clientFreelancer: (id) => `/client/freelancer/${id}`,
//   inviteFreelancer: (id) => `/client/invite-freelancer/${id}`,
//   talents: "/client/talents",
//   profile: "/client/profile",
//   disputeResolution: (id) => `/client/disputes/${id}`,
//   fileAComplain: (id) => `/client/file-a-complain/${id}`,
//   viewProposal: (id) => `/client/view-proposal/${id}`,
//   discover: "/client/discover",
// };

