import {useEffect} from "react";
import BannerHome from "../../components/banners/home";
import Brands from "../../components/sections/brands";
import BestCategories from "../../components/sections/bestCategories";
import CouseList from "../../components/sections/couseList";
import CounterStats from "../../components/sections/counterStats";
import Register from "../../components/sections/register";
// import Pricing from "../../components/sections/pricing";
import Testimonials from "../../components/sections/testimonials";
import Blog from "../../components/sections/blog";

export default function Home() {

  useEffect(() => {
    window.scroll(0,0)
  
  
  }, [])
  
  return (
    <>
      <BannerHome />
      <Brands />
      <BestCategories />
      <CouseList />
      <CounterStats />
      <Register />
      {/* <Pricing /> */}
      <Testimonials />
      <Blog />
    </>
  );
}
