import { useEffect, useState } from 'react'
import { axiosInstance } from '../../config/https';
import { Link, useParams } from 'react-router-dom'
import Sidbar from '../../layout/admin/sidbar'
import Breadcrumb from '../../layout/admin/breadcrumb'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import qs from 'qs';
import { ADMIN } from '../../Router/paths';

export default function Questions() {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const { id } = useParams();

    // Add 
    const [question, setQuestion] = useState("");
    const [type, setType] = useState("");
    const [score, setScore] = useState("");
    // Edit
    const [equestion, seteQuestion] = useState("");
    const [etype, seteType] = useState("");
    const [escore, seteScore] = useState("");
    const [quizid, setQuizid] = useState("");
    const [questionid, setQuestionid] = useState("");

    // delete 
    const [deletequiz, setDeletequiz] = useState("");
    const [deletelquestion, setDeleteQuestion] = useState("");

    useEffect(() => {
        window.scroll(0, 0)
        GetAllQuestions(id)
    }, [id])

    const GetAllQuestions = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/quizzes/${id}/questions`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all ques", Data);

                    if (Data.quizQuestion && Data.quizQuestion.length > 0) {
                        setData(Data.quiz);
                        setQuiz(Data.quizQuestion);
                    } else {
                        setLoader(false);
                        toast.error("Quiz questions array is empty.");
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    const handleInputChange = (event, func) => {
        func(event.target.value);
    }


    // ADD API
    const AddQuestion = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('question', question)
        formData.append('type', type)
        formData.append('score', score)

        axiosInstance.post(`/api/quizzes/${id}/questions`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                if (Data?.status) {
                    CloseAddmodal()
                    toast.success("Question Added Successfully");
                    setQuestion("")
                    setScore("")
                    setType("")
                    GetAllQuestions(id)
                }

                else {

                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    // EDIT FUNC 

    const Editmodal = (item) => {
        console.log("item edit", item)
        seteQuestion(item.question)
        seteScore(item.score)
        seteType(item.type)
        setQuestionid(item.id)
        setQuizid(item.quiz_id)



    }
    const CloseEditmodal = () => {
        const deletemodal = document.getElementById("staticBackdropedit");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    // UPDATE  
    const UpdateQuestion = () => {
        setLoader(true);

        // Serialize data into x-www-form-urlencoded format
        const formData = qs.stringify({
            question: equestion,
            type: etype,
            score: escore,
        });

        axiosInstance.put(`/api/quizzes/${quizid}/questions/${questionid}`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data update", Data)
                if (Data?.status) {
                    CloseEditmodal()
                    toast.success(Data.message);
                    GetAllQuestions(id)


                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const DeleteModal = (data) => {

        console.log(data)
        setDeleteQuestion(data.id)
        setDeletequiz(data.quiz_id)

    }

    // DELETE 
    const DeleteQuestion = () => {
        setLoader(true);
        axiosInstance.delete(`/api/quizzes/${deletequiz}/questions/${deletelquestion}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetAllQuestions(id)

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtndlt", closeBtn)

        closeBtn.click();

    }




    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>{data.title}</h4>
                                    </div>
                                    <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>{data.description}</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">

                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                    Add Question
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <hr className="mt-40" />
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>

                                                            <th>Questions</th>
                                                            <th>Type</th>
                                                            <th>Score </th>
                                                            <th>Options </th>
                                                            <th>Action</th>

                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    {quiz.map((v, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <th>

                                                                    <p>{i + 1}</p>

                                                                </th>

                                                                <th>

                                                                    <p>{v.question}</p>

                                                                </th>
                                                                <td>
                                                                    <p>{v.type}</p>
                                                                </td>


                                                                <td>{v.score} </td>

                                                                <td> <Link className="dashboard__small__btn" to={ADMIN.questionoptions(v.id)}> Options </Link></td>

                                                                <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td>
                                                                            <div className="dashboard__button__group">

                                                                                <button className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdropedit" onClick={() => Editmodal(v)}> <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg>{" "} Edit </button>

                                                                                <button className="modal_button " data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => DeleteModal(v)}> <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "} Delete </button>


                                                                            </div>
                                                                        </td>
                                                                    </div>
                                                                </td>

                                                            </tr>



                                                        </tbody>


                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD MODAL  */}

            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Question</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="title" class="form-label">Question</label>
                                        <input
                                            id='title'
                                            className="form-control"
                                            value={question}
                                            onChange={(e) => handleInputChange(e, setQuestion)}
                                            type="text"
                                            placeholder="Enter Question"
                                        />
                                    </div>

                                </div>


                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="Description" class="form-label">Score</label>
                                        <input
                                            id='Description'
                                            class="form-control"
                                            value={score}
                                            onChange={(e) => handleInputChange(e, setScore)}
                                            type="text"
                                            placeholder="Enter Score"
                                        />
                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <select value={type} onChange={(e) => handleInputChange(e, setType)} class="form-select" aria-label="Default select example">
                                        <option >Select Type</option>
                                        <option value="multiple_choice">Multiple Choice </option>
                                        <option value="true_false">True False</option>
                                        <option value="open_ended">Open Ended </option>


                                    </select>

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => AddQuestion()}>
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* EDIT MODAL  */}

            <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Question</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="title" class="form-label">Question</label>
                                        <input
                                            id='title'
                                            className="form-control"
                                            value={equestion}
                                            onChange={(e) => handleInputChange(e, seteQuestion)}
                                            type="text"
                                            placeholder="Enter Question"
                                        />
                                    </div>

                                </div>


                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="Description" class="form-label">Score</label>
                                        <input
                                            id='Description'
                                            class="form-control"
                                            value={escore}
                                            onChange={(e) => handleInputChange(e, seteScore)}
                                            type="text"
                                            placeholder="Enter Score"
                                        />
                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <select value={etype} onChange={(e) => handleInputChange(e, seteType)} class="form-select" aria-label="Default select example">
                                        <option >Select Type</option>
                                        <option value="multiple_choice">Multiple Choice </option>
                                        <option value="true_false">True False</option>
                                        <option value="open_ended">Open Ended </option>


                                    </select>

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => UpdateQuestion()}>
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* DELETE  */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Question</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this Question?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteQuestion()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
