import React from 'react'
import { Link } from 'react-router-dom'
import { STUDENT } from '../../Router/paths'
export default function Lessionmaterial() {
    return (
        <div>
            <div className="tution sp_bottom_100 sp_top_100">
                <div className="container-fluid full__width__padding">
                    <div className="row">
                        <div
                            className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12"
                            data-aos="fade-up"
                        >
                            <div
                                className="accordion content__cirriculum__wrap"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Lesson #01
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to={STUDENT.coursematerial}>
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link  to={STUDENT.coursequiz}>
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to={STUDENT.courseassignment}>
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            Lesson #02
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>7.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-materials">
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-quiz">
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to="/course-assignment">
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            Lesson #03
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>7.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-materials">
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-quiz">
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to="/course-assignment">
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            Lesson #04
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>7.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-materials">
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-quiz">
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to="/course-assignment">
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFive">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                 Lesson #05
                              </button>
                          </h2>
                          <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                              <div class="accordion-body">

                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-video-alt"></i>
                                          <h5> <Link to="lesson.html"><span>Course Intro</span></Link></h5>
                                      </div>
                                      <div class="scc__meta">
                                          <strong>3.27</strong>
                                          <Link to="lesson.html"><span class="question"><i class="icofont-eye"></i> Preview</span></Link>
                                          
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-video-alt"></i>
                                          <h5> <Link to="lesson-2.html"><span>Course Outline</span></Link></h5>
                                      </div>
                                      <div class="scc__meta">
                                          <strong>5.00</strong>
                                          <Link to="lesson-2.html"><span class="question"><i class="icofont-eye"></i> Preview</span></Link>
                                          
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-video-alt"></i>
                                          <h5> <Link to="lesson-2.html"><span>Course Outline</span></Link></h5>
                                      </div>
                                      <div class="scc__meta">
                                          <strong>7.00</strong>
                                          <Link to="lesson-3.html"><span class="question"><i class="icofont-eye"></i> Preview</span></Link>
                                          
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-file-text"></i>
                                          <h5> <Link to="lesson-course-materials.html"><span>Course Materials</span></Link></h5>
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-audio"></i>
                                          <h5>  <Link to="lesson-quiz.html"><span>Summer Quiz</span></Link></h5>
                                      </div>
                                  </div>

                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-file-text"></i>
                                          <h5><Link to="lesson-assignment.html"><span>Assignment</span></Link></h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> */}
                            </div>
                        </div>
                        <div
                            className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
                            data-aos="fade-up"
                        >
                            <div className="lesson__meterials__wrap">
                                <div className="aboutarea__content__wraper__5">
                                    <div className="section__title">
                                        {/* <div class="section__title__button">
                                  <div class="default__small__button">About us</div>
                              </div> */}
                                        <div className="section__title__heading ">
                                            <h2>Projects you will made.</h2>
                                        </div>
                                    </div>
                                    <div className="about__text__5">
                                        <p>
                                            Meet my startup design agency Shape Rex Currently I am working
                                            at CodeNext as Product Designer. Lorem ipsum dolor sit amet
                                            consectetur adipisicing elit. A, quaerat excepturi accusantium
                                            eum, dolorem ipsa deleniti dicta voluptates reiciendis tempore
                                            aliquid assumenda at labore, unde quibusdam! Tenetur hic enim
                                            odit.
                                            <br />
                                            <br />
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. In
                                            illum facilis quaerat expedita. Inventore, commodi. Non
                                            molestias neque esse ipsam temporibus quia accusantium voluptas
                                            facilis enim blanditiis, doloribus, facere omnis.
                                        </p>
                                    </div>
                                    <div className="aboutarea__5__small__icon__wraper">
                                        <div className="aboutarea__5__small__icon">
                                            <img loading="lazy" src="/assets/img/about/about_15.png" alt="about" />
                                        </div>
                                        <div className="aboutarea__small__heading">
                                            <span>10+ Years ExperienceIn</span> this game, Means Product
                                            Designing
                                        </div>
                                    </div>
                                    <div className="aboutarea__para__5">
                                        <p>
                                            I love to work in User Experience &amp; User Interface
                                            designing. Lorem ipsum dolor sit amet consectetur adipisicing
                                            elit. Provident quod, maxime dolor beatae repellendus blanditiis
                                            error molestias accusamus optio suscipit nostrum tempora
                                            consectetur, vel placeat pariatur aliquid nisi harum sed
                                            cupiditate repudiandae dolorum facere repellat fugit.
                                        </p>
                                    </div>
                                    <div className="aboutarea__list__2">
                                        <ul>
                                            <li>
                                                <i className="icofont-check" /> eCommerce design, Creative.
                                            </li>
                                            <li>
                                                <i className="icofont-check" /> Business, Corporate,
                                                Education.
                                            </li>
                                            <li>
                                                <i className="icofont-check" /> Business, Corporate,
                                                Education.
                                            </li>
                                            <li>
                                                <i className="icofont-check" /> Non-Profit, It &amp; Tech,
                                                Hosting.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
