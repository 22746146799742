import {useState,useEffect} from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast';
import { Loader } from 'react-overlay-loader';
import { axiosInstance } from '../../config/https';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Studentsettings() {

    const [loader, setLoader] = useState(false);
    const [Emaill, setEmaill] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");

    // change pass 
    const [password, setPassword] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [confpassword, setConfpassword] = useState("");


    useEffect(() => {
        window.scroll(0, 0)
        GetProfile()

    }, [])

    // GET Categories 
    const GetProfile = () => {
        setLoader(true);
        axiosInstance.get('/api/view/user')
            .then((response) => {
                const Data = response.data

                setLoader(false);
                if (Data?.status) {

                    // setData(Data.user)
                    setFname(Data.user.fname)
                    setLname(Data.user.lname)
                    setEmaill(Data.user.email)

                    // toast.success(Data.message);




                } else {
                    toast.warn(Data?.message);


                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }
    // get profile api
    const UpdateProfile = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('fname', fname)
        formData.append('lname', lname)
        formData.append('email', Emaill)

        axiosInstance.post("/api/update/user", formData)
            .then((response) => {
                const Data = response?.data;
                setLoader(false);
                if (Data?.status === true) {

                    toast.success("Updated Successfully");

                }
                else {
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));


                }





            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }

    const handleInputChange = (event, func) => {
        func(event.target.value);
    }

    // CHANGE PASSWORD 

    const UpdatePassword = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('oldPassword', password)
        formData.append('password', newpassword)
        formData.append('password_confirmation', confpassword)

        axiosInstance.post("/api/admin/change/password", formData)
            .then((response) => {
                const Data = response?.data;
                console.log("pass", Data)
                setLoader(false);
                if (Data?.status === true) {

                    toast.success("Updated Password Successfully");

                }
                else {
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));



                }


            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }
    const handleInputChangePass = (event, func) => {
        func(event.target.value);
    }


    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
              <Breadcrumb/>
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <StudentSidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Settings</h4>
                                    </div>
                                    <div className="row">
                                        <div
                                            className="col-xl-12 aos-init aos-animate"
                                            data-aos="fade-up"
                                        >
                                            <ul
                                                className="nav  about__button__wrap dashboard__button__wrap"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="single__tab__link active"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#projects__one"
                                                        type="button"
                                                        aria-selected="true"
                                                        role="tab"
                                                    >
                                                        Profile
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="single__tab__link"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#projects__two"
                                                        type="button"
                                                        aria-selected="false"
                                                        role="tab"
                                                        tabIndex={-1}
                                                    >
                                                        Password
                                                    </button>
                                                </li>

                                            </ul>
                                        </div>
                                        <div
                                            className="tab-content tab__content__wrapper aos-init aos-animate"
                                            id="myTabContent"
                                            data-aos="fade-up"
                                        >
                                            <div
                                                className="tab-pane fade active show"
                                                id="projects__one"
                                                role="tabpanel"
                                                aria-labelledby="projects__one"
                                            >
                                                <div className="row">
                                                    <div className="col-xl-12">

                                                        <div className="row">
                                                            <div className="col-xl-6">
                                                                <div className="dashboard__form__wraper">
                                                                    <div className="dashboard__form__input">
                                                                        <label>First Name</label>
                                                                        <input
                                                                            onChange={(e) => handleInputChange(e, setFname)}
                                                                            value={fname}
                                                                            type="text"
                                                                            placeholder=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="dashboard__form__wraper">
                                                                    <div className="dashboard__form__input">
                                                                        <label>Last Name</label>
                                                                        <input
                                                                            onChange={(e) => handleInputChange(e, setLname)}
                                                                            value={lname}

                                                                            type="text"
                                                                            placeholder=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="dashboard__form__wraper">
                                                                    <div className="dashboard__form__input">
                                                                        <label>Email</label>
                                                                        <input
                                                                            onChange={(e) => handleInputChange(e, setLname)}
                                                                            value={Emaill}

                                                                            type="email"
                                                                            placeholder="Email"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-12">
                                                                <div className="dashboard__form__button" onClick={() => UpdateProfile()}>
                                                                    <Link className="default__button" to="#">
                                                                        Update Info
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="projects__two"
                                                role="tabpanel"
                                                aria-labelledby="projects__two"
                                            >
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="dashboard__form__wraper">
                                                            <div className="dashboard__form__input">
                                                                <label>Current Password</label>
                                                                <input
                                                                    onChange={(e) => handleInputChangePass(e, setPassword)}

                                                                    type="text"
                                                                    placeholder="Current password"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12">
                                                        <div className="dashboard__form__wraper">
                                                            <div className="dashboard__form__input">
                                                                <label>New Password</label>
                                                                <input
                                                                    onChange={(e) => handleInputChangePass(e, setNewpassword)}

                                                                    type="text"
                                                                    placeholder="New Password"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12">
                                                        <div className="dashboard__form__wraper">
                                                            <div className="dashboard__form__input">
                                                                <label>Re-Type New Password</label>
                                                                <input
                                                                    onChange={(e) => handleInputChangePass(e, setConfpassword)}

                                                                    type="text"
                                                                    placeholder="Re-Type New Password"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12">
                                                        <div className="dashboard__form__button" onClick={() => UpdatePassword()}>
                                                            <Link className="default__button" to="#">
                                                                Update Password
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
