import React from "react";
import { Link } from "react-router-dom";

export default function Error404() {
  return (
    <>
      {/* error__section__start */}
      <div className="errorarea sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-sm-12 col-12 m-auto">
              <div className="errorarea__inner" data-aos="fade-up">
                <div className="error__img">
                  <img
                    loading="lazy"
                    src="/assets/img/icon/error__1.png"
                    alt="error"
                  />
                </div>
                <div className="error__text">
                  <h3>Oops... It looks like you ‘re lost !</h3>
                  <p>
                    Oops! The page you are looking for does not exist. It might
                    have been moved or deleted
                  </p>
                </div>
                <div className="error__button">
                  <Link className="default__button" to={"/"}>
                    Back To Home
                    <i className="icofont-simple-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* error__section__end */}
    </>
  );
}
