import React from "react";
import { Link } from "react-router-dom";
import { STUDENT } from "../../Router/paths";

export default function Header() {

  const Token = localStorage.getItem('token')

  const Logout = ()=>{
    localStorage.clear()
  }
  return (
    <>
      {/* topbar__section__stert */}
      <div className="topbararea">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="topbar__left">
                <ul>
                  <li>Call Us: +1 800 123 456 789</li>
                  <li>- Mail Us: Itcroc@mail.com</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="topbar__right">
                <div className="topbar__icon">
                  <i className="icofont-location-pin" />
                </div>
                <div className="topbar__text">
                  <p>684 West College St. Sun City, USA</p>
                </div>
                <div className="topbar__list">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="icofont-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="icofont-youtube-play" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* topbar__section__end */}
      {/* headar section start */}
      <header>
        <div className="headerarea headerarea__3 header__sticky header__area">
          <div className="container desktop__menu__wrapper">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-6">
                <div className="headerarea__left">
                  <div className="headerarea__left__logo">
                    <Link to="/">
                      <img
                        loading="lazy"
                        src="/assets/img/logo/logo_1.png"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 main_menu_wrap">
                <div className="headerarea__main__menu">
                  <nav>
                    <ul>
                      {/* <li className="mega__menu position-static">
                        <Link
                          className="headerarea__has__dropdown"
                          to=""
                        >
                          Pages
                          <i className="icofont-rounded-down" />{" "}
                        </Link>
                        <div className="headerarea__submenu mega__menu__wrapper">
                          <div className="row">
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 1 </Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="about.html">
                                    About{" "}
                                    <span className="mega__menu__label">
                                      Sale Everything
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="">
                                    About (Dark)
                                    <span className="mega__menu__label new">
                                      New
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="">Blog</Link>
                                </li>
                                <li>
                                  <Link to="">Blog (Dark)</Link>
                                </li>
                                <li>
                                  <Link to="">Blog Details</Link>
                                </li>
                                <li>
                                  <Link to="">
                                    Blog Details (Dark)
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 2 </Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="">Error 404</Link>
                                </li>
                                <li>
                                  <Link to="">Error (Dark)</Link>
                                </li>
                                <li>
                                  <Link to="">Event Details</Link>
                                </li>
                                <li>
                                  <Link to="">
                                    Zoom
                                    <span className="mega__menu__label">
                                      Online Call
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="">
                                    Zoom Meeting (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="">
                                    Zoom Meeting Details
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 3</Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="l">
                                    Meeting Details (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="">Login</Link>
                                </li>
                                <li>
                                  <Link to="login-dark.html">Login (Dark)</Link>
                                </li>
                                <li>
                                  <Link to="maintenance.html">Maintenance</Link>
                                </li>
                                <li>
                                  <Link to="maintenance-dark.html">
                                    Maintenance Dark
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">Terms &amp; Condition</Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 4</Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="#">Terms &amp; Condition (Dark)</Link>
                                </li>
                                <li>
                                  <Link to="#">Privacy Policy</Link>
                                </li>
                                <li>
                                  <Link to="#">Privacy Policy (Dark)</Link>
                                </li>
                                <li>
                                  <Link to="#">Success Stories</Link>
                                </li>
                                <li>
                                  <Link to="#">Success Stories (Dark)</Link>
                                </li>
                                <li>
                                  <Link to="#">Work Policy</Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-12 mega__menu__single__wrap sp_top_30">
                              <div className="mega__menu__img">
                                <Link to="#">
                                  <img
                                    loading="lazy"
                                    src="/assets/img/mega/mega_menu_2.png"
                                    alt="Mega Menu"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="mega__menu position-static">
                        <Link
                          className="headerarea__has__dropdown"
                          to="/courses"
                        >
                          Courses
                          <i className="icofont-rounded-down" />{" "}
                        </Link>
                        <div className="headerarea__submenu mega__menu__wrapper">
                          <div className="row">
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 1 </Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="course.html">
                                    Grid{" "}
                                    <span className="mega__menu__label">
                                      All Courses
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-dark.html">
                                    Course Grid (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-grid.html">Course Grid</Link>
                                </li>
                                <li>
                                  <Link to="course-grid-dark.html">
                                    Course Grid (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-list.html">Course List</Link>
                                </li>
                                <li>
                                  <Link to="course-list-dark.html">
                                    Course List (Dark)
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 2 </Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="course-details.html">
                                    Course Details
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-details-dark.html">
                                    Course Details (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-details-2.html">
                                    Course Details 2
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-details-2-dark.html">
                                    Details 2 (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-details-3.html">
                                    Course Details 3
                                  </Link>
                                </li>
                                <li>
                                  <Link to="course-details-3.html">
                                    Details 3 (Dark)
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-3 mega__menu__single__wrap">
                              <h4 className="mega__menu__title">
                                <Link to="#">Get Started 3</Link>
                              </h4>
                              <ul className="mega__menu__item">
                                <li>
                                  <Link to="dashboard/become-an-instructor.html">
                                    Become An Instructor
                                  </Link>
                                </li>
                                <li>
                                  <Link to="dashboard/create-course.html">
                                    Create Course{" "}
                                    <span className="mega__menu__label">
                                      Career
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="instructor.html">Instructor</Link>
                                </li>
                                <li>
                                  <Link to="instructor-dark.html">
                                    Instructor (Dark)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="instructor-details.html">
                                    Instructor Details
                                  </Link>
                                </li>
                                <li>
                                  <Link to="lesson.html">
                                    Course Lesson
                                    <span className="mega__menu__label new">
                                      New
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-3 mega__menu__single__wrap">
                              <div className="mega__menu__img">
                                <Link to="#">
                                  <img
                                    loading="lazy"
                                    src="/assets/img/mega/mega_menu_1.png"
                                    alt="Mega Menu"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <Link
                          className="headerarea__has__dropdown"
                          to="dashboard/instructor-dashboard.html"
                        >
                          Dashboard
                          <i className="icofont-rounded-down" />
                        </Link>
                        <ul className="headerarea__submenu headerarea__submenu--third--wrap">
                          <li>
                            <Link to="../admin-dashboard.html">
                              Admin <i className="icofont-rounded-right" />
                            </Link>
                            <ul className="headerarea__submenu--third">
                              <li>
                                <Link to="dashboard/admin-dashboard.html">
                                  Admin Dashboard
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/admin-profile.html">
                                  Admin Profile
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/admin-message.html">
                                  Message
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/admin-course.html">
                                  Courses
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/admin-reviews.html">
                                  Review
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/admin-quiz-attempts.html">
                                  Admin Quiz
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/admin-settings.html">
                                  Settings
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="dashboard/instructor-dashboard.html">
                              Instructor <i className="icofont-rounded-right" />
                            </Link>
                            <ul className="headerarea__submenu--third">
                              <li>
                                <Link to="dashboard/instructor-dashboard.html">
                                  Inst. Dashboard
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-profile.html">
                                  Inst. Profile
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-message.html">
                                  Message
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-wishlist.html">
                                  Wishlist
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-reviews.html">
                                  Review
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-my-quiz-attempts.html">
                                  My Quiz
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-order-history.html">
                                  Order History
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-course.html">
                                  My Courses
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-announcments.html">
                                  Announcements
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-quiz-attempts.html">
                                  Quiz Attempts
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-assignments.html">
                                  Assignment
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/instructor-settings.html">
                                  Settings
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="dashboard/student-dashboard.html">
                              Student <i className="icofont-rounded-right" />
                            </Link>
                            <ul className="headerarea__submenu--third">
                              <li>
                                <Link to="dashboard/student-dashboard.html">
                                  Dashboard
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/student-profile.html">
                                  Profile
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/student-message.html">
                                  Message
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/student-enrolled-courses.html">
                                  Enrolled Courses
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/student-wishlist.html">
                                  Wishlist
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/student-reviews.html">
                                  Review
                                </Link>
                              </li>
                              <li>
                                <Link to="dashboard/student-my-quiz-attempts.html">
                                  My Quiz
                                </Link>
                              </li>
                              <li>
                                <Link to="">
                                  Assignment
                                </Link>
                              </li>
                              <li>
                                <Link to="">
                                  Settings
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}

                      <li>
                        <Link className="headerarea__has__dropdown" to="/" > Home</Link>
                      </li>
                      <li>
                        <Link className="headerarea__has__dropdown" to="/courses" > Courses</Link>
                      </li>
                      <li>
                        <Link className="headerarea__has__dropdown" to="/lesson" > Lessons</Link>
                      </li>
                      <li>
                        <Link className="headerarea__has__dropdown" to="/blogs" > Blogs</Link>
                      </li>   <li>
                        <Link className="headerarea__has__dropdown" to="/about" > About</Link>
                      </li>   <li>
                        <Link className="headerarea__has__dropdown" to="/contactus" > Contact</Link>
                      </li>   <li>
                        {/* <Link className="headerarea__has__dropdown"  to="" > Dashboard</Link> */}
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="headerarea__right">
                  {Token !== null ?
                  <div className="headerarea__login" onClick={Logout}>
                    <Link to={STUDENT.login}>
                      <i className="icofont-exit" />
                    </Link>
                  </div>:
                  <div className="headerarea__button">
                    <Link to={STUDENT.login}>Get Start</Link>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mob_menu_wrapper">
            <div className="row align-items-center">
              <div className="col-6">
                <div className="mobile-logo">
                  <Link className="logo__dark" to="/">
                    <img
                      loading="lazy"
                      src="/assets/img/logo/logo_1.png"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-6">
                <div className="header-right-wrap">
                  <div className="headerarea__right">
                    <div className="header__cart">
                      <Link to="#">
                        {" "}
                        <i className="icofont-cart-alt" />
                      </Link>
                      <div className="header__right__dropdown__wrapper">
                        <div className="header__right__dropdown__inner">
                          <div className="single__header__right__dropdown">
                            <div className="header__right__dropdown__img">
                              <Link to="#">
                                <img
                                  loading="lazy"
                                  src="/assets/img/grid/cart1.jpg"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="header__right__dropdown__content">
                              <Link to="shop-product.html">Web Directory</Link>
                              <p>
                                1 x <span className="price">$ 80.00</span>
                              </p>
                            </div>
                            <div className="header__right__dropdown__close">
                              <Link to="#">
                                <i className="icofont-close-line" />
                              </Link>
                            </div>
                          </div>
                          <div className="single__header__right__dropdown">
                            <div className="header__right__dropdown__img">
                              <Link to="#">
                                <img
                                  loading="lazy"
                                  src="/assets/img/grid/cart2.jpg"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="header__right__dropdown__content">
                              <Link to="shop-product.html">Design Minois</Link>
                              <p>
                                1 x <span className="price">$ 60.00</span>
                              </p>
                            </div>
                            <div className="header__right__dropdown__close">
                              <Link to="#">
                                <i className="icofont-close-line" />
                              </Link>
                            </div>
                          </div>
                          <div className="single__header__right__dropdown">
                            <div className="header__right__dropdown__img">
                              <Link to="#">
                                <img
                                  loading="lazy"
                                  src="/assets/img/grid/cart3.jpg"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="header__right__dropdown__content">
                              <Link to="shop-product.html">Crash Course</Link>
                              <p>
                                1 x <span className="price">$ 70.00</span>
                              </p>
                            </div>
                            <div className="header__right__dropdown__close">
                              <Link to="#">
                                <i className="icofont-close-line" />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <p className="dropdown__price">
                          Total: <span>$1,100.00</span>
                        </p>
                        <div className="header__right__dropdown__button">
                          <Link to="#" className="white__color">
                            VIEW CART
                          </Link>
                          <Link to="#" className="blue__color">
                            CHECKOUT
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-off-canvas">
                    <Link className="mobile-aside-button" to="#">
                      <i className="icofont-navigation-menu" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* header section end */}
      {/* Mobile Menu Start Here */}
      <div className="mobile-off-canvas-active">
        <Link className="mobile-aside-close">
          <i className="icofont  icofont-close-line" />
        </Link>
        <div className="header-mobile-aside-wrap">
          <div className="mobile-search">
            <form className="search-form" action="#">
              <input type="text" placeholder="Search entire store…" />
              <button className="button-search">
                <i className="icofont icofont-search-2" />
              </button>
            </form>
          </div>
          <div className="mobile-menu-wrap headerarea">
            <div className="mobile-navigation">
              <nav>
                <ul className="mobile-menu">
                  <li className="menu-item-has-children">
                    <Link to="index-2.html">Home</Link>
                    <ul className="dropdown">
                      <li className="menu-item-has-children">
                        <Link to="index-2.html">Homes Light</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="index-2.html">Home (Default)</Link>
                          </li>
                          <li>
                            <Link to="home-2.html">Elegant</Link>
                          </li>
                          <li>
                            <Link to="home-3.html">Classic</Link>
                          </li>
                          <li>
                            <Link to="home-4.html">Classic LMS</Link>
                          </li>
                          <li>
                            <Link to="home-5.html">Online Course </Link>
                          </li>
                          <li>
                            <Link to="home-6.html">Marketplace </Link>
                          </li>
                          <li>
                            <Link to="home-7.html">University</Link>
                          </li>
                          <li>
                            <Link to="home-8.html">eCommerce</Link>
                          </li>
                          <li>
                            <Link to="home-9.html">Kindergarten</Link>
                          </li>
                          <li>
                            <Link to="home-10.html">Machine Learning</Link>
                          </li>
                          <li>
                            <Link to="home-11.html">Single Course</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="index-2.html">Homes Dark</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="index-dark.html">Home Default (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-2-dark.html">Elegant (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-3-dark.html">Classic (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-4-dark.html">Classic LMS (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-5-dark.html">Online Course (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-6-dark.html">Marketplace (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-7-dark.html">University (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-8-dark.html">eCommerce (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-9-dark.html">Kindergarten (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-10-dark.html">Kindergarten (Dark)</Link>
                          </li>
                          <li>
                            <Link to="home-11-dark.html">Single Course (Dark)</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children ">
                    <Link to="#">Pages</Link>
                    <ul className="dropdown">
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 1</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="about.html">About</Link>
                          </li>
                          <li>
                            <Link to="">
                              About (Dark)
                              <span className="mega__menu__label new">New</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">Blog</Link>
                          </li>
                          <li>
                            <Link to="">Blog (Dark)</Link>
                          </li>
                          <li>
                            <Link to="">Blog Details</Link>
                          </li>
                          <li>
                            <Link to="">
                              Blog Details (Dark)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 2</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">Error 404</Link>
                          </li>
                          <li>
                            <Link to="">Error (Dark)</Link>
                          </li>
                          <li>
                            <Link to="">Event Details</Link>
                          </li>
                          <li>
                            <Link to="">
                              Zoom
                              <span className="mega__menu__label">
                                Online Call
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Zoom Meeting (Dark)
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Zoom Meeting Details
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 3</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="l">
                              Meeting Details (Dark)
                            </Link>
                          </li>
                          <li>
                            <Link to="">Login</Link>
                          </li>
                          <li>
                            <Link to="">Login (Dark)</Link>
                          </li>
                          <li>
                            <Link to="">Maintenance</Link>
                          </li>
                          <li>
                            <Link to="">Maintenance Dark</Link>
                          </li>
                          <li>
                            <Link to="#">Terms &amp; Condition</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 4</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="#">Terms &amp; Condition (Dark)</Link>
                          </li>
                          <li>
                            <Link to="#">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="#">Privacy Policy (Dark)</Link>
                          </li>
                          <li>
                            <Link to="#">Success Stories</Link>
                          </li>
                          <li>
                            <Link to="#">Success Stories (Dark)</Link>
                          </li>
                          <li>
                            <Link to="#">Work Policy</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <div className="mega__menu__img">
                          <Link to="#">
                            <img
                              loading="lazy"
                              src="/assets/img/mega/mega_menu_2.png"
                              alt="Mega Menu"
                            />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children ">
                    <Link to="">Courses</Link>
                    <ul className="dropdown">
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 1</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">
                              Grid{" "}
                              <span className="mega__menu__label">
                                All Courses
                              </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">Course Grid (Dark)</Link>
                          </li>
                          <li>
                            <Link to="">Course Grid</Link>
                          </li>
                          <li>
                            <Link to="">
                              Course Grid (Dark)
                            </Link>
                          </li>
                          <li>
                            <Link to="">Course List</Link>
                          </li>
                          <li>
                            <Link to="">
                              Course List (Dark)
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 2</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">Course Details</Link>
                          </li>
                          <li>
                            <Link to="">
                              Course Details (Dark)
                            </Link>
                          </li>
                          <li>
                            <Link to="">Course Details 2</Link>
                          </li>
                          <li>
                            <Link to="">
                              Details 2 (Dark)
                            </Link>
                          </li>
                          <li>
                            <Link to="">Course Details 3</Link>
                          </li>
                          <li>
                            <Link to="">Details 3 (Dark)</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Get Started 3</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">
                              Become An Instructor
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Create Course{" "}
                              <span className="mega__menu__label">Career</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="">Instructor</Link>
                          </li>
                          <li>
                            <Link to="">Instructor (Dark)</Link>
                          </li>
                          <li>
                            <Link to="">
                              Instructor Details
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Course Lesson
                              <span className="mega__menu__label new">New</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <div className="mega__menu__img">
                          <Link to="#">
                            <img
                              loading="lazy"
                              src="/assets/img/mega/mega_menu_1.png"
                              alt="Mega Menu"
                            />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children ">
                    <Link to="">Dashboard</Link>
                    <ul className="dropdown">
                      <li className="menu-item-has-children">
                        <Link to="#">Admin</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">
                              Admin Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Admin Profile
                            </Link>
                          </li>
                          <li>
                            <Link to="">Message</Link>
                          </li>
                          <li>
                            <Link to="">Courses</Link>
                          </li>
                          <li>
                            <Link to="">Review</Link>
                          </li>
                          <li>
                            <Link to="">
                              Admin Quiz
                            </Link>
                          </li>
                          <li>
                            <Link to="">Settings</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Instructor</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">
                              Inst. Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Inst. Profile
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Message
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Wishlist
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Review
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              My Quiz
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Order History
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              My Courses
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Announcements
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Quiz Attempts
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Assignment
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Settings
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Student</Link>
                        <ul className="dropdown">
                          <li>
                            <Link to="">
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link to="">Profile</Link>
                          </li>
                          <li>
                            <Link to="">Message</Link>
                          </li>
                          <li>
                            <Link to="">
                              Enrolled Courses
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Wishlist
                            </Link>
                          </li>
                          <li>
                            <Link to="">Review</Link>
                          </li>
                          <li>
                            <Link to="">
                              My Quiz
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Assignment
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              Settings
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="">eCommerce</Link>
                    <ul className="dropdown">
                      <li>
                        <Link to="">
                          Shop
                          <span className="mega__menu__label">
                            Online Store
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          Product Details
                        </Link>
                      </li>
                      <li>
                        <Link to="">Cart</Link>
                      </li>
                      <li>
                        <Link to="">Checkout</Link>
                      </li>
                      <li>
                        <Link to="">Wishlist</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="mobile-curr-lang-wrap">
            <div className="single-mobile-curr-lang">
              <Link className="mobile-language-active" to="#">
                Language <i className="icofont-thin-down" />
              </Link>
              <div className="lang-curr-dropdown lang-dropdown-active">
                <ul>
                  <li>
                    <Link to="#">English (US)</Link>
                  </li>
                  <li>
                    <Link to="#">English (UK)</Link>
                  </li>
                  <li>
                    <Link to="#">Spanish</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="single-mobile-curr-lang">
                            <Link class="mobile-currency-active" to="#">Currency <i class="icofont-thin-down"></i></Link>
                            <div class="lang-curr-dropdown curr-dropdown-active">
                                <ul>
                                    <li><Link to="#">USD</Link></li>
                                    <li><Link to="#">EUR</Link></li>
                                    <li><Link to="#">Real</Link></li>
                                    <li><Link to="#">BDT</Link></li>
                                </ul>
                            </div>
                        </div> */}
            <div className="single-mobile-curr-lang">
              <Link className="mobile-account-active" to="#">
                My Account <i className="icofont-thin-down" />
              </Link>
              <div className="lang-curr-dropdown account-dropdown-active">
                <ul>
                  <li>
                    <Link to="">Login</Link>
                  </li>
                  <li>
                    <Link to="">/ Create Account</Link>
                  </li>
                  <li>
                    <Link to="">My Account</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mobile-social-wrap">
            <Link className="facebook" to="#">
              <i className="icofont icofont-facebook" />
            </Link>
            <Link className="twitter" to="#">
              <i className="icofont icofont-twitter" />
            </Link>
            <Link className="pinterest" to="#">
              <i className="icofont icofont-pinterest" />
            </Link>
            <Link className="instagram" to="#">
              <i className="icofont icofont-instagram" />
            </Link>
            <Link className="google" to="#">
              <i className="icofont icofont-youtube-play" />
            </Link>
          </div>
        </div>
      </div>
      {/* Mobile Menu end Here */}
    </>
  );
}
