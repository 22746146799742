import React from "react";
import Header from "./header";
import { Outlet } from "react-router-dom";
import Footer from "./footer";

export default function MainLayout() {
  return (
    <main className="main_wrapper overflow-hidden">
      <Header />
      {/* theme fixed shadow */}
      <div>
        <div className="theme__shadow__circle" />
        <div className="theme__shadow__circle shadow__right" />
      </div>
      {/* theme fixed shadow */}
      <Outlet />
      <Footer />
    </main>
  );
}
