import React from 'react'
import { Link } from 'react-router-dom'
export default function index() {
  return (
    <>
    <div className="blogarea__2 sp_top_100 sp_bottom_100">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div className="blog__content__wraper__2" data-aos="fade-up">
          <div className="blogarae__img__2">
            <img loading="lazy" src="/assets/img/blog/blog_6.png" alt="blog" />
            <div className="blogarea__date__2">
              <span>24</span>
              <span className="blogarea__month">Feb</span>
            </div>
          </div>
          <div className="blogarea__text__wraper__2">
            <div className="blogarea__heading__2">
              <h3>
                <Link to="/blogdetails">
                  Delivering What Consumers Really Value?
                </Link>
              </h3>
            </div>
            <div className="blogarea__list__2">
              <ul>
                <li>
                  <Link to="">
                    <i className="icofont-business-man-alt-2" /> Mirnsdo.H
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-speech-comments" /> 0 Comments
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-eraser-alt" /> Association
                  </Link>
                </li>
              </ul>
            </div>
            <div className="blogarea__paragraph">
              <p>
                These cases are perfectly simple and easy to distinguish. In a
                free hour, when our power of On the other hand, organizations
                have the need for integrating in IT departments
              </p>
            </div>
            <div className="blogarea__button__2">
              <Link to="">
                READ MORE
                <i className="icofont-double-right" />
              </Link>
             
            </div>
          </div>
        </div>
        <div className="blog__content__wraper__2" data-aos="fade-up">
          <div className="blogarae__img__2">
            <img loading="lazy" src="/assets/img/blog/blog_7.png" alt="blog" />
            <div className="blogarea__date__2">
              <span>24</span>
              <span className="blogarea__month">Feb</span>
            </div>
            <div className="registerarea__content course__details__video">
              <div className="registerarea__video">
                <div className="video__pop__btn">
                  <Link
                    className="video-btn"
                    to="https://www.youtube.com/watch?v=vHdclsdkp28"
                  >
                    {" "}
                    <img loading="lazy" src="/assets/img/icon/video.png" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="blogarea__text__wraper__2">
            <div className="blogarea__heading__2">
              <h3>
                <Link to="/blogdetails">
                  Here at First Baptist Cape Coral we believe!
                </Link>
              </h3>
            </div>
            <div className="blogarea__list__2">
              <ul>
                <li>
                  <Link to="">
                    <i className="icofont-business-man-alt-2" /> Mirnsdo.H
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-speech-comments" /> 0 Comments
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-eraser-alt" /> Association
                  </Link>
                </li>
              </ul>
            </div>
            <div className="blogarea__paragraph">
              <p>
                These cases are perfectly simple and easy to distinguish. In a
                free hour, when our power of On the other hand, organizations
                have the need for integrating in IT departments
              </p>
            </div>
            <div className="blogarea__button__2">
              <Link to="">
                READ MORE
                <i className="icofont-double-right" />
              </Link>
             
            </div>
          </div>
        </div>
        <div className="blog__content__wraper__2" data-aos="fade-up">
          <div className="blogarae__img__2">
            <img loading="lazy" src="/assets/img/blog/blog_8.png" alt="blog" />
            <div className="blogarea__date__2">
              <span>24</span>
              <span className="blogarea__month">Feb</span>
            </div>
          </div>
          <div className="blogarea__text__wraper__2">
            <div className="blogarea__heading__2">
              <h3>
                <Link to="/blogdetails">
                  We are praying for our community and for.
                </Link>
              </h3>
            </div>
            <div className="blogarea__list__2">
              <ul>
                <li>
                  <Link to="">
                    <i className="icofont-business-man-alt-2" /> Mirnsdo.H
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-speech-comments" /> 0 Comments
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-eraser-alt" /> Association
                  </Link>
                </li>
              </ul>
            </div>
            <div className="blogarea__paragraph">
              <p>
                These cases are perfectly simple and easy to distinguish. In a
                free hour, when our power of On the other hand, organizations
                have the need for integrating in IT departments
              </p>
            </div>
            <div className="blogarea__button__2">
              <Link to="">
                READ MORE
                <i className="icofont-double-right" />
              </Link>
            
            </div>
          </div>
        </div>
        <div className="blog__content__wraper__2" data-aos="fade-up">
          <div className="blogarae__img__2">
            <img loading="lazy" src="/assets/img/blog/blog_9.png" alt="blog" />
            <div className="blogarea__date__2">
              <span>24</span>
              <span className="blogarea__month">Feb</span>
            </div>
          </div>
          <div className="blogarea__text__wraper__2">
            <div className="blogarea__heading__2">
              <h3>
                <Link to="/blogdetails">
                  Delivering What Consumers Really Value?
                </Link>
              </h3>
            </div>
            <div className="blogarea__list__2">
              <ul>
                <li>
                  <Link to="">
                    <i className="icofont-business-man-alt-2" /> Mirnsdo.H
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-speech-comments" /> 0 Comments
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="icofont-eraser-alt" /> Association
                  </Link>
                </li>
              </ul>
            </div>
            <div className="blogarea__paragraph">
              <p>
                These cases are perfectly simple and easy to distinguish. In a
                free hour, when our power of On the other hand, organizations
                have the need for integrating in IT departments
              </p>
            </div>
            <div className="blogarea__button__2">
              <Link to="">
                READ MORE
                <i className="icofont-double-right" />
              </Link>
             
            </div>
          </div>
        </div>
     
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
          <div className="blogsidebar__content__inner__2">
            <div className="blogsidebar__img__2">
              <img loading="lazy" src="/assets/img/blog/blog_10.png" alt="blog" />
            </div>
            <div className="blogsidebar__name__2">
              <h5>
                <Link to="#"> Rosalina D. Willaim</Link>
              </h5>
              <p>Blogger/Photographer</p>
            </div>
            <div className="blog__sidebar__text__2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing
                elit.Veritatis distinctio suscipit reprehenderit atque
              </p>
            </div>
            <div className="blogsidbar__icon__2">
              <ul>
                <li>
                  <Link to="#">
                    <i className="icofont-facebook" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icofont-youtube-play" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icofont-instagram" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icofont-twitter" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
      
        <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
          <h4 className="sidebar__title">Recent Post</h4>
          <ul className="recent__list">
            <li>
              <div className="recent__img">
                <Link to="#">
                  <img loading="lazy" src="/assets/img/blog/blog_11.png" alt="sidbar" />
                  <div className="recent__number">
                    <span>01</span>
                  </div>
                </Link>
              </div>
              <div className="recent__text">
                <div className="recent__date">
                  <Link to="#">23 December 2023</Link>
                </div>
                <h6>
                  <Link to="">Show at the University </Link>
                </h6>
              </div>
            </li>
            <li>
              <div className="recent__img">
                <Link to="#">
                  <img loading="lazy" src="/assets/img/blog/blog_12.png" alt="sidbar" />
                  <div className="recent__number">
                    <span>02</span>
                  </div>
                </Link>
              </div>
              <div className="recent__text">
                <div className="recent__date">
                  <Link to="#">23 December 2023</Link>
                </div>
                <h6>
                  <Link to="">Show at the University </Link>
                </h6>
              </div>
            </li>
            <li>
              <div className="recent__img">
                <Link to="#">
                  <img loading="lazy" src="/assets/img/blog/blog_13.png" alt="sidbar" />
                  <div className="recent__number">
                    <span>03</span>
                  </div>
                </Link>
              </div>
              <div className="recent__text">
                <div className="recent__date">
                  <Link to="#">23 December 2023</Link>
                </div>
                <h6>
                  <Link to="">Show at the University </Link>
                </h6>
              </div>
            </li>
            <li>
              <div className="recent__img">
                <Link to="#">
                  <img loading="lazy" src="/assets/img/blog/blog_14.png" alt="sidbar" />
                  <div className="recent__number">
                    <span>04</span>
                  </div>
                </Link>
              </div>
              <div className="recent__text">
                <div className="recent__date">
                  <Link to="#">23 December 2023</Link>
                </div>
                <h6>
                  <Link to="">Show at the University </Link>
                </h6>
              </div>
            </li>
          </ul>
        </div>
        <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
          <h4 className="sidebar__title">Photo Gallery</h4>
          <div className="photo__gallery__img">
            <div className="single__gallery__img">
              <img loading="lazy" src="/assets/img/blog/blog_15.png" alt="" />
              <div className="gallery__icon">
                <Link className="popup__img" to="/assets/img/blog/blog_15.png">
                  {" "}
                  <i className="icofont-eye-alt" />
                </Link>
              </div>
            </div>
            <div className="single__gallery__img">
              <img loading="lazy" src="/assets/img/blog/blog_16.png" alt="" />
              <div className="gallery__icon">
                <Link className="popup__img" to="/assets/img/blog/blog_16.png">
                  {" "}
                  <i className="icofont-eye-alt" />
                </Link>
              </div>
            </div>
            <div className="single__gallery__img">
              <img loading="lazy" src="/assets/img/blog/blog_17.png" alt="" />
              <div className="gallery__icon">
                <Link className="popup__img" to="img/blog/blog_17.png">
                  {" "}
                  <i className="icofont-eye-alt" />
                </Link>
              </div>
            </div>
            <div className="single__gallery__img">
              <img loading="lazy" src="/assets/img/blog/blog_18.png" alt="" />
              <div className="gallery__icon">
                <Link className="popup__img" to="img/blog/blog_18.png">
                  {" "}
                  <i className="icofont-eye-alt" />
                </Link>
              </div>
            </div>
            <div className="single__gallery__img">
              <img loading="lazy" src="/assets/img/blog/blog_19.png" alt="" />
              <div className="gallery__icon">
                <Link className="popup__img" to="/assets/img/blog/blog_19.png">
                  {" "}
                  <i className="icofont-eye-alt" />
                </Link>
              </div>
            </div>
            <div className="single__gallery__img">
              <img loading="lazy" src="/assets/img/blog/blog_20.png" alt="" />
              <div className="gallery__icon">
                <Link className="popup__img" to="/assets/img/blog/blog_20.png">
                  {" "}
                  <i className="icofont-eye-alt" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
          <h4 className="sidebar__title">Get in Touch</h4>
          <div className="get__touch__input">
            <input type="text" placeholder="Enter name*" />
            <input type="text" placeholder="Enter your mail*" />
            <input type="text" placeholder="Massage*" />
            <Link className="default__button" to="">
              SEND MASSAGE
            </Link>
          </div>
        </div>
        <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
          <h4 className="sidebar__title">Popular tag</h4>
          <div className="populer__tag__list">
            <ul>
              <li>
                <Link to="">Business</Link>
              </li>
              <li>
                <Link to="">Design</Link>
              </li>
              <li>
                <Link to="">apps</Link>
              </li>
              <li>
                <Link to="">landing page</Link>
              </li>
              <li>
                <Link to="">data</Link>
              </li>
              <li>
                <Link to="">book</Link>
              </li>
              <li>
                <Link to="">Design</Link>
              </li>
              <li>
                <Link to="">book</Link>
              </li>
              <li>
                <Link to="">landing page</Link>
              </li>
              <li>
                <Link to="">data</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
          <h4 className="sidebar__title">Follow Us</h4>
          <div className="follow__icon">
            <ul>
              <li>
                <Link to="#">
                  <i className="icofont-facebook" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="icofont-youtube-play" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="icofont-instagram" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="icofont-twitter" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="icofont-instagram" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        
    </>
  )
}
