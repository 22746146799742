import React from 'react'
import { Link } from 'react-router-dom'
export default function Blogdetails() {
    return (
        <>
            <div className="blogarea__2 sp_top_100 sp_bottom_100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="blog__details__content__wraper">
                                <div className="blog__details__img" data-aos="fade-up">
                                    <img loading="lazy" src="/assets/img/blog/blog_21.png" alt="blog" />
                                </div>
                                <div className="blog__details__content">
                                    <p className="content__1" data-aos="fade-up">
                                        We have covered many special events such as fireworks, fairs,
                                        parades, races, walks, awards ceremonies, fashion shows, sporting
                                        events, and even a memorial service.
                                    </p>
                                    <p className="content__2" data-aos="fade-up">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                        vulputate vestibulum rhoncus, dolor eget viverra pretium, dolor
                                        tellus aliquet nunc, vitae ultricies erat elit eu lacus.
                                        Vestibulum non justo consectetur, cursus ante, tincidunt sapien.
                                        Nulla quis diam sit amet turpis interd enim. Vivamus faucibus ex
                                        sed nibh egestas elementum. Mauris et bibendum dui. Aenean
                                        consequat pulvinar luctus. Suspendisse consectetur tristique
                                        tortor
                                    </p>
                                    <div className="blog__details__heading" data-aos="fade-up">
                                        <h5>Experience is over the world visit</h5>
                                    </div>
                                    <p className="content__3" data-aos="fade-up">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                        vulputate vestibulum Phasellus rhoncus, dolor eget viverra
                                        pretium, dolor tellus aliquet nunc, vitae ultricies erat elit eu
                                        lacus. Vestibulum non justo consectetur, cursus ante, tincidunt
                                        sapien. Nulla quis diam sit amet turpis interdum accumsan quis nec
                                        enim. Vivamus faucibus ex sed nibh egestas elementum. Mauris et
                                        bibendum dui. Aenean consequat pulvinar luctus
                                    </p>
                                    <p className="content__4" data-aos="fade-up">
                                        We have covered many special events such as fireworks, fairs,
                                        parades, races, walks, awards ceremonies, fashion shows, sporting
                                        events, and even a memorial service.
                                    </p>
                                    <p className="content__5" data-aos="fade-up">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                        vulputate vestibulum Phasellus rhoncus, dolor eget viverra
                                        pretium, dolor tellus aliquet nunc, vitae ultricies erat elit eu
                                        lacus. Vestibulum non justo consectetur, cursus ante, tincidunt
                                        sapien. Nulla quis diam sit amet turpis interdum accumsan quis nec
                                        enim. Vivamus faucibus ex sed nibh egestas elementum. Mauris et
                                        bibendum dui. Aenean consequat pulvinar luctus. Suspendisse
                                        consectetur tristique tortor
                                    </p>
                                    <div className="blockquate__wraper" data-aos="fade-up">
                                        <div className="blockquate__content">
                                            <h3>
                                                Smashing Podcast Episode With Paul Boag What Is Conversion
                                                Optimization
                                            </h3>
                                            <Link to="#">John Mirnsdo</Link>
                                        </div>
                                        <div className="blockquate__img">
                                            <img loading="lazy" src="/assets/img/blog/blog_22.png" alt="img" />
                                        </div>
                                    </div>
                                    <div className="blog__details__heading" data-aos="fade-up">
                                        <h5>Experience is over the world visit</h5>
                                    </div>
                                    <p className="content__6" data-aos="fade-up">
                                        We have covered many special events such as fireworks, fairs,
                                        parades, races, walks, awards ceremonies, fashion shows, sporting
                                        events, and even a memorial service
                                    </p>
                                    <p className="content__7" data-aos="fade-up">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                        vulputate vestibulum Phasellus rhoncus, dolor eget viverra
                                        pretium, dolor tellus aliquet nunc, vitae ultricies erat elit eu
                                        lacus. Vestibulum non justo consectetur, cursus ante, tincidunt
                                        sapien. Nulla quis diam sit amet turpis interdum accumsan quis nec
                                        enim. Vivamus faucibus ex sed nibh egestas elementum. Mauris et
                                        bibendum dui. Aenean consequat pulvinar luctus. Suspendisse
                                        consectetur tristique tortor
                                    </p>
                                    <div className="row blog__details__margin">
                                        <div className="col-xl-4 col-lg-4 col-md-4" data-aos="fade-up">
                                            <div className="blog__details__img__2">
                                                <img loading="lazy" src="/assets/img/blog/blog_23.png" alt="blog" />
                                                <div className="registerarea__content course__details__video">
                                                    <div className="registerarea__video">
                                                        <div className="video__pop__btn">
                                                            <Link
                                                                className="video-btn"
                                                                to="https://www.youtube.com/watch?v=vHdclsdkp28"
                                                            >
                                                                {" "}
                                                                <img loading="lazy" src="/assets/img/icon/video.png" alt="" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8">
                                            <div className="blog__details__heading__2" data-aos="fade-up">
                                                <h5>Why search Is Important ?</h5>
                                            </div>
                                            <div
                                                className="aboutarea__list__2 blog__details__list__2"
                                                data-aos="fade-up"
                                            >
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check" />
                                                        <p>
                                                            Lorem Ipsum is simply dummying text of the printing
                                                            andtypesetting industry most of the standard.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check" />
                                                        <p>
                                                            Lorem Ipsum is simply dummying text of the printing
                                                            andtypesetting industry most of the standard.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check" />
                                                        <p>
                                                            Lorem Ipsum is simply dummying text of the printing
                                                            andtypesetting industry most of the standard.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check" />
                                                        <p>
                                                            Lorem Ipsum is simply dummying text of the printing
                                                            andtypesetting industry most of the standard.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog__details__heading__2" data-aos="fade-up">
                                        <h5>Let our investment management team</h5>
                                    </div>
                                    <p className="content__8" data-aos="fade-up">
                                        We have covered many special events such as fireworks, fairs,
                                        parades, races, walks, awards ceremonies, fashion shows, sporting
                                        events, and even a memorial service.
                                    </p>
                                    <p className="content__9" data-aos="fade-up">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                                        vulputate vestibulum Phasellus rhoncus, dolor eget viverra
                                        pretium, dolor tellus aliquet nunc, vitae ultricies erat elit eu
                                        lacus. Vestibulum non justo consectetur, cursus ante, tincidunt
                                        sapien. Nulla quis diam sit amet turpis interdum accumsan quis nec
                                        enim. Vivamus faucibus ex sed nibh egestas elementum. Mauris et
                                        bibendum dui. Aenean consequat pulvinar luctus. Suspendisse
                                        consectetur tristique tortor
                                    </p>
                                </div>
                                <div className="blog__details__tag" data-aos="fade-up">
                                    <ul>
                                        <li className="heading__tag">Tag</li>
                                        <li>
                                            <Link to="#">Business</Link>
                                        </li>
                                        <li>
                                            <Link to="#">Design</Link>
                                        </li>
                                        <li>
                                            <Link to="#">apps</Link>
                                        </li>
                                        <li>
                                            <Link to="#">data</Link>
                                        </li>
                                    </ul>
                                    <ul className="share__list" data-aos="fade-up">
                                        <li className="heading__tag">Share</li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-twitter" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-facebook" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-instagram" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                             
                            
                            
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <div className="blogsidebar__content__inner__2">
                                    <div className="blogsidebar__img__2">
                                        <img loading="lazy" src="/assets/img/blog/blog_10.png" alt="blog" />
                                    </div>
                                    <div className="blogsidebar__name__2">
                                        <h5>
                                            <Link to="#"> Rosalina D. Willaim</Link>
                                        </h5>
                                        <p>Blogger/Photographer</p>
                                    </div>
                                    <div className="blog__sidebar__text__2">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing
                                            elit.Veritatis distinctio suscipit reprehenderit atque
                                        </p>
                                    </div>
                                    <div className="blogsidbar__icon__2">
                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-facebook" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-youtube-play" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-instagram" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="icofont-twitter" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <h4 className="sidebar__title">categories</h4>
                                <ul className="categorie__list">
                                    <li>
                                        <Link to="#">
                                            Mobile Set <span>03</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            Mobile Set <span>03</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            Raxila Dish nonyte<span>09</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            Fresh Vegetable <span>01</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            Fruites <span>00</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            Gesuriesey <span>26</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <h4 className="sidebar__title">Recent Post</h4>
                                <ul className="recent__list">
                                    <li>
                                        <div className="recent__img">
                                            <Link to="#">
                                                <img loading="lazy" src="/assets/img/blog/blog_11.png" alt="sidbar" />
                                                <div className="recent__number">
                                                    <span>01</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="recent__text">
                                            <div className="recent__date">
                                                <Link to="#">23 December 2023</Link>
                                            </div>
                                            <h6>
                                                <Link to="">Show at the University </Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="recent__img">
                                            <Link to="#">
                                                <img loading="lazy" src="/assets/img/blog/blog_12.png" alt="sidbar" />
                                                <div className="recent__number">
                                                    <span>02</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="recent__text">
                                            <div className="recent__date">
                                                <Link to="#">23 December 2023</Link>
                                            </div>
                                            <h6>
                                                <Link to="">Show at the University </Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="recent__img">
                                            <Link to="#">
                                                <img loading="lazy" src="/assets/img/blog/blog_13.png" alt="sidbar" />
                                                <div className="recent__number">
                                                    <span>03</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="recent__text">
                                            <div className="recent__date">
                                                <Link to="#">23 December 2023</Link>
                                            </div>
                                            <h6>
                                                <Link to="">Show at the University </Link>
                                            </h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="recent__img">
                                            <Link to="#">
                                                <img loading="lazy" src="/assets/img/blog/blog_14.png" alt="sidbar" />
                                                <div className="recent__number">
                                                    <span>04</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="recent__text">
                                            <div className="recent__date">
                                                <Link to="#">23 December 2023</Link>
                                            </div>
                                            <h6>
                                                <Link to="">Show at the University </Link>
                                            </h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <h4 className="sidebar__title">Photo Gallery</h4>
                                <div className="photo__gallery__img">
                                    <div className="single__gallery__img">
                                        <img loading="lazy" src="/assets/img/blog/blog_15.png" alt="" />
                                        <div className="gallery__icon">
                                            <Link className="popup__img" to="/assets/img/blog/blog_15.png">
                                                {" "}
                                                <i className="icofont-eye-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__gallery__img">
                                        <img loading="lazy" src="/assets/img/blog/blog_16.png" alt="" />
                                        <div className="gallery__icon">
                                            <Link className="popup__img" to="/assets/img/blog/blog_16.png">
                                                {" "}
                                                <i className="icofont-eye-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__gallery__img">
                                        <img loading="lazy" src="/assets/img/blog/blog_17.png" alt="" />
                                        <div className="gallery__icon">
                                            <Link className="popup__img" to="/assets/img/blog/blog_17.png">
                                                {" "}
                                                <i className="icofont-eye-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__gallery__img">
                                        <img loading="lazy" src="/assets/img/blog/blog_18.png" alt="" />
                                        <div className="gallery__icon">
                                            <Link className="popup__img" to="img/blog/blog_18.png">
                                                {" "}
                                                <i className="icofont-eye-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__gallery__img">
                                        <img loading="lazy" src="/assets/img/blog/blog_19.png" alt="" />
                                        <div className="gallery__icon">
                                            <Link className="popup__img" to="img/blog/blog_19.png">
                                                {" "}
                                                <i className="icofont-eye-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single__gallery__img">
                                        <img loading="lazy" src="/assets/img/blog/blog_20.png" alt="" />
                                        <div className="gallery__icon">
                                            <Link className="popup__img" to="img/blog/blog_20.png">
                                                {" "}
                                                <i className="icofont-eye-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <h4 className="sidebar__title">Get in Touch</h4>
                                <div className="get__touch__input">
                                    <input type="text" placeholder="Enter name*" />
                                    <input type="text" placeholder="Enter your mail*" />
                                    <input type="text" placeholder="Massage*" />
                                    <Link className="default__button" to="">
                                        SEND MASSAGE
                                    </Link>
                                </div>
                            </div>
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <h4 className="sidebar__title">Popular tag</h4>
                                <div className="populer__tag__list">
                                    <ul>
                                        <li>
                                            <Link to="">Business</Link>
                                        </li>
                                        <li>
                                            <Link to="">Design</Link>
                                        </li>
                                        <li>
                                            <Link to="">apps</Link>
                                        </li>
                                        <li>
                                            <Link to="">landing page</Link>
                                        </li>
                                        <li>
                                            <Link to="">data</Link>
                                        </li>
                                        <li>
                                            <Link to="">book</Link>
                                        </li>
                                        <li>
                                            <Link to="">Design</Link>
                                        </li>
                                        <li>
                                            <Link to="">book</Link>
                                        </li>
                                        <li>
                                            <Link to="">landing page</Link>
                                        </li>
                                        <li>
                                            <Link to="">data</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">
                                <h4 className="sidebar__title">Follow Us</h4>
                                <div className="follow__icon">
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-facebook" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-youtube-play" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-instagram" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-twitter" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="icofont-instagram" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
