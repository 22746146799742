import React from 'react'
import AddAssignment from '../../components/admin/assignment'

export default function Assignment() {
  return (
    <div>
        <AddAssignment/>
    </div>
  )
}
