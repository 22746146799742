import { useState, useEffect } from 'react'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { Link } from 'react-router-dom';
import { MAIN } from '../../Router/paths';

export default function BestCategories() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetCategory()

}, [])


// GET Categories 
const GetCategory = () => {
    setLoader(true);
    axiosInstance.get('/api/categories')
        .then((response) => {
            const Data = response.data
            setLoader(false);
            if (Data?.status) {
                console.log("user side cate", Data)
                setData(Data.categories)

            } else {
                setLoader(false);
                toast.warn(Data?.message);

            }
        }).catch((err) => {
            setLoader(false);
            const Error = err?.response?.data?.message
            setLoader(false);
            toast.error(Error);

        })
}

  return (
    <>
        {loader === true ? <Loader fullPage loading /> : null}
  
    <div className="best__categories sp_bottom_100">
      <div className="container">
        <div className="row">
        {data.map((v,i)=>(
        
          <div className="col-xl-3 col-lg-4 col-sm-6">
         <>
             <div className="best__categories__item aos-init" data-aos="fade-up">
             <Link className="best__categories__link"  key={i} to={MAIN.specificCourse(v.id)}>
               <div className="best__categories__info">
                 <h3 className="best__categories__name">{v.name}</h3>
                 {/* <span className="best__categories__more">12 </span> */}
               </div>
             </Link>
           </div>
           </>
          </div>
          ))}
           
        </div>
      </div>
    </div>
    </>
  );
}
