
import React, { useState } from 'react'
import {  useNavigate, useLocation } from 'react-router-dom'
import { axiosInstance } from '../../config/https'
import { Loader } from "react-overlay-loader"
import { STUDENT } from '../../Router/paths';
import toast from "react-hot-toast";

export default function Confirmpassword() {
    const navigate = useNavigate()
    const location = useLocation();
    const [newpassword, setNewpassword] = useState("");
    const [confirmpass, setConfirmpass] = useState("");
    const [loader, setLoader] = useState(false);
    console.log("location", location.state)

    const ForgetPassword = () => {
        
        if (newpassword === confirmpass) {
            setLoader(true)


            //  fromdata data passing 
            const formData = new FormData()
            formData.append("remember_token", location.state.token)
            formData.append("password", newpassword)
            formData.append("password_confirmation", confirmpass)



            axiosInstance.post("/api/user/reset/password", formData)
                .then((response) => {
                    setLoader(false)
                    const Data = response?.data
                    if (Data?.status) {

                        toast.success(Data.message)
                        navigate(STUDENT.login)

                    } else {
                       toast.error(Data.message)

                    }
                }).catch(() => {
                    setLoader(false)

                })


        } else {
        setLoader(false)

            toast.error("Password Not Match")
        }

    }

    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="loginarea sp_top_100 sp_bottom_100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-md-8 offset-md-2" data-aos="fade-up">

                        </div>
                        <div
                            className="tab-content tab__content__wrapper"

                            data-aos="fade-up"
                        >
                            <div
                                className=""

                            >
                                <div className="col-xl-8 col-md-8 offset-md-2">
                                    <div className="loginarea__wraper">
                                        <div className="login__heading">
                                            <h5 className="login__title">PASSWORD RECOVERY</h5>
                                            <p className="login__description"> Create new password </p>
                                        </div>
                                        <form action="#">
                                            <div className="login__form">
                                                <label className="form__label">New Password</label>
                                                <input
                                                    value={newpassword}
                                                    onChange={(e) => setNewpassword(e.target.value)}
                                                    className="common__login__input"
                                                    type="email"
                                                    placeholder="Your new password"
                                                />
                                            </div>
                                            <div className="login__form">
                                                <label className="form__label"> Confirm Password</label>
                                                <input
                                                    value={confirmpass}
                                                    onChange={(e) => setConfirmpass(e.target.value)}
                                                    className="common__login__input"
                                                    type="email"
                                                    placeholder="Your confirm password"
                                                />
                                            </div>


                                            <div className="login__button" >
                                                <button type='button' className="default__button" onClick={() => ForgetPassword()}>
                                                    Recover Password
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=" login__shape__img educationarea__shape_image">
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__1"
                            src="/assets/img/education/hero_shape2.png"
                            alt="Shape"
                        />
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__2"
                            src="/assets/img/education/hero_shape3.png"
                            alt="Shape"
                        />
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__3"
                            src="/assets/img/education/hero_shape4.png"
                            alt="Shape"
                        />
                        <img
                            loading="lazy"
                            className="hero__shape hero__shape__4"
                            src="/assets/img/education/hero_shape5.png"
                            alt="Shape"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
