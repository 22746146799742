import React from 'react'
import { Link } from 'react-router-dom'
export default function index() {
    return (
        <>
            <div className="aboutarea__5 sp_bottom_100 sp_top_100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6" data-aos="fade-up">
                            <div className="aboutarea__5__img" data-tilt="">
                                <img loading="lazy" src="/assets/img/about/about_14.png" alt="about" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6" data-aos="fade-up">
                            <div className="aboutarea__content__wraper__5">
                                <div className="section__title">
                                    <div className="section__title__button">
                                        <div className="default__small__button">About us</div>
                                    </div>
                                    <div className="section__title__heading ">
                                        <h2>Welcome to the online Learning Center</h2>
                                    </div>
                                </div>
                                <div className="about__text__5">
                                    <p>
                                        Meet my startup design agency Shape Rex Currently I am working at
                                        CodeNext as Product Designer.
                                    </p>
                                </div>
                                <div className="aboutarea__5__small__icon__wraper">
                                    <div className="aboutarea__5__small__icon">
                                        <img loading="lazy" src="/assets/img/about/about_15.png" alt="about" />
                                    </div>
                                    <div className="aboutarea__small__heading">
                                        <span>10+ Years ExperienceIn</span> this game, Means Product
                                        Designing
                                    </div>
                                </div>
                                <div className="aboutarea__para__5">
                                    <p>
                                        I love to work in User Experience &amp; User Interface designing.
                                        Because I love to solve the design problem and find easy and
                                        better solutions to solve it. I always try my best to make good
                                        user interface with the best user experience. I have been working
                                        as a UX Designer
                                    </p>
                                </div>
                                <div className="aboutarea__bottom__button__5">
                                    <Link className="default__button" to="#">
                                        {" "}
                                        More About
                                        <i className="icofont-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="abouttabarea sp_bottom_70">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" data-aos="fade-up">
                            <ul className="nav  about__button__wrap" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="single__tab__link active"
                                        data-bs-toggle="tab"
                                        data-bs-target="#projects__one"
                                        type="button"
                                    >
                                        About
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="single__tab__link"
                                        data-bs-toggle="tab"
                                        data-bs-target="#projects__two"
                                        type="button"
                                    >
                                        Course
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="single__tab__link"
                                        data-bs-toggle="tab"
                                        data-bs-target="#projects__three"
                                        type="button"
                                    >
                                        awards
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="single__tab__link"
                                        data-bs-toggle="tab"
                                        data-bs-target="#projects__four"
                                        type="button"
                                    >
                                        education
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="tab-content tab__content__wrapper"
                            id="myTabContent"
                            data-aos="fade-up"
                        >
                            <div
                                className="tab-pane fade active show"
                                id="projects__one"
                                role="tabpanel"
                                aria-labelledby="projects__one"
                            >
                                <div className="col-xl-12">
                                    <div className="aboutarea__content__tap__wraper">
                                        <p className="paragraph__1">
                                            There are many variations of passages of Lorem Ipsum available,
                                            but the majority have suffered alteration in some form, by
                                            injected humour, or randomised words look even slightly
                                            believable. If you are going to use a passage of Lorem Ipsum,
                                            you need to be sure there isn't anything embarrassing hidden in
                                            the middle of text. Ipsum generators on the Internet tend to
                                            repeat predefined chunks as necessary, making this the first
                                            true generator on the Internet. It uses a dictionary of over 200
                                            combined with a handful of model sentence structures, to
                                            generate Lorem Ipsum which looks reasonable. The generated Lorem
                                            Ipsum is therefore always free from characteristic words etc.
                                        </p>
                                        <div className="aboutarea__tap__heading">
                                            <h5>World best education site - (Computer engeenering)</h5>
                                            <p>
                                                There are many variations of passages of Lorem Ipsum
                                                available, but the majority have suffered alteration in some
                                                form, by injected humour, or randomised words which dont look
                                                even slightly believable. If you are going to useery
                                            </p>
                                        </div>
                                        <div className="aboutarea__tap__heading">
                                            <h5>Web and user interface design - Development</h5>
                                            <p>
                                                There are many variations of passages of Lorem Ipsum
                                                available, but the majority have suffered alteration in some
                                                form, by injected humour, or randomised words which dont look
                                                even slightly believable. If you are going to useery
                                            </p>
                                        </div>
                                        <div className="aboutarea__tap__heading">
                                            <h5>Interaction design - Animation</h5>
                                            <p>
                                                There are many variations of passages of Lorem Ipsum
                                                available, but the majority have suffered alteration in some
                                                form, by injected humour, or randomised words which dont look
                                                even slightly believable. If you are going to useery
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="projects__two"
                                role="tabpanel"
                                aria-labelledby="projects__two"
                            >
                                <div
                                    className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                                    data-aos="fade-up"
                                >
                                    <div className="gridarea__img">
                                        <Link to="">
                                            <img loading="lazy" src="/assets/img/grid/grid_1.png" alt="grid" />
                                        </Link>
                                        <div className="gridarea__small__button">
                                            <div className="grid__badge">Data &amp; Tech</div>
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <Link to="#">
                                                <i className="icofont-heart-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li>
                                                    <i className="icofont-book-alt" /> 23 Lesson
                                                </li>
                                                <li>
                                                    <i className="icofont-clock-time" /> 1 hr 30 min
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3>
                                                <Link to="">
                                                    Become a product Manager learn the skills &amp; job.
                                                </Link>
                                            </h3>
                                        </div>
                                        <div className="gridarea__price">
                                            $32.00 <del>/ $67.00</del>
                                            <span>Free.</span>
                                        </div>
                                        <div className="gridarea__bottom">
                                            <div className="gridarea__bottom__left">
                                                <Link to="">
                                                    <div className="gridarea__small__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/grid/grid_small_1.jpg"
                                                            alt="grid"
                                                        />
                                                        <div className="gridarea__small__content">
                                                            <h6>Mirnsdo .H</h6>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="gridarea__star">
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <span>(44)</span>
                                                </div>
                                            </div>
                                            <div className="gridarea__details">
                                                <Link to="">
                                                    Know Details
                                                    <i className="icofont-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                                    data-aos="fade-up"
                                >
                                    <div className="gridarea__img">
                                        <img loading="lazy" src="/assets/img/grid/grid_2.png" alt="grid" />
                                        <div className="gridarea__small__button">
                                            <div className="grid__badge blue__color">Mechanical</div>
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <Link to="#">
                                                <i className="icofont-heart-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li>
                                                    <i className="icofont-book-alt" /> 23 Lesson
                                                </li>
                                                <li>
                                                    <i className="icofont-clock-time" /> 1 hr 30 min
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3>
                                                <Link to="">
                                                    Foundation course to under stand about softwere
                                                </Link>
                                            </h3>
                                        </div>
                                        <div className="gridarea__price">
                                            $32.00 <del>/ $67.00</del>
                                            <span>Free.</span>
                                        </div>
                                        <div className="gridarea__bottom">
                                            <div className="gridarea__bottom__left">
                                                <Link to="">
                                                    <div className="gridarea__small__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/grid/grid_small_1.jpg"
                                                            alt="grid"
                                                        />
                                                        <div className="gridarea__small__content">
                                                            <h6>Mirnsdo .H</h6>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="gridarea__star">
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <span>(44)</span>
                                                </div>
                                            </div>
                                            <div className="gridarea__details">
                                                <Link to="">
                                                    Know Details
                                                    <i className="icofont-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="gridarea__wraper gridarea__wraper__2 gridarea__course__list"
                                    data-aos="fade-up"
                                >
                                    <div className="gridarea__img">
                                        <Link to="">
                                            <img loading="lazy" src="/assets/img/grid/grid_3.png" alt="grid" />
                                        </Link>
                                        <div className="gridarea__small__button">
                                            <div className="grid__badge pink__color">Development</div>
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <Link to="#">
                                                <i className="icofont-heart-alt" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li>
                                                    <i className="icofont-book-alt" /> 23 Lesson
                                                </li>
                                                <li>
                                                    <i className="icofont-clock-time" /> 1 hr 30 min
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3>
                                                <Link to="">
                                                    Strategy law and with for organization Foundation
                                                </Link>
                                            </h3>
                                        </div>
                                        <div className="gridarea__price">
                                            $32.00 <del>/ $67.00</del>
                                            <span>Free.</span>
                                        </div>
                                        <div className="gridarea__bottom">
                                            <div className="gridarea__bottom__left">
                                                <Link to="">
                                                    <div className="gridarea__small__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/grid/grid_small_1.jpg"
                                                            alt="grid"
                                                        />
                                                        <div className="gridarea__small__content">
                                                            <h6>Mirnsdo .H</h6>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className="gridarea__star">
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <i className="icofont-star" />
                                                    <span>(44)</span>
                                                </div>
                                            </div>
                                            <div className="gridarea__details">
                                                <Link to="">
                                                    Know Details
                                                    <i className="icofont-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="projects__three"
                                role="tabpanel"
                                aria-labelledby="projects__three"
                            >
                                <div className="col-xl-12">
                                    <div className="aboutarea__content__tap__wraper">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div
                                                    className="single__event__wraper single__award"
                                                    data-aos="fade-up"
                                                >
                                                    <div className="eventarea__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/about/award-1.jpg"
                                                            alt="event"
                                                        />
                                                    </div>
                                                    <div className="eventarea__content__wraper">
                                                        <div className="single__event__heading">
                                                            <h4>
                                                                <Link to="">
                                                                    Forging relationships between national
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                        <div className="single__event__button">
                                                            <Link to="">
                                                                Read More <i className="icofont-simple-right" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div
                                                    className="single__event__wraper single__award"
                                                    data-aos="fade-up"
                                                >
                                                    <div className="eventarea__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/about/award-2.jpg"
                                                            alt="event"
                                                        />
                                                    </div>
                                                    <div className="eventarea__content__wraper">
                                                        <div className="single__event__heading">
                                                            <h4>
                                                                <Link to="">
                                                                    Lorem ipsum dolor sit amet conse ctetur.
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                        <div className="single__event__button">
                                                            <Link to="">
                                                                Read More <i className="icofont-simple-right" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div
                                                    className="single__event__wraper single__award"
                                                    data-aos="fade-up"
                                                >
                                                    <div className="eventarea__img">
                                                        <img
                                                            loading="lazy"
                                                            src=".assets/img/about/award-3.jpg"
                                                            alt="event"
                                                        />
                                                    </div>
                                                    <div className="eventarea__content__wraper">
                                                        <div className="single__event__heading">
                                                            <h4>
                                                                <Link to="">
                                                                    Forging relationships between national
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                        <div className="single__event__button">
                                                            <Link to="">
                                                                Read More <i className="icofont-simple-right" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div
                                                    className="single__event__wraper single__award"
                                                    data-aos="fade-up"
                                                >
                                                    <div className="eventarea__img">
                                                        <img
                                                            loading="lazy"
                                                            src="/assets/img/about/award-4.jpg"
                                                            alt="event"
                                                        />
                                                    </div>
                                                    <div className="eventarea__content__wraper">
                                                        <div className="single__event__heading">
                                                            <h4>
                                                                <Link to="">
                                                                    Lorem ipsum dolor sit amet conse ctetur.
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                        <div className="single__event__button">
                                                            <Link to="">
                                                                Read More <i className="icofont-simple-right" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="projects__four"
                                role="tabpanel"
                                aria-labelledby="projects__four"
                            >
                                <div className="col-xl-12">
                                    <div className="aboutarea__content__tap__wraper">
                                        <div className="aboutarea__tap__heading">
                                            <h5>World best education site - (Computer engeenering)</h5>
                                            <p>
                                                There are many variations of passages of Lorem Ipsum
                                                available, but the majority have suffered alteration in some
                                                form, by injected humour, or randomised words which dont look
                                                even slightly believable. If you are going to useery
                                            </p>
                                        </div>
                                        <div className="aboutarea__tap__heading">
                                            <h5>Web and user interface design - Development</h5>
                                            <p>
                                                There are many variations of passages of Lorem Ipsum
                                                available, but the majority have suffered alteration in some
                                                form, by injected humour, or randomised words which dont look
                                                even slightly believable. If you are going to useery
                                            </p>
                                        </div>
                                        <div className="aboutarea__tap__heading">
                                            <h5>Interaction design - Animation</h5>
                                            <p>
                                                There are many variations of passages of Lorem Ipsum
                                                available, but the majority have suffered alteration in some
                                                form, by injected humour, or randomised words which dont look
                                                even slightly believable. If you are going to useery
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="gridarea__2 sp_bottom_100" data-aos="fade-up">
                <div className="container">
                    <div className="section__title">
                        <div className="section__title__button">
                            <div className="default__small__button">Popular Courses</div>
                        </div>
                        <div className="section__title__heading">
                            <h2>
                                Choose The Best Package
                                <br />
                                For your Learning
                            </h2>
                        </div>
                    </div>
                    <div className="row about__course__slider__active row__custom__class">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item column__custom__class">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="">
                                        <img loading="lazy" src="/assets/img/grid/grid_7.png" alt="grid" />
                                    </Link>
                                    <div className="gridarea__small__button gridarea__small__button__1">
                                        <div className="grid__badge">Data &amp; Tech</div>
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="#">
                                            <i className="icofont-heart-alt" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt" /> 23 Lesson
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time" /> 1 hr 30 min
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3>
                                            <Link to="">
                                                Foundation course to under stand about softwere
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className="gridarea__price">
                                        $32.00 <del>/ $67.00</del>
                                        <span>
                                            {" "}
                                            <del className="del__2">Free</del>
                                        </span>
                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="">
                                            <div className="gridarea__small__img">
                                                <img
                                                    loading="lazy"
                                                    src="/assets/img/grid/grid_small_1.jpg"
                                                    alt="grid"
                                                />
                                                <div className="gridarea__small__content">
                                                    <h6>Micle Jhon</h6>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="gridarea__star">
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <span>(44)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item column__custom__class">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="">
                                        <img loading="lazy" src="/assets/img/grid/grid_8.png" alt="grid" />
                                    </Link>
                                    <div className="gridarea__small__button">
                                        <div className="grid__badge">Data &amp; Tech</div>
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="#">
                                            <i className="icofont-heart-alt" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt" /> 23 Lesson
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time" /> 1 hr 30 min
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3>
                                            <Link to="">
                                                Foundation course to under stand about softwere
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className="gridarea__price">
                                        $32.00 <del>/ $67.00</del>
                                        <span>
                                            {" "}
                                            <del className="del__2">Free</del>
                                        </span>
                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="">
                                            <div className="gridarea__small__img">
                                                <img
                                                    loading="lazy"
                                                    src="/assets/img/grid/grid_small_1.jpg"
                                                    alt="grid"
                                                />
                                                <div className="gridarea__small__content">
                                                    <h6>Micle Jhon</h6>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="gridarea__star">
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <span>(44)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item column__custom__class">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="">
                                        <img loading="lazy" src="/assets/img/grid/grid_9.png" alt="grid" />
                                    </Link>
                                    <div className="gridarea__small__button">
                                        <div className="grid__badge">Data &amp; Tech</div>
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="#">
                                            <i className="icofont-heart-alt" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt" /> 23 Lesson
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time" /> 1 hr 30 min
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3>
                                            <Link to="">
                                                Foundation course to under stand about softwere
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className="gridarea__price">
                                        $32.00 <del>/ $67.00</del>
                                        <span>
                                            {" "}
                                            <del className="del__2">Free</del>
                                        </span>
                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="">
                                            <div className="gridarea__small__img">
                                                <img
                                                    loading="lazy"
                                                    src="/assets/img/grid/grid_small_1.jpg"
                                                    alt="grid"
                                                />
                                                <div className="gridarea__small__content">
                                                    <h6>Micle Jhon</h6>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="gridarea__star">
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <i className="icofont-star" />
                                            <span>(44)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div className="testmonialarea sp_top_100 sp_bottom_110">
                <div className="container">
                    <div className="row" data-aos="fade-up">
                        <div className="section__title text-center">
                            <div className="section__title__button">
                                <div className="default__small__button">Course List</div>
                            </div>
                            <div className="section__title__heading heading__underline">
                                <h2>
                                    Client <span>Testimonial</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div
                        className="row row__custom__class testimonial__slider__active default__arrow"
                        data-aos="fade-up"
                    >
                        <div className="col-xl-6 column__custom__class">
                            <div className="single__testimonial__wraper">
                                <div className="single__testimonial__inner">
                                    <div className="testimonial__img">
                                        <img
                                            loading="lazy"
                                            src="/assets/img/testimonial/testi_1.png"
                                            alt="testi"
                                        />
                                        <div className="testimonial__name">
                                            <h6>Mirnsdo Jons</h6>
                                            <span>Ui/Ux Designer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial__icon">
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star dark__color" />
                                    </div>
                                </div>
                                <div className="testimonial__content">
                                    <p>
                                        The other hand we denounce with righteou indg ation men who are so
                                        beguiled and demoraliz ed by the of the mo ment.Dislike men who
                                        are so beguiled and dems ed by the charms of pleas ure of the
                                        moment. Lorem{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 column__custom__class">
                            <div className="single__testimonial__wraper">
                                <div className="single__testimonial__inner">
                                    <div className="testimonial__img">
                                        <img
                                            loading="lazy"
                                            src="/assets/img/testimonial/testi_1.png"
                                            alt="testi"
                                        />
                                        <div className="testimonial__name">
                                            <h6>Mirnsdo Jons</h6>
                                            <span>Ui/Ux Designer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial__icon">
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star dark__color" />
                                    </div>
                                </div>
                                <div className="testimonial__content">
                                    <p>
                                        The other hand we denounce with righteou indg ation men who are so
                                        beguiled and demoraliz ed by the of the mo ment.Dislike men who
                                        are so beguiled and dems ed by the charms of pleas ure of the
                                        moment. Lorem{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 column__custom__class">
                            <div className="single__testimonial__wraper">
                                <div className="single__testimonial__inner">
                                    <div className="testimonial__img">
                                        <img
                                            loading="lazy"
                                            src="/assets/img/testimonial/testi_1.png"
                                            alt="testi"
                                        />
                                        <div className="testimonial__name">
                                            <h6>Mirnsdo Jons</h6>
                                            <span>Ui/Ux Designer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial__icon">
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star dark__color" />
                                    </div>
                                </div>
                                <div className="testimonial__content">
                                    <p>
                                        The other hand we denounce with righteou indg ation men who are so
                                        beguiled and demoraliz ed by the of the mo ment.Dislike men who
                                        are so beguiled and dems ed by the charms of pleas ure of the
                                        moment. Lorem{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 column__custom__class">
                            <div className="single__testimonial__wraper">
                                <div className="single__testimonial__inner">
                                    <div className="testimonial__img">
                                        <img
                                            loading="lazy"
                                            src="/assets/img/testimonial/testi_1.png"
                                            alt="testi"
                                        />
                                        <div className="testimonial__name">
                                            <h6>Mirnsdo Jons</h6>
                                            <span>Ui/Ux Designer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial__icon">
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star" />
                                        <i className="icofont-star dark__color" />
                                    </div>
                                </div>
                                <div className="testimonial__content">
                                    <p>
                                        The other hand we denounce with righteou indg ation men who are so
                                        beguiled and demoraliz ed by the of the mo ment.Dislike men who
                                        are so beguiled and dems ed by the charms of pleas ure of the
                                        moment. Lorem{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="brandarea sp_bottom_60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" data-aos="fade-up">
                            <div className="section__title text-center">
                                <div className="section__title__heading heading__underline">
                                    <h2>
                                        Relied on marketers trusted by engineers and
                                        <br />
                                        Beloved by <span>Executives</span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="brandarea__wraper" data-aos="fade-up">
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_1.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_2.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_3.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_4.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_5.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_6.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_7.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_8.png" alt="brand" />
                                </Link>
                            </div>
                            <div className="brandarea__img">
                                <Link to="#">
                                    <img loading="lazy" src="/assets/img/brand/brand_9.png" alt="brand" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
