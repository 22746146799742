import { useState, useEffect } from 'react'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { useNavigate} from 'react-router-dom';

export default function CoursesComp() {
const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetCourses()

}, [])


// GET courses 
const GetCourses = () => {
    setLoader(true);
    axiosInstance.get('/api/courses')
        .then((response) => {
            const Data = response.data
            setLoader(false);
            if (Data?.status) {
                console.log("user courses all", Data)
                setData(Data.courses)

            } else {
                setLoader(false);
                toast.warn(Data?.message);

            }
        }).catch((err) => {
            setLoader(false);
            const Error = err?.response?.data?.message
            setLoader(false);
            toast.error(Error);

        })
}

const handleCourseClick = (data) => {

  navigate('/pricing', { state: data });
 
};

  return (
    <>
            {loader === true ? <Loader fullPage loading /> : null}

      {/* course__section__start   */}
      <div className="coursearea sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
         
            <div className="col-xl-12">
              <div
                className="tab-content tab__content__wrapper"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade  active show"
                  id="projects__one"
                  role="tabpanel"
                  aria-labelledby="projects__one"
                >
                  <div className="row">
                  {data.map((v, i) => (
               
                  <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter5 filter2"
              data-aos="fade-up"
            >
           
                <div className="gridarea__wraper">
                  <div className="gridarea__img">
                    <div to="" onClick={() => handleCourseClick(v)}>
                      <img
                        loading="lazy"
                        src={v.image}
                        alt="grid"
                      />
                    </div>
                    <div className="gridarea__small__button">
                      <div className="grid__badge yellow__color">{v.title}</div>
                    </div>
                   
                  </div>
                  <div className="gridarea__content">
                    <div className="gridarea__list">
                      <ul>
                        <li>
                          <i className="icofont-book-alt" /> {v.course_level} 
                        </li>
                        <li>
                          <i className="icofont-clock-time" /> {v.language} 
                        </li>
                      </ul>
                    </div>
                    <div className="gridarea__heading">
                      <h3>
                        <div  onClick={() => handleCourseClick(v)}>
                          {v.description}
                        </div>
                      </h3>
                    </div>
                    <div className="gridarea__price">
                    ${v.price_discount}<del>/ ${v.price}</del>
                      <span>
                        {" "}
                        <span className="">{v.course_status}</span>
                      </span>
                    </div>
                  
                  </div>
                </div>
            </div>
              ))}
                  </div>
                </div>
               
              </div>
            </div>
        
          </div>
        </div>
      </div>
      {/* course__section__end   */}
    </>
  );
}
