import React from 'react'
import { Link } from 'react-router-dom'
import { STUDENT } from '../../Router/paths'
export default function assignment() {
    return (
        <>
            <div className="tution sp_bottom_100 sp_top_100">
                <div className="container-fluid full__width__padding">
                    <div className="row">
                        <div
                            className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12"
                            data-aos="fade-up"
                        >
                            <div
                                className="accordion content__cirriculum__wrap"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Lesson #01
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to={STUDENT.coursematerial}>
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link  to={STUDENT.coursequiz}>
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to={STUDENT.courseassignment}>
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            Lesson #02
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>7.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-materials">
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-quiz">
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to="/course-assignment">
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            Lesson #03
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>7.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-materials">
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-quiz">
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to="/course-assignment">
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            Lesson #04
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Intro</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>3.27</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>5.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-video-alt" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/lesson">
                                                            <span>Course Outline</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                                <div className="scc__meta">
                                                    <strong>7.00</strong>
                                                    <Link to="">
                                                        <span className="question">
                                                            <i className="icofont-eye" /> Preview
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-materials">
                                                            <span>Course Materials</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-audio" />
                                                    <h5>
                                                        {" "}
                                                        <Link to="/course-quiz">
                                                            <span>Summer Quiz</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="scc__wrap">
                                                <div className="scc__info">
                                                    <i className="icofont-file-text" />
                                                    <h5>
                                                        <Link to="/course-assignment">
                                                            <span>Assignment</span>
                                                        </Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFive">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                 Lesson #05
                              </button>
                          </h2>
                          <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                              <div class="accordion-body">

                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-video-alt"></i>
                                          <h5> <Link to="lesson.html"><span>Course Intro</span></Link></h5>
                                      </div>
                                      <div class="scc__meta">
                                          <strong>3.27</strong>
                                          <Link to="lesson.html"><span class="question"><i class="icofont-eye"></i> Preview</span></Link>
                                          
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-video-alt"></i>
                                          <h5> <Link to="lesson-2.html"><span>Course Outline</span></Link></h5>
                                      </div>
                                      <div class="scc__meta">
                                          <strong>5.00</strong>
                                          <Link to="lesson-2.html"><span class="question"><i class="icofont-eye"></i> Preview</span></Link>
                                          
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-video-alt"></i>
                                          <h5> <Link to="lesson-2.html"><span>Course Outline</span></Link></h5>
                                      </div>
                                      <div class="scc__meta">
                                          <strong>7.00</strong>
                                          <Link to="lesson-3.html"><span class="question"><i class="icofont-eye"></i> Preview</span></Link>
                                          
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-file-text"></i>
                                          <h5> <Link to="lesson-course-materials.html"><span>Course Materials</span></Link></h5>
                                      </div>
                                  </div>
                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-audio"></i>
                                          <h5>  <Link to="lesson-quiz.html"><span>Summer Quiz</span></Link></h5>
                                      </div>
                                  </div>

                                  <div class="scc__wrap">
                                      <div class="scc__info">
                                          <i class="icofont-file-text"></i>
                                          <h5><Link to="lesson-assignment.html"><span>Assignment</span></Link></h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> */}
                            </div>
                        </div>
                        <div
                            className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
                            data-aos="fade-up"
                        >
                            <div className="lesson__assignment__wrap">
                                <h3>Latest Assignment lists</h3>
                                <hr className="hr" />
                                <div className="dashboard__table table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Assignment Name</th>
                                                <th>Total Marks</th>
                                                <th>Total Submit</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <span>Write a the 5</span>
                                                    <p>
                                                        course: <Link to="#">Fundamentals</Link>
                                                    </p>
                                                </th>
                                                <td>
                                                    <p>80</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <div className="dashboard__button__group">
                                                        <Link className="dashboard__small__btn__2" to="#">
                                                            <i className="icofont-download" /> Download
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="dashboard__table__row">
                                                <th>
                                                    <span>Write a the 5</span>
                                                    <p>
                                                        course: <Link to="#">Fundamentals</Link>
                                                    </p>
                                                </th>
                                                <td>
                                                    <p>80</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <div className="dashboard__button__group">
                                                        <Link className="dashboard__small__btn__2" to="#">
                                                            <i className="icofont-download" /> Download
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <span>Write a the 5</span>
                                                    <p>
                                                        course: <Link to="#">Fundamentals</Link>
                                                    </p>
                                                </th>
                                                <td>
                                                    <p>80</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <div className="dashboard__button__group">
                                                        <Link className="dashboard__small__btn__2" to="#">
                                                            <i className="icofont-download" /> Download
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="dashboard__table__row">
                                                <th>
                                                    <span>Write a the 5</span>
                                                    <p>
                                                        course: <Link to="#">Fundamentals</Link>
                                                    </p>
                                                </th>
                                                <td>
                                                    <p>80</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <div className="dashboard__button__group">
                                                        <Link className="dashboard__small__btn__2" to="#">
                                                            <i className="icofont-download" /> Download
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <br />
                                <div className="assignment__submit__wrap">
                                    <h3>Assignment Submission</h3>
                                    <hr className="hr" />
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">
                                            Email address
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="exampleFormControlInput1"
                                            placeholder="name@example.com"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="exampleFormControlTextarea1"
                                            className="form-label"
                                        >
                                            Assignment Content
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="formFileLg" className="form-label">
                                            Drop file here
                                        </label>
                                        <input
                                            className="form-control form-control-lg"
                                            id="formFileLg"
                                            type="file"
                                        />
                                    </div>
                                    <Link className="default__button" to="#">
                                        Submit Assignment
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
