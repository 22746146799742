import { useEffect, useState } from 'react'
import { axiosInstance } from '../../config/https';
import {  useParams } from 'react-router-dom'
import Sidbar from '../../layout/admin/sidbar'
import Breadcrumb from '../../layout/admin/breadcrumb'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import qs from 'qs';

export default function Quizquestionopt() {
    const [loader, setLoader] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [options, setOptions] = useState([]);
    const { id } = useParams();

    // Add 
    const [option, setOption] = useState("");
    const [correct, setCorrect] = useState("");

    // Edit
    const [eoption, seteOption] = useState("");
    const [ecorrect, seteCorrect] = useState("");
    const [quesid, setQuesid] = useState("");
    const [optionid, setOptionid] = useState("");

    // delete 
   
   const [deletequesid, setDeletequesid] = useState("");
   const [deleteoptid, setDeleteoptid] = useState("");

    useEffect(() => {
        window.scroll(0, 0)
        GetAlloptions(id)
    }, [id])

    const handleInputChange = (event, func) => {
        func(event.target.value);
    }


    const GetAlloptions = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/questions/${id}/options`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all quizes", Data);

                    setQuestions(Data.question);

                    if (Data.Questionoption.length > 0) {
                        setOptions(Data.Questionoption);
                    } else {
                        setLoader(false);
                        toast.error("Options not available for this question.");
                    }
                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }


    // ADD API
    const AddOption = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('option', option)
        formData.append('correct', correct)
        axiosInstance.post(`/api/questions/${id}/options`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                if (Data?.status) {
                    CloseAddmodal()
                    toast.success("Option Added Successfully");
                    setCorrect("")
                    setOption("")
                    GetAlloptions(id)

                }

                else {
                    setLoader(false)
                    const Err = Data.message
                    if(Err){

                        toast.error(Data.message);
                    }
                    else{
                        const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));

                    }

                
               
                    
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
               
                setLoader(false);
                toast.error(Error);
            })
    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }


   // EDIT FUNC 

   const Editmodal = (item) => {
    setQuesid(item.question_id)
    setOptionid(item.id)
    seteCorrect(item.correct)
    seteOption(item.option)


}
const CloseEditmodal = () => {
    const deletemodal = document.getElementById("staticBackdropedit");
    console.log("deletemodal", deletemodal)

    // Simulate a click on the close button to close the modal
    const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
    console.log("closeBtn", closeBtn)

    closeBtn.click();

}

// UPDATE  
const UpdateOption = () => {
    setLoader(true);
  
// Serialize data into x-www-form-urlencoded format
const formData = qs.stringify({
    option: eoption,
    correct: ecorrect,

});

axiosInstance.put(`/api/questions/${quesid}/options/${optionid}`, formData, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
})
        .then((response) => {
            setLoader(false);
            const Data = response?.data;
            console.log("data update", Data)
            if (Data?.status) {
                CloseEditmodal()
                toast.success("Option Updated Successfully");
                GetAlloptions(id)
            

            }

            else {
                setLoader(false)
                const ErrList = Object.keys(Data.errors);
                ErrList.map((e) => toast.error(Data.errors[e][0]));
            }

        }).catch((err) => {
            const Error = err?.response?.data?.message
            setLoader(false);
            toast.error(Error);
        })
}


const DeleteModal = (data) => {
    console.log(data)
    setDeleteoptid(data.id)
    setDeletequesid(data.question_id)

}

    // DELETE 
    const DeleteOption = () => {
        setLoader(true);
        axiosInstance.delete(`/api/questions/${deletequesid}/options/${deleteoptid}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetAlloptions(id)
                 
                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtndlt", closeBtn)

        closeBtn.click();

    }




    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Question</h4>
                                        <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                            Add Option
                                        </button>

                                    </div>

                                    <div
                                        className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12"
                                        data-aos="fade-up"
                                    >
                                        <div className="lesson__quiz__wrap">
                                            <div className="quiz__single__attemp">

                                                <div className="quiz__single__attemp">
                                                    <ul>

                                                        {/* <li>Attempts allowed: 1</li> */}
                                                        {/* <li> | Time: 5.0 Min </li> */}
                                                        <li><strong>Score:</strong>  {questions.score}</li>
                                                        <li>  | <strong>Type:</strong> {questions.type}</li>

                                                    </ul>
                                                    <hr className="hr" />
                                                    <h3>(Q) {questions.question} </h3>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {options

                                                                .map((option) => (
                                                                    <div key={option.id} className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            defaultValue=""
                                                                            id={`option-${option.id}`}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`option-${option.id}`}>
                                                                            {option.option}
                                                                        </label>
                                                                      
                                                                    </div>



                                                                ))}
                                                        </div>

                                                        <div className="col-md-6">
                                                            {options

                                                                .map((option) => (
                                                                    <div key={option.id} className="form-check">
                                                                                                    <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td>
                                                                            <div className="dashboard__button__group">

                                                                                <button onClick={()=>Editmodal(option)} className="modal_button " data-bs-toggle="modal" data-bs-target="#staticBackdropedit" ><svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg> Edit </button>

                                                                                <button  onClick={()=>DeleteModal(option)} className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ><svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg> Delete </button>
                                                                              

                                                                            </div>
                                                                        </td>
                                                                    </div>
                                                                </td>
                                                                      
                                                                    </div>



                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <br />
                                            <br />
                                            <br />

                                           


                                        </div>



                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD  */}
            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Option</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="option" class="form-label">Option</label>
                                        <input
                                            id='option'
                                            className="form-control"
                                            value={option}
                                            onChange={(e) => handleInputChange(e, setOption)}
                                            type="text"
                                            placeholder="Enter Option"
                                        />
                                    </div>

                                </div>


                                <div className='col-md-12'>
                                    <select value={correct} onChange={(e) => handleInputChange(e, setCorrect)} class="form-select" aria-label="Default select example">
                                        <option >Select Status</option>
                                        <option value="1">True</option>
                                        <option value="0">False</option>

                                    </select>

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => AddOption()}>
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* EDIT MODAL  */}

            <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Option</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="option" class="form-label">Option</label>
                                        <input
                                            id='option'
                                            className="form-control"
                                            value={eoption}
                                            onChange={(e) => handleInputChange(e, seteOption)}
                                            type="text"
                                            placeholder="Enter Option"
                                        />
                                    </div>

                                </div>


                                <div className='col-md-12'>
                                    <select value={ecorrect} onChange={(e) => handleInputChange(e, seteCorrect)} class="form-select" aria-label="Default select example">
                                        <option >Select option</option>
                                        <option value="1">True</option>
                                        <option value="0">False</option>

                                    </select>

                                </div>


                            </div>

                        </div>
                       
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => UpdateOption()}>
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

  
            {/* DELETE  */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Option</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this Option?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteOption()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
