import React from 'react'
import Logincomp from '../../auth/login'
import BreadCrumb from "../../components/breadCrumb";

export default function Login() {
  return (
    <>
      <BreadCrumb heading={'Student Login'}/>

    <Logincomp/>
        
    </>
  )
}
