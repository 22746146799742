import { useState, useEffect } from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { Link } from 'react-router-dom'
import { STUDENT } from '../../Router/paths'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Enrolledcourse() {
  const [datacourses, setDatacourses] = useState([]);
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    window.scroll(0,0)
    GetCourses()

}, [])

  // GET COURSES
  const GetCourses = () => {
    setLoader(true);
    axiosInstance.get('/api/student/courses')
        .then((response) => {
            const Data = response.data
            setLoader(false);
            if (Data?.status) {
                console.log("get data",Data)
                setDatacourses(Data.userCourses)

            } else {
                setLoader(false);
                toast.warn(Data?.message);

            }
        }).catch((err) => {
            setLoader(false);
            const Error = err?.response?.data?.message
            setLoader(false);
            toast.error(Error);

        })
}
  return (
    <>
            {loader === true ? <Loader fullPage loading /> : null}

      <div className="dashboardarea sp_bottom_100">
      <Breadcrumb/>
        <div className="dashboard">
          <div className="container-fluid full__width__padding">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-12">
                <StudentSidbar />
              </div>
              <div className="col-xl-9 col-lg-9 col-md-12">
                <div className="dashboard__content__wraper">
                  <div className="dashboard__section__title">
                    <h4>My Courses</h4>
                  </div>
                  <div className="row">

                    <div
                      className="tab-content tab__content__wrapper aos-init aos-animate"
                    
                      data-aos="fade-up"
                    >
                      <div
                        className="tab-pane fade active show"
                       
                      >
                        <div className="row">
                            {datacourses.map((v,i)=>(
                          <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                              <div className="gridarea__wraper">
                              <div className="gridarea__img">
                                <Link to={STUDENT.coursedetail(v.id)}>
                                  <img
                                    loading="lazy"
                                    src={v.image}
                                    alt="grid"
                                  />
                                </Link>
                                <div className="gridarea__small__button">
                                  <div className="grid__badge">{v.title}</div>
                                </div>

                              </div>
                              <div className="gridarea__content">
                                <div className="gridarea__list">
                                  <ul>
                                    <li>
                                      <i className="icofont-book-alt" /> {v.course_level}
                                    </li>
                                    <li>
                                      <i className="icofont-clock-time" /> {v.language}
                                    </li>
                                  </ul>
                                </div>
                                <div className="gridarea__heading">
                                  <h3>
                                    <Link to={STUDENT.coursedetail(v.id)}>
                                   {v.description}
                                    </Link>
                                  </h3>
                                </div>
                                <div className="gridarea__price">
                                ${v.price_discount}/<del>${v.price}</del>
                                  <span>
                                    {" "}
                                    <del className="del__2">Free</del>
                                  </span>
                                </div>

                              </div>

                            </div>


                          </div>
                            ))}
                            
                      
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
