import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../layout/admin/breadcrumb'
import Sidbar from '../../layout/admin/sidbar'
import { Loader } from 'react-overlay-loader';
import { Link, useParams } from 'react-router-dom';
import { axiosInstance } from '../../config/https';
import toast from 'react-hot-toast';

export default function Submittedassignment() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [marks, setMarks] = useState("");
    const [aproved, setAproved] = useState("");
    const [dataid, setdataId] = useState("");



    const { id } = useParams();


    useEffect(() => {
        window.scroll(0, 0)
        GetSubmitAssignment(id)
    }, [id])

    const GetSubmitAssignment = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/all/submmitted/assignment/${id}`)
            .then((response) => {
                const Data = response.data;
                setLoader(false);
                if (Data?.status) {
                    console.log("get submit", Data);
                    setData(Data?.submittedAssignments)
                    // Ensure lessonAssignment is an array
                    // setAssignment(Data.assingments)

                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message;
                toast.error(Error);
            });
    };

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }


    // ADD API
    const AddDetails = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('marks', marks)
        formData.append('is_approved', aproved)

        axiosInstance.post(`/api/approve/disapprove/marking/assignment/${dataid}`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("Data course", Data)

                if (Data?.status) {
                    GetSubmitAssignment(id)
                    CloseAddmodal()
                    toast.success(Data.message);
                    setAproved("")
                    setMarks("")


                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }
    return (

        <div>

            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />

                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />

                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>SUBMITTED ASSIGNMENT</h4>
                                    </div>



                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table style={{ overflow: "scroll" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>S.NO</th>

                                                            <th>Title</th>
                                                            <th>Content</th>
                                                            <th>Start Date</th>
                                                            <th>Due Date</th>
                                                            <th>Marks</th>
                                                            <th>isApproved</th>
                                                            <th>View File</th>







                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    {data.map((v, i) => (
                                                        <tbody key={i}> {/* Ensure each child in a list has a unique "key" prop */}
                                                            <tr>
                                                                <th>
                                                                    <p>{i + 1}</p>
                                                                </th>
                                                                <th>
                                                                    <p>{v?.assignment?.title}</p>
                                                                </th>
                                                                {/* <th>
                                                                    <p>{v.content}</p>
                                                                </th> */}
                                                                <th>
                                                                    <p>{v?.assignment?.assign_date}</p>
                                                                </th>
                                                                <th>
                                                                    <p>{v?.assignment?.due_date}</p>
                                                                </th>
                                                                <th>
                                                                    <p>{v?.assignment?.due_date}</p>
                                                                </th>
                                                                <th>
                                                                    <p>{v?.marks}</p>
                                                                </th>



                                                                <td>
                                                                    <span className="dashboard__td dashboard__td--running">
                                                                        {v?.is_approved === "1" ? "Approved" : "Not Approved"}
                                                                    </span>
                                                                </td>


                                                                <td>
                                                                    <Link className="dashboard__small__btn" target='_blank' to={v?.assignment?.document} >Document</Link>
                                                                </td>

                                                                <td>
                                                                    <button onClick={() => setdataId(v.id)} type='button' className="modal_button " data-bs-toggle="modal" data-bs-target="#staticBackdropadd" > <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg>{" "} Edit </button>

                                                                    {/* <button type="button"  className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd" >Add</button> */}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))}


                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label for="category" class="form-label">Total Marks</label>
                                        <input
                                            class="form-control"
                                            id="category"
                                            value={marks}
                                            onChange={(e) => setMarks(e.target.value)}
                                            type="text"
                                            placeholder="Enter marks"
                                        />
                                    </div>

                                </div>



                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1">Is Approved</label>
                                            <select value={aproved} onChange={(e) => setAproved(e.target.value)} class="form-select" aria-label="Default select example">
                                                <option selected>Select ...</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="default__button" onClick={() => AddDetails()} >Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
