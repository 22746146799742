import { useEffect, useState } from 'react'
import { axiosInstance } from '../../config/https';
import { Link, useParams } from 'react-router-dom'
import Sidbar from '../../layout/admin/sidbar'
import Breadcrumb from '../../layout/admin/breadcrumb'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import qs from 'qs';
import { ADMIN } from '../../Router/paths';

export default function Quiz() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [quiz, setQuiz] = useState([]);
    const { id } = useParams();
    // Add 
    const [title, setTitle] = useState("");
    const [descp, setDescp] = useState("");
    const [status, setStatus] = useState("");
    // Edit
    const [etitle, seteTitle] = useState("");
    const [edescp, seteDescp] = useState("");
    const [estatus, seteStatus] = useState("");
    const [quizid, setQuizid] = useState("");
    const [lessonid, setLessonid] = useState("");
    // delete 
    const [deletequiz, setDeletequiz] = useState("");
    const [deletelesson, setDeletelesson] = useState("");

    useEffect(() => {
        console.log("Effect triggered with id:", id);
        window.scroll(0, 0)
        GetAllQuiz(id)
    }, [id])

    const GetAllQuiz = (id) => {
        console.log("API call initiated with id:", id);
        setLoader(true);
        axiosInstance.get(`/api/lessons/${id}/quizzes`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("get all quizes", Data)
                    setData(Data.lesson)
                    setQuiz(Data.lessonQuizzes)

                } else {
                    // setLoader(false);
                    toast.error(Data?.message);
                  
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                toast.error(Error);
               
            })
    }

    const handleInputChange = (event, func) => {
        func(event.target.value);
    }

    // ADD API
    const AddQuiz = () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('title', title)
        formData.append('description', descp)
        formData.append('active', status)

        axiosInstance.post(`/api/lessons/${id}/quizzes`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                if (Data?.status) {
                    CloseAddmodal()
                    toast.success(Data?.message);
                    setDescp("")
                    setTitle("")
                    setStatus("")
                    GetAllQuiz(id)
                }

                else {

                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    // EDIT FUNC 

    const Editmodal = (item) => {
        console.log("item edit", item)
        seteDescp(item.description)
        seteTitle(item.title)
        seteStatus(item.active)
        setLessonid(item.lesson_id)
        setQuizid(item.id)


    }
    const CloseEditmodal = () => {
        const deletemodal = document.getElementById("staticBackdropedit");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    // UPDATE  
    const UpdateQuiz = () => {
        setLoader(true);

        // Serialize data into x-www-form-urlencoded format
        const formData = qs.stringify({
            title: etitle,
            description: edescp,
            active: estatus,
        });

        axiosInstance.put(`/api/lessons/${lessonid}/quizzes/${quizid}`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data update", Data)
                if (Data?.status) {
                    CloseEditmodal()
                    toast.success(Data.message);
                    GetAllQuiz(id)


                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const DeleteModal = (data) => {
        console.log(data)
        setDeletelesson(data.lesson_id)
        setDeletequiz(data.id)

    }

    // DELETE 
    const DeleteQuiz = () => {
        setLoader(true);
        axiosInstance.delete(`/api/lessons/${deletelesson}/quizzes/${deletequiz}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetAllQuiz(id)

                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtndlt", closeBtn)

        closeBtn.click();

    }




    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>{data.title}</h4>
                                    </div>
                                    <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>{data.description}</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">

                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                    Add Quiz
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <hr className="mt-40" />
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>

                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th>Status </th>
                                                            <th>Quiz</th>
                                                            {/* <th>Options</th> */}
                                                            <th>Action</th>


                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    {quiz.map((v, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <th>

                                                                    <p>{i+1}</p>

                                                                </th>
                                                                <th>

                                                                    <p>{v.title}</p>

                                                                </th>
                                                                <td>
                                                                    <p>{v.description}</p>
                                                                </td>


                                                                <td>
                                                                    <span className="dashboard__td dashboard__td--running">
                                                                        {v.active === 1 ? 'Active' : 'DeActive'}
                                                                    </span>
                                                                </td>


                                                                <td> <Link className="dashboard__small__btn" to={ADMIN.questions(v.id)}> Questions </Link></td>

                                                                {/* <td> <Link className="dashboard__small__btn" to={ADMIN.questionoptions(v.id)}> Options </Link></td> */}

                                                                <td>
                                                                    <div className="dashboard__button__group">
                                                                        <td>
                                                                            <div className="dashboard__button__group">

                                                                                <button className="modal_button " data-bs-toggle="modal" data-bs-target="#staticBackdropedit" onClick={() => Editmodal(v)}><svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit" > <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /> <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /> </svg> Edit </button>

                                                                                <button className="modal_button mx-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => DeleteModal(v)}><svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg> Delete </button>


                                                                            </div>
                                                                        </td>
                                                                    </div>
                                                                </td>

                                                            </tr>



                                                        </tbody>


                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD MODAL  */}

            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Quiz</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="title" class="form-label">Title</label>
                                        <input
                                            id='title'
                                            className="form-control"
                                            value={title}
                                            onChange={(e) => handleInputChange(e, setTitle)}
                                            type="text"
                                            placeholder="Enter Title"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="Description" class="form-label">Description</label>
                                        <input
                                            id='Description'
                                            class="form-control"
                                            value={descp}
                                            onChange={(e) => handleInputChange(e, setDescp)}
                                            type="text"
                                            placeholder="Enter Description"
                                        />
                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <select value={status} onChange={(e) => handleInputChange(e, setStatus)} class="form-select" aria-label="Default select example">
                                        <option >Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Deactive</option>

                                    </select>

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => AddQuiz()}>
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* EDIT MODAL  */}

            <div class="modal fade" id="staticBackdropedit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Edit Quiz</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="title" class="form-label">Title</label>
                                        <input
                                            id='title'
                                            className="form-control"
                                            value={etitle}
                                            onChange={(e) => handleInputChange(e, seteTitle)}
                                            type="text"
                                            placeholder="Enter Title"
                                        />
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div class="mb-3">
                                        <label htmlFor="Description" class="form-label">Description</label>
                                        <input
                                            id='Description'
                                            class="form-control"
                                            value={edescp}
                                            onChange={(e) => handleInputChange(e, seteDescp)}
                                            type="text"
                                            placeholder="Enter Description"
                                        />
                                    </div>

                                </div>

                                <div className='col-md-12'>
                                    <select value={estatus} onChange={(e) => handleInputChange(e, seteStatus)} class="form-select" aria-label="Default select example">
                                        <option >Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Deactive</option>

                                    </select>

                                </div>


                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => UpdateQuiz()}>
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* DELETE  */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Quiz</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this Quiz?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteQuiz()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
