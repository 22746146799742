import React from 'react'
import SettingsComp from '../../components/admin/settings'
export default function Settings() {
  return (
    <div>
        <SettingsComp/>
        
    </div>
  )
}
