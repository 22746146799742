import React from 'react'
import LessonComp from '../../components/lesson'
import BreadCrumb from '../../components/breadCrumb'
export default function Lesson() {
  return (
    <>
    <BreadCrumb  heading={"Lessons"}/>
    <LessonComp/>
        
    </>
  )
}
