import React, { createContext, useContext, useState } from "react";

const LoaderContext = createContext();
export const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const startLoader = () => {
    setLoader(true);
  };

  const stopLoader = () => {
    setLoader(false);
  };

  const contextValue = {
    loader,
    startLoader,
    stopLoader,
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  return useContext(LoaderContext);
};
