import { useEffect, useState } from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { STUDENT } from '../../Router/paths'
import React from 'react'
import { axiosInstance } from '../../config/https';
import { Link, useParams } from 'react-router-dom'
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';
export default function Studentassignment() {

    const [loader, setLoader] = useState(false);
    // const [data, setData] = useState([]);
    const [assignment, setAssignment] = useState([]);
    const { id } = useParams();

    console.log("assign", assignment)
    useEffect(() => {
        window.scroll(0, 0)
        GetSingleAssignment(id)
    }, [id])

    const GetSingleAssignment = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/student/lesson/assignment/${id}`)
            .then((response) => {
                const Data = response.data;
                setLoader(false);
                if (Data?.status) {
                    console.log("get assign", Data);
                    // Ensure lessonAssignment is an array
                    setAssignment(Data.assingments)

                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message;
                toast.error(Error);
            });
    };
    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
                <Breadcrumb />
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <StudentSidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>My Assignment</h4>
                                    </div>

                                    <hr className="mt-40" />
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="dashboard__table table-responsive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>

                                                            <th> Title</th>
                                                            {/* <th> Content</th> */}
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Status</th>
                                                            <th>Action</th>


                                                            <th />
                                                        </tr>
                                                    </thead>

                                                    {assignment.map((v, i) => (
                                                        <tbody key={i}> {/* Ensure each child in a list has a unique "key" prop */}
                                                            <tr>
                                                                <th>
                                                                    <p>{i + 1}</p>
                                                                </th>
                                                                <th>
                                                                    <p>{v.title}</p>
                                                                </th>
                                                                {/* <th>
                                                                    <p>{v.content}</p>
                                                                </th> */}
                                                                <th>
                                                                    <p>{v.assign_date}</p>
                                                                </th>
                                                                <th>
                                                                    <p>{v.due_date}</p>
                                                                </th>

                                                                {v.submitted === true ?
                                                                    <td>
                                                                        <span className="dashboard__td dashboard__td--running">Attempted</span>
                                                                    </td> :

                                                                    <td>
                                                                        <span className="dashboard__td dashboard__td--running" to={v.document}>Not Attempt</span>
                                                                    </td>
                                                                    // <td>
                                                                    //     <Link target='_blank' className="dashboard__small__btn" to={v.document}>Download</Link>
                                                                    // </td>
                                                                }

                                                                <td>
                                                                    <Link className="dashboard__small__btn" to={STUDENT.specificassignment(v.id, v.submitted)}>view</Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))}

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
