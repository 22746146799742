import { useState, useEffect } from 'react'
import {  useParams } from 'react-router-dom'
import Sidbar from '../../layout/admin/sidbar'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { useDropzone } from 'react-dropzone';
import Breadcrumb from '../../layout/admin/breadcrumb';

export default function Lessonvides() {


    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);

    // add 
   
    const [files, setFiles] = useState([]);

    // edit 
   
    const [deleteid, setDeleteid] = useState("");

    const { id, title } = useParams();


   
    useEffect(() => {
        window.scroll(0, 0)
        GetVideosByid(id)

    }, [id])


    // GET videoss 
    const GetVideosByid = (id) => {
        setLoader(true);
        axiosInstance.get(`/api/lesson/view/${id}`)
            .then((response) => {
                const Data = response.data
                setLoader(false);
                console.log("Data====", Data)
                if (Data?.status) {
                    if (Data.lessonVideo && Data.lessonVideo.length > 0) {
                        setData(Data.lessonVideo);
                    } else {

                        toast.error("No video found");
                    }

                } else {
                    setLoader(false);
                    toast.error(Data?.message);
                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message

                setLoader(false);
                toast.error(Error);
            })
    }


    const DeleteModal = (data) => {
        setDeleteid(data.id)



    }

    // DELETE 

    const DeleteVideo = () => {
        setLoader(true);

        axiosInstance.get(`/api/delete/lesson/video/${deleteid}`)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;
                console.log("data delete", Data)
                if (Data?.status) {
                    CloseDeletemodal()
                    toast.success(Data.message);
                    GetVideosByid(id)


                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }


    const { getRootProps, getInputProps } = useDropzone({
        accept: ['video/*'],
        maxFiles: 10,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: file.type.startsWith('video/') ? URL.createObjectURL(file) : undefined,
                    })
                )
            );
        },
    });



    // ADD API
    const AddVideo = () => {
        setLoader(true);
        const formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append("file[]", files[i]);
        }

        axiosInstance.post(`/api/add/lesson/video/${id}`, formData)
            .then((response) => {
                setLoader(false);
                const Data = response?.data;

                if (Data?.status) {
                    setFiles([])
                    CloseAddmodal()
                    toast.success("Added Successfully");
                    GetVideosByid(id)
                }

                else {
                    setLoader(false)
                    const ErrList = Object.keys(Data.errors);
                    ErrList.map((e) => toast.error(Data.errors[e][0]));
                }

            }).catch((err) => {
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);
            })
    }

    const CloseDeletemodal = () => {
        const deletemodal = document.getElementById("staticBackdrop");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }

    const CloseAddmodal = () => {
        const deletemodal = document.getElementById("staticBackdropadd");
        console.log("deletemodal", deletemodal)

        // Simulate a click on the close button to close the modal
        const closeBtn = deletemodal.querySelector('[data-bs-dismiss="modal"]');
        console.log("closeBtn", closeBtn)

        closeBtn.click();

    }



    return (
        <>
            {loader === true ? <Loader fullPage loading /> : null}


            <div className="dashboardarea sp_bottom_100">
            <Breadcrumb/>

                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <Sidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>Videos</h4>
                                    </div>
                                    <div className="dashboard__Announcement__wraper">
                                        <div className="row ">
                                            <div className="col-xl-8 col-lg-6 col-md-6 col-12">
                                                <div className="dashboard__Announcement">
                                                    <h5>{title ? title : ''}</h5>

                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                {/* <Link className="default__button" to="#" onClick={handleShow}>
                                                    Add Video
                                                </Link> */}
                                                <button type="button" className="default__button" data-bs-toggle="modal" data-bs-target="#staticBackdropadd">
                                                    Add Video
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        {/* <div className="counterarea__text__wraper"> */}

                                        {data.map((video) => (
                                            <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                                                <div className="dashboard__single__counter p-0" >
                                                    <div key={video.id} style={{ marginBottom: '20px' }}>
                                                        <video
                                                            src={video.file}
                                                            alt={`Video-${video.id}`}
                                                            style={{ maxWidth: '100%', maxHeight: '400px' }}
                                                            controls
                                                        />
                                                        <button className="modal_button" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => DeleteModal(video)}> <svg xmlns="http://www.w3.org/2000/svg" width={15} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2" > <polyline points="3 6 5 6 21 6" /> <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /> <line x1={10} y1={11} x2={10} y2={17} /> <line x1={14} y1={11} x2={14} y2={17} /> </svg>{" "}  </button> </div>
                                                </div>
                                            </div>

                                        ))}



                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ADD MODAL  */}
        

            <div class="modal fade" id="staticBackdropadd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Add Video</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div
                                        {...getRootProps({ className: "dropzone" })}
                                        style={{
                                            border: "2px dotted  rgba(90,204,230)",
                                            width: "100%",
                                            height: "100%",
                                            padding: "30px",
                                            backgroundColor: "rgba(68,70,84)",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <p className="text-center text-white"> Upload your lesson video</p>
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div className='mt-4'>
                                        {files.map((file) => (
                                            <div key={file.name} style={{ marginRight: '10px', marginBottom: '10px' }}>
                                                {file.type.startsWith('video/') ? (
                                                    <>
                                                        <video
                                                            src={file.preview}
                                                            alt={file.name}
                                                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                                                            controls
                                                        />
                                                        <p>{file.name}</p>
                                                    </>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">Close</button>
                            {loader && (
                                <button className="default__button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            )}

                            {!loader && (
                                <button className="default__button" type="button" onClick={() => AddVideo()}>
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* DELETE  */}


            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Delete Video</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2> Are You Sure ?</h2>
                                    <h5>Do you really want to delete this Video?</h5>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="default__button" data-bs-dismiss="modal">No</button>
                            <button type="button" className="default__button" onClick={() => DeleteVideo()} >Yes</button>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
