import React from 'react'
import BreadCrumb from "../../components/breadCrumb";
import Contactus from "../../components/contactus";
export default function Contect() {
  return (
    <div>
         <BreadCrumb heading={'Contact Us'}/>
         <Contactus/>

    </div>
  )
}
