import {useEffect,useState} from 'react'
import StudentSidbar from '../../layout/student/sidbar'
import { axiosInstance } from '../../config/https';
import toast from "react-hot-toast";
import {  Loader } from 'react-overlay-loader';
import Breadcrumb from '../../layout/student/breadcrumb';

export default function Studentprofile() {
    const [loader, setLoader] = useState(false);

    const [email, setEmail] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");

    useEffect(() => {
        window.scroll(0,0)
        GetProfile()
     
    }, [ ])
    

      // GET Categories 
      const GetProfile = () => {
        setLoader(true);
        axiosInstance.get('/api/view/user')
            .then((response) => {
                const Data = response.data
                setLoader(false);
                if (Data?.status) {
                    console.log("profile data",Data)
                    // setData(Data.user)
                    setFname(Data.user.fname)
                    setLname(Data.user.lname)
                    setEmail(Data.user.email)

                    // toast.success(Data.message);
                 
                

                } else {
                setLoader(false);
                    toast.warn(Data?.message);
                   

                }
            }).catch((err) => {
                setLoader(false);
                const Error = err?.response?.data?.message
                setLoader(false);
                toast.error(Error);

            })
    }
    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="dashboardarea sp_bottom_100">
              <Breadcrumb/>
                <div className="dashboard">
                    <div className="container-fluid full__width__padding">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12">
                                <StudentSidbar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-12">
                                <div className="dashboard__content__wraper">
                                    <div className="dashboard__section__title">
                                        <h4>My Profile</h4>
                                    </div>
                                    <div className="row">
                                      
                                       
                                        <div className="col-lg-4 col-md-4">
                                            <div className="dashboard__form dashboard__form__margin">
                                                First Name
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="dashboard__form dashboard__form__margin">
                                              {fname}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="dashboard__form dashboard__form__margin">
                                                Last Name
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="dashboard__form dashboard__form__margin">
                                            {lname}

                                            </div>
                                        </div>
                                       
                                        <div className="col-lg-4 col-md-4">
                                            <div className="dashboard__form dashboard__form__margin">
                                                Email
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="dashboard__form dashboard__form__margin">
                                            {email}
                                              
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4 col-md-4">
                                            <div className="dashboard__form dashboard__form__margin">
                                                Phone Number
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="dashboard__form dashboard__form__margin">
                                                +55 669 4456 25987
                                            </div>
                                        </div> */}
                                       
                                       
                                       
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
