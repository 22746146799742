import React from 'react'
import Categorycour from '../../components/admin/category'

export default function Category() {
  return (
    <div>
        <Categorycour/>
    </div>
  )
}
