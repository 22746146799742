import { useState ,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { axiosInstance } from '../config/https';
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { Loader } from 'react-overlay-loader';
import { STUDENT } from '../Router/paths';

export default function Login() {

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [confpassword, setConfpassword] = useState("");
  // LOGIN 

  const [Emaill, setEmaill] = useState("");
  const [Passwordl, setPasswordl] = useState("");

  useEffect(() => {
    window.scroll(0,0)
   
  }, [])
  


  // SIGNUP api
  const SignupStudent = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('fname', fname)
    formData.append('lname', lname)
    formData.append('email', email)
    formData.append('password', password)
    formData.append('password_confirmation', confpassword)

    axiosInstance.post("/api/student/register", formData)
      .then((response) => {
        const Data = response?.data;
        setLoader(false);
        if (Data?.status) {
     
          toast.success(Data?.message);
          navigate(STUDENT.login);

        } else {
        setLoader(false);
          
          const ErrList = Object.keys(Data.errors);
          ErrList.map((e) => toast.error(Data.errors[e][0]));

        }
      }).catch((err) => {
        setLoader(false);
        toast.error(err.message);

      })
  }


  const handleInputChange = (event, func) => {
    func(event.target.value);
  }

  // Login api
  const StudentLogin = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('email', Emaill)
    formData.append('password', Passwordl)
    axiosInstance.post("/api/user/login", formData)
      .then((response) => {
        const Data = response?.data;
    
        setLoader(false);

        if (Data?.status === true && Data?.user?.role?.name === "Student") {
          localStorage.setItem("token", Data.token)
          localStorage.setItem("user", JSON.stringify(Data.user));
          toast.success("Login Successfully");
          navigate("/")

        }


        else if (Data?.status === true && Data?.user?.role?.name === "Admin") {
          setLoader(false)
          toast.error("Crediential is incorrect");
         
        }else{
          toast.error(Data.message);

        }


      }).catch((err) => {
        console.log("err",err)

      const Error = err?.response?.data?.message
        setLoader(false);
        toast.error(Error);

      })
  }

  const handleInputChangesingin = (event, func) => {
    func(event.target.value);
  }


  return (
    // <!-- login__section__start -->
    <>
            {loader === true ? <Loader fullPage loading /> : null}


      <div className="loginarea sp_top_100 sp_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-md-8 offset-md-2" data-aos="fade-up">
              <ul
                className="nav  tab__button__wrap text-center"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="single__tab__link active"
                    data-bs-toggle="tab"
                    data-bs-target="#projects__one"
                    type="button"
                  >
                    Login
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="single__tab__link"
                    data-bs-toggle="tab"
                    data-bs-target="#projects__two"
                    type="button"
                  >
                    Sing up
                  </button>
                </li>
              </ul>
            </div>
            <div
              className="tab-content tab__content__wrapper"
              id="myTabContent"
              data-aos="fade-up"
            >
              <div
                className="tab-pane fade active show"
                id="projects__one"
                role="tabpanel"
                aria-labelledby="projects__one"
              >
                <div className="col-xl-8 col-md-8 offset-md-2">
                  <div className="loginarea__wraper">
                    <div className="login__heading">
                      <h5 className="login__title">Login</h5>
                      {/* <p className="login__description">
                        Don't have an account yet?
                        <Link
                          to=""

                        >
                          Sign up for free
                        </Link>
                      </p> */}
                    </div>
                    <form action="#">
                      <div className="login__form">
                        <label className="form__label"> Email</label>
                        <input
                          onChange={(e) => handleInputChangesingin(e, setEmaill)}

                          className="common__login__input"
                          type="text"
                          placeholder="Your username or email"
                        />
                      </div>
                      <div className="login__form">
                        <label className="form__label">Password</label>
                        <input
                          onChange={(e) => handleInputChangesingin(e, setPasswordl)}

                          className="common__login__input"
                          type="password"
                          placeholder="Password"
                        />
                      </div>
                      <div className="login__form d-flex justify-content-between flex-wrap gap-2">
                        <div className="form__check">
                          <input id="forgot" type="checkbox" />
                          <label htmlFor="forgot"> Remember me</label>
                        </div>
                        <div className="text-end login__form__link">
                          <Link to={STUDENT.passwordrecover}>Forgot your password?</Link>
                        </div>
                      </div>
                      <div className="login__button" onClick={() => StudentLogin()}>
                        <Link className="default__button" to="#">
                          Log In
                        </Link>
                      </div>
                    </form>
                    {/* <div className="login__social__option">
                      <p>or Log-in with</p>
                      <ul className="login__social__btn">
                        <li>
                          <Link className="default__button login__button__1" to="#">
                            <i className="icofont-facebook" /> Gacebook
                          </Link>
                        </li>
                        <li>
                          <Link className="default__button" to="#">
                            <i className="icofont-google-plus" />
                            Google
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="projects__two"
                role="tabpanel"
                aria-labelledby="projects__two"
              >
                <div className="col-xl-8 offset-md-2">
                  <div className="loginarea__wraper">
                    <div className="login__heading">
                      <h5 className="login__title">Sing Up</h5>
                      {/* <p className="login__description">
                        Already have an account?
                        <Link
                          to=""

                        >
                          Log In
                        </Link>
                      </p> */}
                    </div>
                    <form action="#">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="login__form">
                            <label className="form__label">First Name</label>
                            <input
                              onChange={(e) => handleInputChange(e, setFname)}
                              className="common__login__input"
                              type="text"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="login__form">
                            <label className="form__label">Last Name</label>
                            <input
                              onChange={(e) => handleInputChange(e, setLname)}

                              className="common__login__input"
                              type="text"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6">
                          <div className="login__form">
                            <label className="form__label">Email</label>
                            <input
                              onChange={(e) => handleInputChange(e, setEmail)}

                              className="common__login__input"
                              type="email"
                              placeholder="Your Email"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="login__form">
                            <label className="form__label">Password</label>
                            <input
                              onChange={(e) => handleInputChange(e, setPassword)}

                              className="common__login__input"
                              type="password"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="login__form">
                            <label className="form__label">Re-Enter Password</label>
                            <input
                              onChange={(e) => handleInputChange(e, setConfpassword)}

                              className="common__login__input"
                              type="password"
                              placeholder="Re-Enter Password"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="login__form d-flex justify-content-between flex-wrap gap-2">
                        <div className="form__check">
                          <input id="accept_pp" type="checkbox" />{" "}
                          <label htmlFor="accept_pp">
                            Accept the Terms and Privacy Policy
                          </label>
                        </div>
                      </div>
                      <div className="login__button" onClick={() => SignupStudent()}>
                        <Link className="default__button" to="#">
                          Signup
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" login__shape__img educationarea__shape_image">
            <img
              loading="lazy"
              className="hero__shape hero__shape__1"
              src="/assets/img/education/hero_shape2.png"
              alt="Shape"
            />
            <img
              loading="lazy"
              className="hero__shape hero__shape__2"
              src="/assets/img/education/hero_shape3.png"
              alt="Shape"
            />
            <img
              loading="lazy"
              className="hero__shape hero__shape__3"
              src="/assets/img/education/hero_shape4.png"
              alt="Shape"
            />
            <img
              loading="lazy"
              className="hero__shape hero__shape__4"
              src="/assets/img/education/hero_shape5.png"
              alt="Shape"
            />
          </div>
        </div>
      </div>
      {/* <!-- login__section__end --> */}

    </>
  )
}
